export default function Roles() {
  return (
    <div className="py-6 mx-auto bg-white border border-gray-200 shadow-sm rounded-xl">
      <h1 className="px-6 mb-4 text-[18px] font-semibold text-htext-main">Roles</h1>

      <div className="space-y-2 border-t border-gray-200">
        <div className="px-6 py-3 border-b border-gray-200 bg-hgray-100">
          <h2 className="text-[12.5px] font-inter font-medium uppercase text-hgray-500">Types</h2>
        </div>
        <div className="px-6 pt-2 pb-5 border-b border-gray-200">
          <h2 className="mb-[1px] font-inter text-[13.5px] text-htext-main font-semibold">Viewer</h2>
          <p className="text-[13px] text-hgray-500">
            For people who can only view transactions and cannot make any edit
          </p>
        </div>
        <div className="px-6 pt-2 pb-5 border-b border-gray-200">
          <h2 className="mb-[1px] font-inter text-[13.5px] text-htext-main font-semibold">Admin</h2>
          <p className="text-[13px] text-hgray-500">
            For people who need to edit payroll account, view all transactions
            and edit other team members (viewers)
          </p>
        </div>
        <div className="px-6 pt-2 pb-[2px]">
          <h2 className="mb-[1px] font-inter text-[13.5px] text-htext-main font-semibold">Owner</h2>
          <p className="text-[13px] text-hgray-500">
            For business owners who need access to view, edit and manage
            everything.
          </p>
        </div>
      </div>
    </div>
  );
}
