import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useModal } from "../../../../context/modalProvider";
import { trpc } from "../../../../lib/trpc";
import { dollarDisk, poundseuroDisk } from "../../../Common/svg";
import PortalInSetup from "./index";

const PortalInActivateAccount: FC = () => {
  const navigate = useNavigate();
  const utils = trpc.useUtils();
  const mutation = trpc.user.confirmBankStep.useMutation({
    onSuccess: () => {
      navigate("/dashboard/portal-accounts/portal-in-bank-acccounts");
      utils.user.getAccountType.invalidate();
    },
  });
  const { showModal } = useModal();

    
  const { mutateAsync: triggerOtp } = trpc.user.triggerOtp.useMutation({
    onError: (e) => {
      toast.error(e.message);
    },
  });
  const { mutateAsync: getSession, isLoading: isLoadingGettingSession } =
  trpc.user.isSessionIdExpired.useMutation({
    onError: (e) => {
      toast.error(e.message);
    },
  });



  const activateCurrencies = async ()=>{
    try {
      const response = await getSession({});
      if (response.sessionIdHasExpired) {
        await triggerOtp({});
        showModal();
      } else {
        mutation.mutate({})
      }
    } catch (error) {
      console.error(error);
    }
  }



  return (
    <PortalInSetup currentStep={3} complete={false}>
      <div className="portal-type hide-scrollbar mt-12 h-full w-full overflow-y-scroll">
        <div className="type-content flex h-full flex-col items-center justify-between pt-3 ">
        <div className="overflow-y-scroll hide-scrollbar pb-[6rem] mx-auto">
          <div className="type-top w-[750px] pb-5">
            <div className="type-top pb-10">
              <div className="relative flex items-start justify-between rounded-2xl bg-hgray-50 px-5 pb-4 pt-6 shadow-s-semi-light">
                <div className="z-10 w-[62.5%]">
                  <h3 className="text-[22px] font-semibold leading-[31px] text-htext-main">
                    Activate EUR & GBP portal bank account
                  </h3>
                  <p className="mt-[5px] text-[13px] font-normal leading-[20px] text-htext-main">
                    Any euro & pound amount you send into this portal bank
                    account is swapped into stablecoin and sent to your
                    settlement wallet
                  </p>

                  <button
                    className="mt-7 w-[240px] rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[10px] text-[13px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
                    type="button"
                    onClick={() => activateCurrencies()}
                  >
                    {mutation.isLoading || isLoadingGettingSession? (
                      <span className="loading loading-spinner loading-sm"></span>
                    ) : (
                      "Activate EUR & GBP portal account"
                    )}
                  </button>
                </div>

                <div className="z-10 w-[20.5%]">
                  <span
                    className="float-right -mr-[1rem] -mt-[1.5rem] block w-fit"
                    dangerouslySetInnerHTML={{ __html: poundseuroDisk }}
                  />
                </div>
              </div>

              <div className="relative mt-7 flex items-start justify-between rounded-2xl bg-hgray-50 px-5 pb-4 pt-6 shadow-s-semi-light">
                <div className="z-10 w-[62.5%]">
                  <h3 className="text-[22px] font-semibold leading-[31px] text-htext-main">
                    Activate USD portal bank account
                  </h3>
                  <p className="mt-[5px] text-[13px] font-normal leading-[20px] text-htext-main">
                    Any dollar amount you send into this portal bank account is
                    swapped into stablecoin and sent to your settlement wallet
                  </p>

                  <button
                    className="mt-7 w-[240px] cursor-not-allowed rounded-lg border border-solid border-hgray-200 bg-hgray-100 px-[10px] py-[10px] text-[13px] font-semibold text-hgray-400 shadow-s-light"
                    type="button"
                    disabled
                  >
                    Activate USD portal account
                  </button>
                </div>

                <div className="z-10 w-[20.5%]">
                  <span
                    className="float-right -mr-[1rem] -mt-[1.5rem] block w-fit"
                    dangerouslySetInnerHTML={{ __html: dollarDisk }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

          <div className="form-btns flex h-auto bg-white w-full fixed bottom-0 items-center justify-between border-t border-t-hgray-200 px-14 pb-6 pt-6">
            <button
              className="w-[150px] rounded-lg border  border-solid border-hgray-300 bg-white px-[10px] py-[13px] text-[15px] font-semibold text-hgray-700 shadow-s-light hover:bg-hgray-50"
              type="button"
              onClick={() =>
                navigate(
                  "/dashboard/portal-accounts/portal-in-settlement-information"
                )
              }
            >
              Go Back
            </button>

            <button
              className="w-[250px] cursor-not-allowed rounded-lg border border-solid border-hgray-200 bg-hgray-100 px-[10px] py-[13px] text-[15px] font-semibold text-hgray-400 shadow-s-light"
              type="button"
              disabled
              onClick={() =>
                navigate(
                  "/dashboard/portal-accounts/portal-in-settlement-information"
                )
              }
            >
              View rates
            </button>
          </div>
        </div>
      </div>
    </PortalInSetup>
  );
};

export default PortalInActivateAccount;
