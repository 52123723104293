import clsx from "classnames";
import { twMerge } from "tailwind-merge";
import { closeBtnSVG } from "../../Common/svg";
import { IModalProps } from "./interface";

const Drawer = ({
  onClose,
  children,
  header,
  containerClassName,
  subHeader,
  openDrawerBox,
  action,
  contentClassName,
  position,
  modalOverlayContainerClassName,
}: // ...rest
IModalProps) => {
  return (
    openDrawerBox && (
      <div
        className={twMerge(
          "fixed inset-0 z-[9999] flex h-[100vh] w-[100vw] overflow-auto transition-opacity text-inherit",
          modalOverlayContainerClassName
        )}
      >
        <div
          className={clsx(
            "fixed inset-0 z-[0] h-[100vh] w-[100vw] overflow-auto bg-hgray-900/[0.8]",
            {
              "translate-x-full": !openDrawerBox && position === "right",
              "-translate-x-full": !openDrawerBox && position === "left",
              "translate-y-full": !openDrawerBox && position === "bottom",
              "-translate-y-full": !openDrawerBox && position === "top",
            },
            position === "right" && "right-0",
            position === "left" && "left-0",
            position === "top" && "top-0",
            position === "bottom" && "bottom-0"
          )}
          onClick={() => {
            onClose();
          }}
        ></div>
        <div
          className={twMerge(
            `absolute left-auto right-0 grid h-screen w-full max-w-[540px] bg-[#FFFFFF] ${
              header ? "grid-rows-max-auto-max" : "grid-rows-auto-max"
            }`,
            containerClassName
          )}
        >
          <div className=" sticky top-[0px] z-[1] w-full bg-[#FFFFFF] px-[24px] py-[15px]">
            <div className="flex items-center justify-between">
              <div
                className={`text-[18px] leading-[24px] font-semibold
text-[#101828]`}
              >
                {header}
              </div>
              <div className="">
                <span
                  className="h-[24px] w-[24px] cursor-pointer"
                  dangerouslySetInnerHTML={{ __html: closeBtnSVG }}
                  onClick={onClose}
                />
              </div>
            </div>
            {subHeader && (
              <div className={`text-[14px] text-gray-400`}>{subHeader}</div>
            )}
          </div>

          <div
            className={twMerge(
              "grid grid-rows-auto-max overflow-auto p-[24px]",
              action && "pb-0",
              contentClassName
            )}
          >
            {children}
          </div>

          {action && (
            <div className="sticky bottom-[0px] z-[1] grid w-full grid-flow-col gap-[24px] border-t-[1px] border-gray-100 bg-[#FFFFFF] p-[16px]">
              {action}
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default Drawer;
