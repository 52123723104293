import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as z from "zod";
import Drawer from "../../../components/Common/drawer";
import FileUpload from "../../../components/Common/inputs/fileUpload";
import Select from "../../../components/Common/inputs/select";
// import MainLoader from "../../../components/Common/loader/mainLoader";
import PageContainer from "../../../components/Common/pageContainer";
import { trpc } from "../../../lib/trpc";
import FAQSection from "./faqSection";
import Sidebar from "./sidebar";
export type Section =
  | "all"
  | "aboutShiga"
  | "keyBenefits"
  | "howToUseShiga"
  | "contactSupport";

const sof = ["Transaction issue", "Product inquiry", "Complaints and feedback"];
const contactUsSchema = z.object({
  issue: z.string().min(1, "Issue type is required"),
  message: z.string().min(1, "Description type is required"),
  uploadImage: z.string(),
});

export type ContactUsSchema = z.infer<typeof contactUsSchema>;

const FAQPage = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState<Section>("all");

  const { data: shigaFaq, isLoading: isFetchshigaFaq } =
    trpc.user.getShigaFaq.useQuery({});

  const methods = useForm({
    resolver: zodResolver(contactUsSchema),
    defaultValues: {
      issue: "",
      message: "",
      uploadImage: "",
    },
  });

  const { mutate: sendContactUsMsg, isLoading: isSendingMsg } =
    trpc.user.customerContactUs.useMutation({
      onSuccess: (res) => {
        setIsDrawerOpen(false);
        toast.success(res.message);
        methods.reset();
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  // if (isFetchshigaFaq) {
  //   return <MainLoader />;
  // }

  const onSave = (data: ContactUsSchema) => {
    sendContactUsMsg(data);
  };
  //
  return (
    <PageContainer>
      {isFetchshigaFaq ? (
       <div className="absolute inset-0 z-40 flex items-center justify-center w-full h-full bg-white bg-opacity-50">
       <div className="loader"></div> {/* Add your loader here */}
     </div>
      ) : (
        <div className="sixm:block grid h-screen grid-cols-[400px_1fr] gap-x-8">
          <div className="">
            <Sidebar
              toggleDrawer={toggleDrawer}
              section={selectedSection}
              setSelectedSection={setSelectedSection}
            />
          </div>
          <div className="w-full">
            <FAQSection data={shigaFaq || []} section={selectedSection} />
          </div>
        </div>
      )}

      <Drawer
        openDrawerBox={isDrawerOpen}
        onClose={toggleDrawer}
        header="Get in touch"
        action={
          <div className="flex justify-end ">
            <button
              type="button"
              onClick={toggleDrawer}
              className="shadow-s-light] mr-[5px] rounded-lg border border-hgray-300 bg-white px-3.5 py-2.5 text-[15px] font-semibold text-hgray-700 hover:opacity-70"
            >
              Close
            </button>
            <button
              type="submit"
              onClick={handleSubmit(onSave)}
              className="ml-[5px] w-[40%] rounded-lg border border-hbrand-600 bg-hbrand-600 px-12 py-2.5 text-[15px] font-semibold text-white shadow-s-light hover:opacity-80"
            >
              {isSendingMsg ? (
                <span className="loading loading-spinner loading-sm"></span>
              ) : (
                "Send"
              )}
            </button>
          </div>
        }
        subHeader="We typically respond within 24 hrs"
      >
        <FormProvider {...methods}>
          <form>
            <fieldset>
              <label htmlFor="idType" className="relative h-full">
                <div className="mb-1.5 flex items-center text-[13px] font-medium">
                  <p className="text-htext-label">Issue cateogry</p>
                </div>

                <Select
                  id="idType"
                  register={register("issue")}
                  inputClass={true}
                  optionsItem={sof}
                  error={errors.issue}
                />
                {errors.issue && (
                  <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                    {errors.issue.message}!
                  </p>
                )}
              </label>
              <label htmlFor="idType" className="relative  h-full w-[48.5%]">
                <div className="mb-1.5 mt-7  flex items-center text-[13px] font-medium">
                  <p className="text-htext-label">Description </p>
                </div>
                <textarea
                  className="w-full px-3 py-2 border border-gray-100 rounded-md outline-none cursor-pointer"
                  maxLength={1200}
                  rows={9}
                  placeholder="Enter answer"
                  style={{ resize: "none" }}
                  {...register("message")}
                />
                {errors.message && (
                  <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                    {errors.message.message}!
                  </p>
                )}
              </label>

              <label htmlFor="file" className="relative w-full">
                <div className="mb-1 mt-7  flex items-center text-[13px] font-medium">
                  <p className="text-htext-label">Upload attachment</p>
                </div>
                <FileUpload
                  name="uploadImage"
                  error={errors?.["uploadImage"]}
                />
                {errors?.["uploadImage"] && (
                  <p className="font-ibm-plex absolute mt-1.5 text-[13px] font-medium text-red-600">
                    {errors?.["uploadImage"]?.message as string}
                  </p>
                )}
                {errors.uploadImage && (
                  <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                    {errors.uploadImage.message}!
                  </p>
                )}
              </label>
            </fieldset>
          </form>
        </FormProvider>
      </Drawer>
    </PageContainer>
  );
};

export default FAQPage;
