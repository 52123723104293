import { differenceInYears, format, isValid, parse } from "date-fns";
import * as z from "zod";
import { isEvmAddress } from "../../../../utils/isEvmAddress";
import { isSolanaAddress } from "../../../../utils/isSolanaAddress";

export const profileFormSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  email: z.string().min(1, "Email is required").email("Invalid email address"),
  phoneNumber: z
    .string()
    .trim()
    .regex(
      /^\+\d{1,3}\d{1,14}$/,
      "Please enter a valid phone number starting with a country code"
    ),
  dob: z
    .string()
    .trim()
    .refine(
      (value) => {
        // Attempt to parse the date assuming ISO 8601 format
        let parsedDate = new Date(value);
        if (isNaN(parsedDate.getTime())) {
          return false;
        }

        // Convert the parsed date to D/M/YYYY format
        const formattedDate = format(parsedDate, "dd/MM/yyyy");

        // Validate the formatted date string
        const dateFormatIsValid = /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(
          formattedDate
        );
        if (!dateFormatIsValid) {
          return false;
        }

        // Parse the date again using date-fns
        parsedDate = parse(formattedDate, "dd/MM/yyyy", new Date());

        // Check if the parsed date is valid
        if (!isValid(parsedDate)) {
          return false;
        }

        // Calculate the age
        const age = differenceInYears(new Date(), parsedDate);
        return age >= 18;
      },
      {
        message: "Date format should be D/M/YYYY and must be 18 or above!",
      }
    ),
  sourceOfFunds: z.string().min(1, "Source of funds is required"),
  idType: z.string().min(1, "ID type is required"),
  idNumber: z
    .string()
    .min(1, "ID number is required")
    .max(20, "ID number must contain at most 20 character(s)"),
  uploadIdUrl: z.string().min(1, "Upload ID URL is required"),
  addressLine: z.string().min(1, "Address is required"),
  zipCode: z.string().min(1, "Zip code is required").max(15),
  country: z.string().min(1, "Country is required"),
  city: z.string().min(1, "City is required"),
  addressVerification: z.string().min(1, "Verify address is required"),
  proofOfAddressUrl: z.string().min(1, "Upload proof is required"),
});
export type ProfileFormData = z.infer<typeof profileFormSchema>;

// Define the schema
export const businessInfoFormSaveSchema = z.object({
  legalName: z.string().min(1, "Legal name is required"),
  registrationNumber: z.string().min(1, "Registration number is required"),
  type: z.string().min(1, "Type is required"),
  dateOfEstablishment: z.string(),
  sourcesOfIncome: z.string().min(1, "Sources of income are required"),
  state: z.string().min(1, "State is required"),
  addressLine: z.string().min(1, "Address line is required"),
  postalCode: z.string().min(1, "Postal code is required"),
  country: z.string().min(1, "Country is required"),
  city: z.string().min(1, "City is required"),
});
export type BusinessInfoFormData = z.infer<typeof businessInfoFormSaveSchema>;

export const businessDocs = z.object({
  businessRegistrationUpload: z
    .string()
    .min(1, "Business registration upload is required"),
  ownershipInformationUpload: z
    .string()
    .min(1, "Ownership information upload is required"),
  financialStatementsUpload: z
    .string()
    .min(1, "Financial statements upload is required"),
  corporateGovernanceUpload: z
    .string()
    .min(1, "Corporate governance upload is required"),
  licenseUpload: z.string(),
  amlPolicyUpload: z.string(),
});

export type BusinessDocsData = z.infer<typeof businessDocs>;

export const uboschema = z.object({
  jobTitle: z.string().trim().min(1),
  percentageOfOwnership: z.string().trim().min(1),
  isPep: z.boolean(),
});

export type Uboschema = z.infer<typeof uboschema>;

export const createUBOprofileForOldUserSchema = uboschema
  .extend({
    isOwner: z.boolean(),
    jobTitle: z.string().trim(),
    percentageOfOwnership: z.string().trim(),
    isPep: z.boolean(),
    firstName: z.string().trim().optional(),
    lastName: z.string().trim().optional(),
    email: z.union([z.literal(""), z.string().email()]),
    phoneNumber: z
      .string()
      .trim()
      .regex(
        /^\+\d{1,3}\d{1,14}$/,
        "Please enter a valid phone number starting with a country code"
      )
      .optional()
      .or(z.literal("")),
  })
  .superRefine((data, ctx) => {
    if (data.isOwner) {
      if (!data.jobTitle || data.jobTitle.trim() === "") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Job title is required",
          path: ["jobTitle"],
        });
      }
      if (
        !data.percentageOfOwnership ||
        data.percentageOfOwnership.trim() === ""
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Percentage of ownership is required",
          path: ["percentageOfOwnership"],
        });
      }
      if (data.isPep === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "PEP option is required",
          path: ["isPep"],
        });
      }
    } else {
      if (!data.firstName || data.firstName.trim() === "") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "First name is required",
          path: ["firstName"],
        });
      }
      if (!data.lastName || data.lastName.trim() === "") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Last name is required",
          path: ["lastName"],
        });
      }
      if (!data.email || data.email.trim() === "") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Email is required",
          path: ["email"],
        });
      }
      if (!data.phoneNumber || data.phoneNumber.trim() === "") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Phone number is required",
          path: ["phoneNumber"],
        });
      }
    }
  });

export default createUBOprofileForOldUserSchema;

export type CreateUBOprofileForOldUserSchema = z.infer<
  typeof createUBOprofileForOldUserSchema
>;

export const inviteTeamSchema = z.object({
  firstName: z.string().min(1, "required"),
  lastName: z.string().min(1, "required"),
  email: z.string().email().min(1, "required"),
  // role: z.string().min(1, "required"),
  role: z.enum(["ADMIN", "OWNER", "VIEWER"]),
});


export type InviteTeamSchema = z.infer<
  typeof inviteTeamSchema
>;

export const addDestinationBankSchema = z
  .object({
    accountName: z.string().min(1, "Account Name is required"),
    iban: z.string().optional(),
    currency: z.enum(["GBP", "EUR"]),
    mainBeneficiary: z.boolean().default(true),
    sortCode: z.string().optional(),
    accountNumber: z.union([
      z.string().regex(/^\d{8,10}$/, {
        message: "Account number must be between 8 and 10 digits.",
      }),
      z.string().length(0), // Allow empty string
      z.undefined(), // Allow undefined
    ]),
  })
  .superRefine(
    ({ currency, iban, sortCode, accountNumber }, refinementContext) => {
      if (currency === "EUR" && !iban) {
        return refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: "IBAN is required for EUR currency",
          path: ["iban"],
        });
      }

      if (currency === "GBP") {
        if (!sortCode) {
          return refinementContext.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Sort Code is required for GBP currency",
            path: ["sortCode"],
          });
        }
        if (!accountNumber) {
          return refinementContext.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Account Number is required for GBP currency",
            path: ["accountNumber"],
          });
        }
      }

      return refinementContext;
    }
  );
export type AddDestinationBankSchema = z.infer<typeof addDestinationBankSchema>;
const chainsEnum = [
  "polygon",
  "celo",
  "arbitrum",
  "optimism",
  "tron",
  "mainnet",
  "solana",
  "base",
] as const;

const chains = z.enum(chainsEnum, {
  errorMap: () => ({
    message: "Must Ethereum, Solana, Polygon etc",
  }),
});

export const setupWalletWithoutCurrencySchema = z
  .object({
    chain: chains,
    token: z.string().min(1, { message: "Stablecoin is required" }),
    walletAddress: z
      .string()
      .trim()
      .min(1, { message: "Wallet address is required" })
      .refine((value) => isEvmAddress(value) || isSolanaAddress(value), {
        message: "Wallet address must be a valid Ethereum or Solana address",
      }),
    confirmWalletAddress: z
      .string()
      .min(1, { message: "Confirm wallet address is required" }),
  })
  .refine((data) => data.walletAddress === data.confirmWalletAddress, {
    message: "Wallet addresses do not match",
    path: ["confirmWalletAddress"],
  });

export type SetupWalletWithoutCurrencySchema = z.infer<
  typeof setupWalletWithoutCurrencySchema
>;

export const setupWalletSchema = z
  .object({
    chain: chains,
    token: z.string().min(1, { message: "Stablecoin is required" }),
    currency: z.string().min(1, { message: "currency is required" }),
    walletAddress: z
      .string()
      .trim()
      .min(1, { message: "Wallet address is required" })
      .refine((value) => isEvmAddress(value) || isSolanaAddress(value), {
        message: "Wallet address must be a valid Ethereum or Solana address",
      }),
    confirmWalletAddress: z
      .string()
      .min(1, { message: "Confirm wallet address is required" }),
  })
  .refine((data) => data.walletAddress === data.confirmWalletAddress, {
    message: "Wallet addresses do not match",
    path: ["confirmWalletAddress"],
  });

export type SetupWalletSchema = z.infer<typeof setupWalletSchema>;

export const shareSchema = z.object({
  email: z.string().email().min(1, "required"),
  amountToSend: z.string(),
  settlementAmount: z.string(),
  network: z.string(),
  walletAddress: z.string(),
});

export type ShareSchema = z.infer<typeof shareSchema>;
