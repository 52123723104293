import React, { useState } from "react";
import { toast } from "react-toastify";
import { trpc } from "../../../lib/trpc";

const ROLES = ["ADMIN", "VIEWER", "OWNER"] as const;
type Role = (typeof ROLES)[number];

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

// Basic Button component
const Button: React.FC<ButtonProps> = ({ children, className, ...props }) => (
  <button className={`rounded px-2.5 py-[6.8px] font-semibold ${className}`} {...props}>
    {children}
  </button>
);

const PencilIcon: React.FC = () => (
  <svg
    className="w-[14px] h-[14px]"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path>
  </svg>
);

const TrashIcon: React.FC = () => (
  <svg
    className="w-[14px] h-[14px]"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
  </svg>
);

export default function Team() {
  const [searchTerm, setSearchTerm] = useState("");
  const [changeRoleModal, setChangeRoleModal] = useState<{
    isOpen: boolean;
    userId: number | null;
    currentRole: Role | null;
  }>({ isOpen: false, userId: null, currentRole: null });
  const [removeUserModal, setRemoveUserModal] = useState<{
    isOpen: boolean;
    userId: number | null;
  }>({ isOpen: false, userId: null });
  const [changeOwnerModal, setChangeOwnerModal] = useState<{
    isOpen: boolean;
    userId: number | null;
  }>({ isOpen: false, userId: null });

  const {
    data: organizationMembers,
    refetch,
    isLoading: isLoadingOrgMember,
  } = trpc.user.getOrganizationMembers.useQuery({});
  const changeRoleMutation = trpc.user.changeUserRole.useMutation();
  const removeUserMutation = trpc.user.removeUserFromOrg.useMutation();
  const changeOwnerMutation = trpc.user.changeOwner.useMutation();

  const filteredMembers = organizationMembers?.filter(
    (member) =>
      member.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      member.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      member.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleChangeRole = async (userId: number, newRole: Role) => {
    try {
      await changeRoleMutation.mutateAsync({
        userId,
        newRole,
      });
      toast.success("User role updated successfully");
      refetch();
    } catch (error: any) {
      toast.error(error.message || "Failed to change role");
    }
    setChangeRoleModal({ isOpen: false, userId: null, currentRole: null });
  };

  const handleRemoveUser = async (userId: number) => {
    try {
      await removeUserMutation.mutateAsync({
        userId,
      });
      toast.success("User removed successfully");
      refetch();
    } catch (error: any) {
      toast.error(error.message || "Failed to remove user");
    }
    setRemoveUserModal({ isOpen: false, userId: null });
  };

  const handleChangeOwner = async (userId: number) => {
    try {
      await changeOwnerMutation.mutateAsync({
        userId,
      });
      toast.success("Ownership transferred successfully");
      refetch();
    } catch (error) {
      toast.error("Failed to transfer ownership");
    }
    setChangeOwnerModal({ isOpen: false, userId: null });
  };

  return (
    <div className="bg-white border shadow rounded-xl border-hgray-200 overflow-hidden">
      <div className="p-4 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <h2 className="text-[17px] font-semibold text-htext-main">
            Members{" "}
            <span className="ml-1 text-[13.5px] text-hgray-400">
              {organizationMembers?.length || 0}
            </span>
          </h2>
          <div className="relative">
            <input
              type="text"
              className="py-2 pl-10 pr-4 text-[14px] text-hgray-500 font-medium border border-gray-300 rounded-md ring-hbrand-400 focus:border-hbrand-400 focus:outline-hbrand-400"
              placeholder="Name, email"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className={`w-5 h-5 ${searchTerm ? "text-hbrand-400" : "text-hgray-500"} `}
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <table className="min-w-full divide-y divide-hgray-200">
        <thead className="bg-hgray-100">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-[11.5px] font-inter font-medium tracking-wider text-left text-hgray-500 uppercase"
            >
              Name
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-[11.5px] font-inter font-medium tracking-wider text-left text-hgray-500 uppercase"
            >
              Email
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-[11.5px] font-inter font-medium tracking-wider text-left text-hgray-500 uppercase"
            >
              Roles
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-[11.5px] font-inter font-medium tracking-wider text-left text-hgray-500 uppercase"
            >
              Last log in
            </th>
            <th scope="col" className="relative px-6 py-3">
              <span className="sr-only">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-hgray-200">
          {isLoadingOrgMember && (
            <div className="w-full h-full absolute inset-0 z-40 flex items-center justify-center bg-white bg-opacity-50">
                    <div className="loader"></div> {/* Add your loader here */}
                  </div>
          )}
          {filteredMembers?.map(
            ({ firstName, lastName, role, userId, email }) => (
              <tr key={userId}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-[14.5px] font-medium text-htext-main">{`${firstName} ${lastName}`}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-[14.5px] font-normal text-hgray-500">{email}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`"inline-flex px-2 py-[5px] text-[11.5px] font-semibold leading-5 ${role === 'ADMIN' ? "text-hsuccess-700 bg-hsuccess-50" : role === 'VIEWER' ? "text-hgray-700 bg-hgray-100" : "text-hwarning-700 bg-hwarning-50"} rounded-2xl`}>
                    {role}
                  </span>
                </td>
                <td className="px-6 py-4 text-[14.5px] font-normal text-hgray-500 whitespace-nowrap">
                  Mon Jun 20, 2023 9:38 PM
                </td>
                <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                  {role === "OWNER" ? (
                    <div className="flex justify-end space-x-2">
                      <Button
                        onClick={() =>
                          setChangeOwnerModal({ isOpen: true, userId })
                        }
                        className="flex items-center text-hgray-500 border-[1.5px] rounded-lg border-hgray-300 hover:text-gray-900"
                      >
                        <PencilIcon />
                        <span className="ml-1.5 text-[13.5px]">Change owner</span>
                      </Button>
                    </div>
                  ) : (
                    <div className="flex justify-end space-x-2">
                      <Button
                        onClick={() =>
                          setChangeRoleModal({
                            isOpen: true,
                            userId,
                            currentRole: role as Role,
                          })
                        }
                        className="flex items-center text-hgray-500 border-[1.5px] rounded-lg border-hgray-300 hover:text-hgray-900 hover:bg-hgray-50 shadow-s-light"
                      >
                        <PencilIcon />
                        <span className="ml-1.5 text-[13.5px]">Change role</span>
                      </Button>

                      <Button
                        onClick={() =>
                          setRemoveUserModal({ isOpen: true, userId })
                        }
                        className="flex items-center text-herror-400 border-[1.5px] rounded-lg border-herror-300 hover:text-herror-700 hover:bg-herror-50 shadow-s-light"
                      >
                        <TrashIcon />
                        <span className="ml-1 text-[13.5px]">
                          {removeUserMutation.isLoading ? (
                            <span className="loading loading-spinner loading-sm"></span>
                          ) : (
                            "Remove"
                          )}
                        </span>
                      </Button>
                    </div>
                  )}
                </td>
              </tr>
            )
          )}

        </tbody>
      </table>
          {filteredMembers?.length === 0 && !isLoadingOrgMember && (
            <div className="w-full p-[20px] mx-auto">
              <p className="text-center font-inter text-[14.5px] text-hgray-400 font-medium">You have no members with this name!!!</p>
            </div>
          )}

      {/* Change Role Modal */}
      {changeRoleModal.isOpen && (
        <div
          className="fixed inset-0 z-[99999] overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-hgray-900/[0.8] transition-opacity "
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
              <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <div className="">
                  <div className="mt-1.5">
                    <h3
                      className="text-[16px] font-inter font-medium leading-6 text-htext-main"
                      id="modal-title"
                    >
                      Change User Role
                    </h3>
                    <div className="mt-[2.5px]">
                      <p className="text-[13px] font-inter text-hgray-500 font-normal">
                        Select a new role for this user.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-6">
                  <div className="shadow-sm focus:ring focus:ring-indigo-200 w-full rounded-md border-[1px] border-gray-300 pl-4 pr-3 focus:border-indigo-300  outline-indigo-300 focus:ring-opacity-50">
                  <select
                    className="border-none text-[13px] font-inter font-medium focus:border-none outline-none py-2 w-full"
                    onChange={(e) =>
                      handleChangeRole(
                        changeRoleModal.userId!,
                        e.target.value as Role
                      )
                    }
                  >
                    <option value="">Select a role</option>
                    {ROLES.filter(
                      (role) => role !== changeRoleModal.currentRole
                    ).map((role) => (
                      <option key={role} value={role}>
                        {role}
                      </option>
                    ))}
                  </select>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 hover:bg-gray-100 focus:outline-none focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() =>
                    setChangeRoleModal({
                      isOpen: false,
                      userId: null,
                      currentRole: null,
                    })
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Remove User Modal */}
      {removeUserModal.isOpen && (
        <div
          className="fixed inset-0 z-[99999] overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-hgray-900/[0.8]  transition-opacity "
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
              <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <div className="">
                  <div className="mt-3">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Remove User
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to remove this user from the
                        organization?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm focus:ring-2 focus:ring-red-500 hover:bg-red-700 focus:outline-none focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => handleRemoveUser(removeUserModal.userId!)}
                >
                  {removeUserMutation.isLoading ? (
                    <span className="loading loading-spinner loading-sm"></span>
                  ) : (
                    "Remove"
                  )}
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 hover:bg-gray-50 focus:outline-none focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() =>
                    setRemoveUserModal({ isOpen: false, userId: null })
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Change Owner Modal */}
      {changeOwnerModal.isOpen && (
        <div
          className="fixed inset-0 z-[99999] overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-hgray-900/[0.8] transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
              <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <div className="">
                  <div className="mt-1.5">
                    <h3
                      className="text-[16px] font-inter font-medium leading-6 text-htext-main"
                      id="modal-title"
                    >
                      Change Owner
                    </h3>
                    <div className="mt-[2.5px]">
                      <p className="text-[13px] font-inter text-hgray-500 font-normal">
                        Select the user you want to transfer ownership to:
                      </p>
                      <div className="mt-6">
                        <div className="shadow-sm focus:ring focus:ring-indigo-200 w-full rounded-md border-[1px] border-gray-300 pl-4 pr-2 focus:border-indigo-300  outline-indigo-300 focus:ring-opacity-50">
                        <select
                    className="border-none text-[13px] font-inter font-medium focus:border-none outline-none py-2 w-full"
                    value={changeOwnerModal.userId || ""}
                          onChange={(e) =>
                            setChangeOwnerModal({
                              ...changeOwnerModal,
                              userId: Number(e.target.value),
                            })
                          }
                          aria-label="Select new owner"
                        >
                          <option value="">Select a new owner</option>
                          {organizationMembers
                            ?.filter((member) => member.role !== "OWNER")
                            .map((member) => (
                              <option key={member.userId} value={member.userId}>
                                {`${member.firstName} ${member.lastName} (${member.email})`}
                              </option>
                            ))}
                        </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 bg-hbrand-600 hover:bg-hbrand-700 focus:outline-none focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => handleChangeOwner(changeOwnerModal.userId!)}
                  disabled={!changeOwnerModal.userId}
                >
                  Transfer Ownership
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-hgray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 hover:bg-hgray-50 focus:outline-none focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() =>
                    setChangeOwnerModal({ isOpen: false, userId: null })
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
