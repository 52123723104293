import { toast } from "react-toastify";
import { trpc } from "../../../../lib/trpc";

function DeleteAccount({
  bankDetail,
  closeModal,
  id,
  refetch,
}: {
  bankDetail: {
    currency: string;
    iban: string;
    sortCode: string;
  };
  closeModal: () => void;
  id: number;
  refetch: () => void;
}) {
  const { mutate: deleteMutate, isLoading: isLoadingDeleteAccount } =
    trpc.user.customerDeleteBankDefault.useMutation({
      onError: (e) => {
        toast.error(e.message);
      },
      onSuccess: (res) => {
        refetch();
        toast.success(res.message);
        closeModal();
      },
    });

  const handleDeleteAccount = () => {
    deleteMutate({
      bankId: id as number,
    });
  };
  return (
    <div
      className="fixed inset-0 z-[99999] overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-hgray-900/[0.8] transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="inline-block max-w-lg my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-lg shadow-xl">
          <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
            <div className="">
              <div className="mt-3">
                <h3
                  className="text-lg font-medium leading-6 text-gray-900"
                  id="modal-title"
                >
                  Delete Account
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Are you sure you want to delete your bank with{" "}
                    <b>
                      {bankDetail?.iban
                        ? `Iban ${bankDetail?.iban}`
                        : `Sort Code ${bankDetail?.sortCode}`}{" "}
                    </b>{" "}
                    associated with <b>{bankDetail?.currency}</b>?. This action
                    cannot be undone.
                  </p>
                  <div className="mt-4"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
            <button
              type="button"
              className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 bg-herror-700 hover:bg-herror-600 focus:outline-none focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handleDeleteAccount}
            >
              {isLoadingDeleteAccount ? " Deleting..." : "Delete Account"}
            </button>
            <button
              type="button"
              className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 hover:bg-gray-50 focus:outline-none focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteAccount;
