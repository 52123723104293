import classNames from "classnames";

const Sidebar = ({ setSelectedSection, section, toggleDrawer }: any) => (
  <div className="flex flex-col mb-6 space-y-4">
    <a
      onClick={() => setSelectedSection("all")}
      className={classNames(
        "w-[40%] cursor-pointer py-2 pl-3 text-start text-[14px] font-semibold leading-[20px] text-gray-600",
        {
          "text-hbrand-630 border-l-2 border-l-hbrand-600 text-hbrand-600 ":
            "all" == section,
        }
      )}
    >
      All
    </a>
    <a
      onClick={() => setSelectedSection("aboutShiga")}
      className={classNames(
        "w-[40%] cursor-pointer py-2 pl-3 text-start text-[14px] font-semibold leading-[20px] text-gray-600",
        {
          "text-hbrand-630 border-l-2 border-l-hbrand-600 text-hbrand-600":
            "aboutShiga" == section,
        }
      )}
    >
      About Shiga
    </a>
    <a
      onClick={() => setSelectedSection("keyBenefits")}
      className={classNames(
        "w-[40%] cursor-pointer py-2 pl-3 text-start text-[14px] font-semibold leading-[20px] text-gray-600",
        {
          "text-hbrand-630 border-l-2 border-l-hbrand-600 text-hbrand-600":
            "keyBenefits" == section,
        }
      )}
    >
      Key Benefits
    </a>
    <a
      onClick={() => setSelectedSection("howToUseShiga")}
      className={classNames(
        "w-[40%] cursor-pointer py-2 pl-3 text-start text-[14px] font-semibold leading-[20px] text-gray-600",
        {
          "text-hbrand-630 border-l-2 border-l-hbrand-600 text-hbrand-600":
            "howToUseShiga" == section,
        }
      )}
    >
      How to use Shiga
    </a>

    <a
      onClick={() => setSelectedSection("contactSupport")}
      className={classNames(
        "w-[40%] cursor-pointer py-2 pl-3 text-start text-[14px] font-semibold leading-[20px] text-gray-600",
        {
          "text-hbrand-630 border-l-2 border-l-hbrand-600 text-hbrand-600":
            "contactSupport" == section,
        }
      )}
    >
      Contact & Support
    </a>

    <div className="w-[280px] rounded-lg bg-hgray-50 p-6">
      <h1 className="text-2xl font-bold font-ibm-plex text-hgray-900">
        How can we help?
      </h1>
      <div className=" mt-1 text-[14px] leading-[20px] text-gray-600">
        Send a message to our support team
      </div>
      <button
        type="button"
        onClick={toggleDrawer}
        className="focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 me-2 mt-5 w-full rounded-lg border border-blue-700 bg-white px-5 py-2.5 text-center text-sm font-medium  text-blue-700"
      >
        Send us a messsage{" "}
      </button>
    </div>
  </div>
);


  export default Sidebar