import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../components/Common/isAuth";
import { LogOutIcon, SettingIcon } from "../../components/Common/svg";
import { trpc } from "../../lib/trpc";
import { getUserRoleLabel, IUserRole } from "../../utils";

const optionsMenu = [
  {
    title: "Settings",
    icon: SettingIcon,
  },
];

function ProfileHead() {
  const divRef = useRef<HTMLDivElement | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const utils = trpc.useUtils();
  const { data: user } = trpc.user.getAccountType.useQuery({});
  const { mutate: setAccountType } = trpc.user.setAccountType.useMutation({
    onSuccess: () => {
      utils.transaction.transaction.invalidate();
      utils.user.getAccountType.cancel();
      utils.user.getAccountType.invalidate();
      utils.user.getOrganizationMembers.invalidate();
    },
  });

  const toggleIsOpen = () => {
    setIsHovered(!isHovered);
  };

  useEffect(() => {
    const handler = (e: { target: EventTarget | null }) => {
      if (divRef.current && !divRef.current.contains(e.target as HTMLElement)) {
        setIsHovered(false);
      }
    };

    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  }, []);

  const handleLogout = () => {
    logout();
    queryClient.clear();
    navigate("/login");
  };
  return (
    <div ref={divRef}>
      <div>
        <div
          className="navBtn relative h-[42.5px] w-[42.5px] cursor-pointer overflow-hidden rounded-full bg-hbrand-400 hover:bg-hbrand-600 hover:border-hbrand-500 border-[1.5px] border-solid"
          onClick={toggleIsOpen}
        >
          <p className="mx-auto mt-[7.5px] text-center text-base font-semibold text-white">
            {user?.email[0].toUpperCase() ?? ""}
          </p>
        </div>
      </div>
      {isHovered && (
        <div className="scrollbar-none absolute right-12 top-[50px] z-50 mt-[18px] h-[190px] w-[250px] cursor-pointer overflow-y-hidden rounded-[8px] border-[1px] border-[#EAECF0] bg-[#FFFFFF] py-[12px] shadow-s-semi-light">
          <div className="h-full">
            <div className="flex items-center gap-x-[13px]  px-[16px]">
              <div className="relative h-[40px] w-[40px] cursor-pointer overflow-hidden rounded-full bg-hbrand-400 border-2 border-solid border-hbrand-300">
                <p className="mx-auto mt-[3.7px] text-center text-[18px] font-inter font-semibold text-white">
                  {user?.email[0] ?? ""}
                </p>
              </div>
              <div>
                <p className="text-[13.5px] font-inter font-semibold leading-5 tracking-normal text-[#101828] ">
                  {user?.email}
                </p>
                <p className="text-[11.5px] font-inter font-[400] leading-4 tracking-normal text-[#475467]">
                  {getUserRoleLabel(
                    user?.acountType as IUserRole
                  )}{" "}
                  Account
                </p>
              </div>
            </div>
            
              <div className="mt-[12px] border-t-[1px] border-[#EAECF0]">
                {optionsMenu.map((item, index) => (
                  <Link
                    to="/dashboard/account-settings"
                    onClick={() => setIsHovered(false)}
                    key={index}
                    className="flex items-center gap-x-2 px-[16px] py-3 last:-mt-1 hover:bg-hbrand-50"
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: item.icon }}
                      className="h-[16px] w-[16px]"
                    />
                    <h1 className="text-[13px] font-inter font-[500] leading-5 tracking-normal text-[#344054]">
                      {item.title}
                    </h1>
                  </Link>
                ))}
              </div>

            {user?.acountType !== "CORPORATE" && (
              <div className="border-y-[1px] border-[#EAECF0]">
                <div
                  onClick={() => {
                    setAccountType({
                      setAccountType:
                        user?.acountType === "INDIVIDUAL"
                          ? "TEAM_MEMBER"
                          : "INDIVIDUAL",
                    });
                    setIsHovered(false);
                  }}
                  className="flex items-center gap-x-2 px-[16px] py-3 last:-mt-1 hover:bg-hbrand-50"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: SettingIcon }}
                    className="h-[16px] w-[16px]"
                  />
                  <h1 className="text-[14px] font-[500] leading-5 tracking-normal text-[#344054]">
                    {user?.acountType === "INDIVIDUAL"
                      ? "Switch to Team Member"
                      : " Switch to Individual"}
                  </h1>
                </div>
              </div>
            )}
            <div
              className="my-auto flex  items-center gap-x-2 px-[16px] py-3 last:-mt-1 hover:bg-hbrand-50"
              onClick={handleLogout}
            >
              <span
                dangerouslySetInnerHTML={{ __html: LogOutIcon }}
                className="h-[16px] w-[16px]"
              />
              <h1 className="text-[14px] font-[500] leading-5 tracking-normal text-[#B42318]">
                Logout
              </h1>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfileHead;
