import { FC } from "react";

const TempoNotifi: FC = () => {

  return (
    <div className="tempo bg-login-bg relative h-screen  w-screen bg-cover backdrop-blur-[2px] overflow-hidden">
        <div className="tempo-notes w-[72%] mx-auto h-full flex flex-col items-center justify-center gap-y-4 overflow-hidden">
            <img className="-mt-[12rem]" src="/assets/mobile-desktop.svg" alt="desktop illustration" />

            <h3 className="font-satoshi text-2xl font-black text-white text-center mt-[1.8rem]">Mobile view is not available at the moment</h3>
            <p className="font-inter text-[14px] font-normal text-white text-center mt-[3px]">Please use Shiga on desktop while we work on this.</p>
        </div>
    </div>
  );
};

export default TempoNotifi;
