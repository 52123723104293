import { AlertCircle, CheckCircle, Loader, XCircle } from "lucide-react";
import { Link, useParams } from "react-router-dom";
import { trpc } from "../../lib/trpc";

export default function TeamInvite() {
  const { token } = useParams<{ token: string }>() as { token: string };
  const data = trpc.user.acceptInvitation.useQuery({ token });

  const renderContent = () => {
    switch (data.data?.status) {
      case "accepted":
        return (
          <>
            <CheckCircle className="mx-auto mb-4 h-16 w-16 text-green-500" />
            <h2 className="mb-2 text-center text-2xl font-bold">
              Invitation Accepted!
            </h2>
            <p className="mb-6 text-center text-gray-600">
              You have successfully joined the team .
            </p>
            <Link
              to="/dashboard/home"
              className="block w-full rounded bg-gray-200 px-4 py-2 text-center font-bold text-gray-800 transition duration-200 hover:bg-gray-300"
            >
              Go to Dashboard
            </Link>
          </>
        );
      case "invalid":
        return (
          <>
            <XCircle className="mx-auto mb-4 h-16 w-16 text-red-500" />
            <h2 className="mb-2 text-center text-2xl font-bold">
              Invalid Invitation
            </h2>
            <p className="mb-6 text-center text-gray-600">
              This invitation link is not valid. Please contact your team
              administrator.
            </p>
            <Link
              to="/login"
              className="block w-full rounded bg-gray-200 px-4 py-2 text-center font-bold text-gray-800 transition duration-200 hover:bg-gray-300"
            >
              Go to Login
            </Link>
          </>
        );
      case "unregistered":
        return (
          <>
            <XCircle className="mx-auto mb-4 h-16 w-16 text-red-500" />
            <h2 className="mb-2 text-center text-2xl font-bold">
              Unregistered User
            </h2>
            <p className="mb-6 text-center text-gray-600">
              Kindly sign up on shiga before accept an invitation
            </p>
            <Link
              to="/signup"
              className="block w-full rounded bg-gray-200 px-4 py-2 text-center font-bold text-gray-800 transition duration-200 hover:bg-gray-300"
            >
              Go to Sign up
            </Link>
          </>
        );
      case "expired":
        return (
          <div >
            <AlertCircle className="mx-auto mb-4 h-16 w-16 text-yellow-500" />
            <h2 className="mb-2 text-center text-2xl font-bold">
              Invitation Expired
            </h2>
            <p className="mb-6 text-center text-gray-600">
              This invitation has expired. Please request a new invitation from
              your team administrator.
            </p>
            <Link
              to="/login"
              className="block w-full rounded bg-gray-200 px-4 py-2 text-center font-bold text-gray-800 transition duration-200 hover:bg-gray-300"
            >
              Go to Login
            </Link>
          </div>
        );
      default:
        return (
          <>
            <Loader className="mx-auto mb-4 h-16 w-16 animate-spin text-blue-500" />
            <h2 className="mb-2 text-center text-2xl font-bold">
              Processing Invitation
            </h2>
            <p className="mb-6 text-center text-gray-600">
              Please wait while we process your invitation to join .
            </p>
          </>
        );
    }
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100">
      <div className="shadow-md w-full max-w-md rounded-lg bg-white p-8">
        {renderContent()}
      </div>
    </div>
  );
}
