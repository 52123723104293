import { zodResolver } from "@hookform/resolvers/zod";
import { CheckIcon, Loader2 } from "lucide-react";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Drawer from "../../../../components/Common/drawer";
import { getImageSrc } from "../../../../components/Common/table";
import Tooltip from "../../../../components/Common/tooltip";
import { chainOptions, fiatOptions } from "../../../../constant/options";
import { useModal } from "../../../../context/modalProvider";
import { trpc } from "../../../../lib/trpc";
import { formatNumberWithCommasAndTwoDecimals } from "../../../../utils/formatNumber";
import RadioButton from "../../../Common/buttons/radioBtn";
import { TransactionDetail } from "../../../Common/calDetails";
import Inputs from "../../../Common/inputs";
import MainLoader from "../../../Common/loader/mainLoader";
import {
  BackArrowSVG,
  BlueThinArrowSVG,
  calculator,
  circleSmallMinus,
  circleSmallPlus,
  CopyBlueSVG,
  dollarsSVG,
  EmailSVG,
  euroDarkerSmall,
  eurosSVG,
  flusteredWarning,
  gbpDarkerSmall,
  plusSVG,
  poundsSVG,
  shareIcon,
  slideRight,
  step1Warn,
  step2Warn,
  uSDCSVG,
  usdDarkerSmall,
  UsdtSvg,
  warnToolTipBlueSVG,
} from "../../../Common/svg";
import {
  AddDestinationBankSchema,
  addDestinationBankSchema,
  ShareSchema,
  shareSchema,
} from "../../get-started/onboarding-checklist/schema";
import DeleteAccount from "./deleteAccount";

type CurrencyTypes = "EUR" | "GBP";

const dropFiatOptions = [
  {
    value: "USD",
    type: "fiat",
  },
  {
    value: "EUR",
    type: "fiat",
  },
  {
    value: "GBP",
    type: "fiat",
  },
];

const dropStableOptions = [
  {
    value: "USDT",
    type: "stablecoin",
  },
  {
    value: "USDC",
    type: "stablecoin",
  },
];

const currencyIcons: any = {
  eur: euroDarkerSmall,
  gbp: gbpDarkerSmall,
  usd: usdDarkerSmall,
};

const stableCoinOptions = ["USDT", "USDC"];

const PortalOutDetails: FC = () => {
  const navigate = useNavigate();
  const stableDropdownRef = useRef<HTMLDivElement>(null);
  const fiatDropdownRef = useRef<HTMLDivElement>(null);
  const { showModal } = useModal();
  const [openItemId, setOpenItemId] = useState<number | string | null>(null);
  const [openItemId2, setOpenItemId2] = useState<number | string | null>(null);
  const [showCalPopup, setShowCalPopup] = useState(false);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [showAccPopup, setShowAccPopup] = useState(false);
  const [opnDelete, setOpenDelete] = useState(false);
  const [bankDetail, setBankDetail] = useState({
    currency: "",
    iban: "",
    sortCode: "",
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [calRate, setCalRate] = useState("0");
  const [calBase, setCalBase] = useState(`${dropStableOptions[0]?.value}`);
  const [calTarget, setCalTarget] = useState(`${dropFiatOptions[0]?.value}`);
  const [isUsdt, setIsUsdt] = useState(true);
  const [isUsdc, setIsUsdc] = useState(false);
  const [calculatedValue, setCalculatedValue] = useState("");
  const [amount, setAmount] = useState("");
  const [showDropDownFiat, setShowDropDownFiat] = useState(false);
  const [showDropDownStable, setShowDropDownStable] = useState(false);
  const [id, setId] = useState<number | null>(null);
  const [showEditAccPopup, setShowEditAccPopup] = useState(false);
  const [selectedValue, setSelectedValue] = useState<CurrencyTypes>();
  const [isCopied, setIsCopied] = useState(false);
  const [loadingBankId, setLoadingBankId] = useState<number | null>(null);

  const handleCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  function closeShowPopup() {
    if (showCalPopup === true) {
      setShowCalPopup(false);
    }
    if (showAccPopup === true) {
      setShowAccPopup(false);
    } else {
      setShowEditAccPopup(false);
    }
    reset();
  }

  useEffect(() => {
    setSearchParams((prev) => {
      prev.set("amountToSnd", calBase);
      prev.set("amountToRcv", calTarget);
      prev.set("calBase", amount);
      prev.set("calTarget", calculatedValue);
      return prev;
    });
  }, [calculatedValue, amount, calBase, calTarget, setSearchParams]);

  const toggleItem = (id: number | string) => {
    setOpenItemId(openItemId === id ? null : id);
  };

  const toggleItem2 = (id: number) => {
    setOpenItemId2(openItemId2 === id ? null : id);
  };

  // const handleNavigate = (route: string) => {
  //   navigate(route);
  // };

  const handleGoBack = () => {
    navigate("/dashboard/portal-accounts");
  };

  // Handle clicks outside of Stablecoin dropdown
  useEffect(() => {
    const handleClickOutsideStable = (event: any) => {
      if (
        stableDropdownRef.current &&
        !stableDropdownRef?.current?.contains(event.target)
      ) {
        setShowDropDownStable(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideStable);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideStable);
    };
  }, []);

  // Handle clicks outside of Fiat dropdown
  useEffect(() => {
    const handleClickOutsideFiat = (event: any) => {
      if (
        fiatDropdownRef.current &&
        !fiatDropdownRef.current.contains(event.target)
      ) {
        setShowDropDownFiat(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideFiat);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideFiat);
    };
  }, []);

  const isStableCoin = (str: string) => stableCoinOptions.includes(str);

  const { data: tr } = trpc.user.getCalculatedRate.useQuery({
    base: isStableCoin(calBase) ? "USD" : calBase,
    target: isStableCoin(calTarget) ? "USD" : calTarget,
  });

  useEffect(() => {
    if (amount === "" || isNaN(parseFloat(amount))) {
      setCalculatedValue("0");
    } else if (tr) {
      const rate = tr.exchange_rate; // Adjust based on the actual response structure
      const roundedUpRates = rate.toFixed(2);
      setCalRate(roundedUpRates);
      const targetValue = (parseFloat(amount) * rate).toFixed(2);
      setCalculatedValue(targetValue);
    }
  }, [tr, amount]);

  const { mutate: shareLink, isLoading: isSharingLink } =
    trpc.transaction.sharePaymentLink.useMutation({
      onSuccess: () => {
        resetShareLink();
        toast.success("Payment request sent to email");
      },
      onError: () => {},
    });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setAmount(value);
    }
  };

  //   edit account details
  const { mutateAsync: getSession, isLoading: isLoadingGettingSession } =
    trpc.user.isSessionIdExpired.useMutation({
      onError: (e) => {
        toast.error(e.message);
      },
    });

  const {
    reset: resetShareLink,
    register: registerShareLInk,
    handleSubmit,
    formState: { errors: shareLinkErrors },
  } = useForm<ShareSchema>({
    resolver: zodResolver(shareSchema),
    defaultValues: {
      email: "",
      walletAddress: "",
      amountToSend: "",
      settlementAmount: "",
      network: "",
    },
  });

  const onShareSubmit = (values: ShareSchema) => {
    // Convert the searchParams values to numbers, with a fallback to 0 if they are null or invalid
    const calBase = parseFloat(searchParams.get("calBase") || "0");
    const amountToSnd = searchParams.get("amountToSnd") || "";
    const calTarget = parseFloat(searchParams.get("calTarget") || "0");
    const amountToRcv = searchParams.get("amountToRcv") || "";

    // Format the amounts using the converted numbers
    const amountToSend = `${formatNumberWithCommasAndTwoDecimals(
      calBase
    )} ${amountToSnd}`;
    const settlementAmount = `${formatNumberWithCommasAndTwoDecimals(
      calTarget
    )} ${amountToRcv}`;

    // Call the shareLink function with the formatted values
    shareLink({
      walletAddress:
        unblockWallets?.find((wallet) => wallet.network == "optimism")
          ?.address || "",
      email: values.email,
      amountToSend,
      settlementAmount,
      network: "optimism",
    });
  };

  const { mutateAsync: triggerOtp } = trpc.user.triggerOtp.useMutation({
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const methods = useForm<AddDestinationBankSchema>({
    resolver: zodResolver(addDestinationBankSchema),
    defaultValues: {
      accountNumber: "",
      iban: "",
      accountName: "",
      sortCode: "",
      currency: undefined,
      mainBeneficiary: true,
    },
  });

  const {
    register,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const { data: unblockWallets, isLoading: isLoadingUnblockWallets } =
    trpc.user.listUnblockWallets.useQuery({});

  const {
    data: userAccount,
    isLoading: isLoadingUserAcct,
    refetch: refetchUserAccount,
    isRefetching: isRefetchingUserAcct,
  } = trpc.user.getBankAccount.useQuery({});

  const { mutate: setCustomerSetBankDefault } =
    trpc.user.customerSetBankDefault.useMutation({
      onError: (e) => {
        toast.error(e.message);
      },
      onSuccess: (res) => {
        refetchUserAccount();
        toast.success(res.message);
      },
      onSettled: () => {
        setLoadingBankId(null);
      },
    });

  const {
    mutate: createDestinationBankAcct,
    isLoading: isLoadingCreateDestinationBankAcct,
  } = trpc.user.setUpBank.useMutation({
    onSuccess: () => {
      reset();
      refetchUserAccount();
      setShowAccPopup(false);
      toast.success("Bank Account Created");
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const { mutate: updateUpBank, isLoading: isUpdateUpBank } =
    trpc.user.updateUpBank.useMutation({
      onSuccess: () => {
        reset();
        refetchUserAccount();
        closeShowPopup();
        toast.success("Bank Account Updated");
      },

      onError: (e) => {
        console.log(e);
      },
    });

  const onSetUpBankAccount = async (data: AddDestinationBankSchema) => {
    try {
      const response = await getSession({});
      if (response.sessionIdHasExpired) {
        await triggerOtp({});
        showModal();
      } else {
        if (showEditAccPopup && id) {
          updateUpBank({ input: { ...data, id } });
        } else {
          createDestinationBankAcct({ input: data });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  console.log(userAccount);
  const handleRadioChangeValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedValue(event.target.value as CurrencyTypes);
    setValue("currency", event.target.value as CurrencyTypes);
  };

  useEffect(() => {
    if (showEditAccPopup && userAccount) {
      const accountData = userAccount.find((item) => item.id == id);
      // Populate form fields with fetched data
      if (accountData) {
        setValue("accountNumber", accountData.accountNumber || "");
        setValue("iban", accountData.iban || "");
        setValue("accountName", accountData.accountName || "");
        setValue("sortCode", accountData.sortCode || "");
        setValue("currency", accountData.currency as "GBP" | "EUR");
        setSelectedValue(accountData?.currency as "GBP" | "EUR");
      }
    }
  }, [showEditAccPopup, userAccount, setValue]);

  if (isLoadingUserAcct) {
    return <MainLoader />;
  }

  return (
    <div className="w-screen h-screen pt-4 pb-2 bg-white portal-in-stable">
      <div className="w-full h-full overflow-hidden portal-in-stable-content px-60">
        <div className="flex items-center mt-5 content-nav">
          <p
            className="flex cursor-pointer items-center text-[13px] font-semibold text-hbrand-600 hover:text-hbrand-700"
            onClick={() => handleGoBack()}
          >
            <span
              className="mr-2.5"
              dangerouslySetInnerHTML={{ __html: BackArrowSVG }}
            />
            Go back
          </p>
        </div>

        <div className="h-full mx-auto overflow-y-scroll content-nav hide-scrollbar mt-9 pb-52">
          <div className="nav-top">
            <div className="relative flex items-center justify-between px-5 py-4 rounded-lg top-type bg-new-linear shadow-s-light">
              <div className="z-10">
                <p className="text-[12px] font-semibold text-hwarning-700">
                  PORTAL OUT OF STABLECOIN
                </p>
                <h3 className="mt-1.5 text-[22px] font-semibold text-htext-main">
                  Swap STABLECOIN to FIAT
                </h3>

                <div className="mt-5 text-steps">
                  <p className="text-[12px] font-medium text-htext-subdued">
                    STEPS
                  </p>

                  <div className="mt-3.5 flex items-center">
                    <span
                      className=""
                      dangerouslySetInnerHTML={{ __html: step1Warn }}
                    />
                    <p className="ml-1.5 text-[14px] font-normal text-htext-subdued">
                      Send your fiat currency
                    </p>
                  </div>

                  <div className="flex items-center mt-2">
                    <span
                      className=""
                      dangerouslySetInnerHTML={{ __html: step2Warn }}
                    />
                    <p className="ml-1.5 text-[14px] font-normal text-htext-subdued">
                      Receive your stablecoin
                    </p>
                  </div>
                </div>
              </div>

              <div className="z-10 text-btns">
                <button
                  className="flex w-[210px] items-center justify-center gap-x-2 rounded-lg border border-solid border-hbrand-600 bg-white px-[10px] py-[9px] text-[13px] font-semibold text-hbrand-700 shadow-s-light hover:border-hbrand-700 hover:bg-hgray-50 hover:text-hbrand-600"
                  type="button"
                  onClick={() => setShowCalPopup(true)}
                >
                  <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: calculator }}
                  />
                  <p>Calculate rate conversion</p>
                </button>
              </div>
            </div>

            <div className="flex items-start w-full py-3 mt-6 content-table">

            {(isLoadingUnblockWallets || isLoadingUserAcct || isRefetchingUserAcct) && (
                <div className="w-full h-full absolute inset-0 z-[9999] flex items-center justify-center bg-white bg-opacity-50">
                <div className="loader"></div> {/* Add your loader here */}
              </div>
            )}
              <div className="portal-account w-[50%] border-r-[0.5px] border-r-hgray-200 pl-2 pr-8">
                <div className="account-nav">
                  <h3 className="text-[19px] font-semibold leading-[31px] text-htext-label">
                    Your portal stablecoin wallet
                  </h3>
                  <p className="mt-[5px] w-[400px] text-[13px] font-normal leading-[19px] text-htext-subdued">
                    Send stablecoin to this Shiga generated virtual wallets
                    below and it converts into local currency in your fiat
                    settlement account.
                  </p>
                </div>

                <div className="pb-10 type-top pt-9">
                  <div className="space-y-4">
                    <div className="dash-toggle flex w-full items-center rounded-[10px] border border-hgray-200 bg-hgray-50 p-[5px] shadow-s-light">
                      <div
                        className={`toggle-usdc flex w-[50%] items-center justify-center ${
                          !isUsdc
                            ? "rounded-[6px] border-b-2 border-blue-500 bg-white shadow-s-light" // Dark red border when active
                            : "bg-transparent"
                        } cursor-pointer px-[12px] py-[4.5px]`}
                        onClick={() => {
                          setIsUsdc(false);
                          setIsUsdt(true);
                        }}
                      >
                        <p className="text-[12px] font-semibold text-htext-main">
                          USDT
                        </p>
                        <span
                          className="ml-1.5"
                          dangerouslySetInnerHTML={{ __html: UsdtSvg }}
                        />
                      </div>

                      <div
                        className={`toggle-usdc flex w-[50%] items-center justify-center ${
                          !isUsdt
                            ? "rounded-[6px] border-b-2 border-blue-500 bg-white shadow-s-light" // Dark red border when active
                            : "bg-transparent"
                        } cursor-pointer px-[12px] py-[4.5px]`}
                        onClick={() => {
                          setIsUsdt(false);
                          setIsUsdc(true);
                        }}
                      >
                        <p className="text-[12px] font-semibold text-htext-main">
                          USDC
                        </p>
                        <span
                          className="ml-1.5"
                          dangerouslySetInnerHTML={{ __html: uSDCSVG }}
                        />
                      </div>
                    </div>

                     {unblockWallets && unblockWallets.length > 0 ? (
                      unblockWallets
                        ?.filter(({ network }) => {
                          if (isUsdc) {
                            // USDC supported networks
                            return [
                              "mainnet",
                              "polygon",
                              "optimism",
                              "arbitrum",
                              "solana",
                              "base",
                            ].includes(network.toLowerCase());
                          } else if (isUsdt) {
                            // USDT supported networks
                            return [
                              "mainnet",
                              "polygon",
                              "optimism",
                              "arbitrum",
                              "tron",
                              "solana",
                            ].includes(network.toLowerCase());
                          }
                          return false; // no match, nothing will be shown
                        })
                        ?.sort((a, b) => a.network.localeCompare(b.network))
                        .map(({ address, network }) => {
                          return (
                            <div key={network}>
                              <button
                                onClick={() => toggleItem(network)}
                                className="flex items-center justify-between w-full px-6 py-3 rounded-lg bg-hgray-50 shadow-s-light focus:outline-none"
                              >
                                <div className="flex items-center">
                                  {
                                    chainOptions?.find(
                                      ({ value }) =>
                                        value.toLocaleLowerCase() ===
                                        network.toLocaleLowerCase()
                                    )?.icon
                                  }
                                  <span className="text-[13.5px] font-semibold capitalize text-htext-main">
                                    {network === "mainnet"
                                      ? "Ethereum"
                                      : network}
                                  </span>
                                </div>
                                {openItemId === network ? (
                                  <span
                                    className=""
                                    dangerouslySetInnerHTML={{
                                      __html: circleSmallMinus,
                                    }}
                                  />
                                ) : (
                                  <span
                                    className=""
                                    dangerouslySetInnerHTML={{
                                      __html: circleSmallPlus,
                                    }}
                                  />
                                )}
                              </button>

                              {openItemId === network && address && (
                                <div className="flex items-center justify-between rounded-lg bg-gray-50 px-[18px] py-3.5">
                                  <div>
                                    <p className="text-[11.5px] font-normal text-htext-main">
                                      Wallet address
                                    </p>
                                    <p className="mt-0.5 text-[13.5px] font-semibold text-htext-main">
                                      {address}
                                    </p>
                                  </div>

                                  <div
                                    onClick={() => handleCopy(address)}
                                    className="cursor-pointer"
                                  >
                                    {isCopied ? (
                                      <CheckIcon className="w-4 h-4 mt-2 text-blue-500" />
                                    ) : (
                                      <span className="mt-5">
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: CopyBlueSVG,
                                          }}
                                        />
                                      </span>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })
                    ) : (
                      <div className="flex min-h-[220px] h-auto max-h-[380px] flex-col items-center justify-center">
                        <div className="flex flex-col items-center py-6">
                          <img src="/recent-t.svg" alt="empty recent wallet" />
                          <h5 className="mt-4 text-center text-[13.5px] font-semibold">
                            You have no saved wallet
                          </h5>
                          <p className="mt-1 w-[250px] text-center text-[11px] font-normal text-htext-subdued">
                            Kindly add a wallet to get start with your
                            transactions
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="portal-settlement w-[50%] border-l-[0.5px] border-l-hgray-200 pl-8 pr-2">
                <div className="settlement-nav">
                  <h3 className="text-[19px] font-semibold leading-[31px] text-htext-label">
                    Your settlement bank account
                  </h3>
                  <p className="mt-[5px] w-[420px] text-[13px] font-normal leading-[19px] text-htext-subdued">
                    Stablecoin sent to your portal wallet are sent to your saved
                    local bank account.
                  </p>
                </div>
                
                <div className="type-top min-h-[313px] h-auto max-h-[380px] overflow-y-auto pb-7 pt-9 scrollbar-none">
                  {userAccount && userAccount.length > 0 ? (
                    userAccount?.map(
                      ({
                        accountName,
                        accountNumber,
                        currency,
                        iban,
                        sortCode,
                        useAsDefault,
                        id,
                      }) => (
                        <div key={id}>
                          <button
                            onClick={() => toggleItem2(id)}
                            className="flex items-center justify-between w-full px-6 py-5 rounded-lg bg-hgray-50 shadow-s-light focus:outline-none"
                          >
                            <div className="flex items-center">
                              {
                                fiatOptions?.find(
                                  ({ value }) =>
                                    value.toLocaleLowerCase() ==
                                    currency.toLocaleLowerCase()
                                )?.iconSmall
                              }
                              <p className="ml-3 text-[13px] font-semibold text-htext-main">
                                {currency} bank details
                              </p>
                            </div>
                            {currency === "USD" ? (
                              <p className="rounded-2xl border border-solid border-hbrand-200 bg-hbrand-50 px-[5px] py-[0.5px] text-[11px] font-medium text-hbrand-700">
                                Coming soon
                              </p>
                            ) : openItemId2 === id ? (
                              <span
                                className=""
                                dangerouslySetInnerHTML={{
                                  __html: circleSmallMinus,
                                }}
                              />
                            ) : (
                              <span
                                className=""
                                dangerouslySetInnerHTML={{
                                  __html: circleSmallPlus,
                                }}
                              />
                            )}
                          </button>
                          {openItemId2 === id && (
                            <div className="px-6 pb-2 rounded-lg bg-gray-50 shadow-s-light">
                              <div className="accountDetails flex w-full flex-col justify-center px-2 pb-5 pt-0.5">
                                <div className="flex w-full items-center justify-between py-2.5">
                                  <p className="text-[13.5px] font-normal text-htext-main">
                                    Currency
                                  </p>

                                  <div className="flex items-center">
                                    <p className="text-[14.5px] font-semibold text-htext-main">
                                      {currency.toUpperCase()}
                                    </p>
                                    {/* <span
                                        className="ml-2"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            currencyIcons[
                                              currency.toLowerCase()
                                            ],
                                        }}
                                      /> */}
                                  </div>
                                </div>

                                <div className="flex w-full items-center justify-between py-2.5">
                                  <p className="text-[13.5px] font-normal text-htext-main">
                                    Account name
                                  </p>
                                  <p className="text-[14.5px] font-semibold text-htext-main">
                                    {accountName}
                                  </p>
                                </div>
                                {currency !== "EUR" && (
                                  <div className="flex w-full items-center justify-between py-2.5">
                                    <p className="text-[13.5px] font-normal text-htext-main">
                                      Account number
                                    </p>
                                    <p className="text-[14.5px] font-semibold text-htext-main">
                                      {accountNumber}
                                    </p>
                                  </div>
                                )}

                                <div className="flex w-full items-center justify-between py-2.5">
                                  <p className="text-[13.5px] font-normal text-htext-main">
                                    {iban ? "IBAN" : "Sort Code"}
                                  </p>
                                  <p className="text-[14.5px] font-semibold text-htext-main">
                                    {iban ? iban : sortCode}
                                  </p>
                                </div>
                              </div>

                              <div className="flex items-center justify-between mt-4">
                                <label className="inline-flex items-center cursor-pointer">
                                  <input
                                    type="checkbox"
                                    className="sr-only peer"
                                    checked={useAsDefault}
                                    onChange={async () => {
                                      setLoadingBankId(id);
                                      const response = await getSession({});
                                      if (response.sessionIdHasExpired) {
                                        await triggerOtp({});
                                        showModal();
                                      } else {
                                        setCustomerSetBankDefault({
                                          bankId: id,
                                        });
                                      }
                                    }}
                                  />
                                  <div className="peer relative h-6 w-11 rounded-full bg-hgray-200 after:absolute after:start-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-hgray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white rtl:peer-checked:after:-translate-x-full dark:border-hgray-400 dark:bg-hgray-300"></div>
                                  <span className="ml-3 text-sm font-medium text-hgray-600">
                                    Set as default settlement account
                                  </span>
                                </label>
                                {(loadingBankId === id ||
                                  isLoadingGettingSession) && (
                                  <Loader2 className="h-4 w-4 animate-spin text-[#003399]" />
                                )}
                                <div className="flex items-center gap-x-4">
                                  <button
                                    onClick={() => {
                                      setShowEditAccPopup(true);
                                      setId(id);
                                    }}
                                    className="text-sm font-semibold text-hbrand-700 hover:text-hbrand-600"
                                  >
                                    Edit
                                  </button>
                                  <button
                                    type="button"
                                    className="flex items-center text-[12.5px] font-semibold text-herror-700 hover:text-herror-600"
                                    onClick={() => {
                                      setOpenDelete(true);
                                      setId(id);
                                      setBankDetail({
                                        currency: currency,
                                        iban: iban ?? "",
                                        sortCode: sortCode ?? "",
                                      });
                                    }}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    )
                  ) : (
                    <div className="flex flex-col items-center justify-center h-full">
                      <div className="flex flex-col items-center py-6">
                        <img
                          src="/recent-t.svg"
                          alt="empty recent settlement-account"
                        />
                        <h5 className="mt-4 text-center text-[13.5px] font-semibold">
                          You have no settlement accounts
                        </h5>
                        <p className="mt-1 w-[250px] text-center text-[11px] font-normal text-htext-subdued">
                          Kindly add an account to get start with your
                          transactions
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex items-center justify-between mt-2">
                  <div
                    className="flex items-center w-full cursor-pointer gap-x-1"
                    onClick={() => refetchUserAccount()}
                  >
                    <img
                      src="/assets/arrowRefresh.svg"
                      alt="refresh"
                      className={`h-[10px] w-[10px] cursor-pointer ${
                        isRefetchingUserAcct && "animate-spin"
                      }`}
                    />
                    <span className="text-[14.5px] font-semibold text-hbrand-700">
                      Refresh
                    </span>
                    <div className="mt-1 -ml-2">
                      <Tooltip
                        tipBody="This is your saved settlement accounts. Click to refresh."
                        tipClass="right-0"
                        tipStyle="reverse"
                      />
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      setShowAccPopup(true);
                      setSelectedValue("EUR");
                    }}
                    className="flex items-center justify-end w-full cursor-pointer hover:text-hbrand-600"
                  >
                    <span
                      className=""
                      dangerouslySetInnerHTML={{ __html: plusSVG }}
                    />
                    <p className="ml-2.5 text-[14.5px] font-semibold text-hbrand-700">
                      Add new account
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        openDrawerBox={showCalPopup}
        onClose={closeShowPopup}
        header="Rate calculator"
        action={
          <div>
            <button
              className="w-full rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[11px] text-[15px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
              type="button"
              onClick={() => closeShowPopup()}
            >
              Close
            </button>
          </div>
        }
      >
        <div className="w-full px-5 py-6 rounded-lg content-cal bg-hgray-50">
          <div className="flex items-center justify-between w-full px-5 py-4 bg-white border rounded-lg from-card border-hgray-300 shadow-s-light">
            <div className="w-[160px]">
              <p className="text-left text-[14px] font-medium text-htext-main">
                From
              </p>
              <input
                type="text"
                className="mt-2.5 bg-transparent text-left text-[18px] font-normal text-htext-main outline-none"
                placeholder={`${
                  calBase === "EUR"
                    ? "€"
                    : calBase === "GBP"
                      ? "£"
                      : calBase === "USD"
                        ? "$"
                        : "0"
                }`}
                value={amount}
                onChange={handleInputChange}
              />
            </div>

            <div className="from-drop">
              <p className="text-right text-[12px] font-normal capitalize text-htext-subdued">
                Stablecoin
              </p>
              <div
                className="mt-2.5 cursor-pointer rounded-[500px] border-0 bg-hgray-200 py-1 pl-2 pr-2.5 text-right text-[13px] font-medium text-htext-label outline-none hover:bg-hgray-300"
                onClick={() => setShowDropDownStable(!showDropDownStable)}
                ref={stableDropdownRef}
              >
                <div className="flex items-center">
                  <img
                    className="w-[14px]"
                    src={`/assets/${
                      calBase === "USDC" ? "usdcSmall" : "usdtSmall"
                    }.svg`}
                    alt={calBase}
                  />
                  <p className="ml-1 mr-2.5">{calBase}</p>
                  <span
                    className="cursor-pointer"
                    dangerouslySetInnerHTML={{
                      __html: BlueThinArrowSVG || "",
                    }}
                  />
                </div>
                {showDropDownStable && (
                  <ul className="absolute z-50 -ml-10 mt-3 w-[120px] rounded-lg border border-solid border-hgray-200 bg-hgray-100 py-3">
                    {dropStableOptions.map((option) => (
                      <li
                        key={option.value}
                        className="cursor-pointer px-4 py-1 text-left text-[13px] hover:bg-hgray-300"
                        onClick={() => {
                          setCalBase(option.value);
                          setShowDropDownStable(false);
                        }}
                      >
                        <p>{option.value}</p>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>

          <div className="middleCont flex w-full flex-col justify-center pl-16 pr-1 pt-1.5">
            <TransactionDetail
              type="Portal out of stablecoin"
              fee="- €4"
              amount={amount || "0"}
              rate={`x ${calRate}`}
            />
          </div>

          <div className="flex items-center justify-between w-full px-5 py-4 bg-white border rounded-lg to-card border-hgray-300 shadow-s-light">
            <div className="w-[160px]">
              <p className="text-left text-[14px] font-medium text-htext-main">
                To
              </p>
              <input
                type="text"
                className="mt-2.5 bg-transparent text-left text-[18px] font-normal text-htext-main outline-none"
                placeholder={`${
                  calTarget === "EUR"
                    ? "€"
                    : calTarget === "GBP"
                      ? "£"
                      : calTarget === "USD"
                        ? "$"
                        : "0"
                }`}
                readOnly
                value={calculatedValue}
              />
            </div>

            <div className="to-drop">
              <p className="text-right text-[12px] font-normal capitalize text-htext-subdued">
                Fiat
              </p>
              <div
                className="mt-2.5 cursor-pointer rounded-[500px] border-0 bg-hgray-200 py-1 pl-2 pr-2.5 text-right text-[13px] font-medium text-htext-label outline-none hover:bg-hgray-300"
                onClick={() => setShowDropDownFiat(!showDropDownFiat)}
                ref={fiatDropdownRef}
              >
                <div className="flex items-center">
                  <img
                    className="w-[14px]"
                    src={`/assets/${
                      calTarget === "USD"
                        ? "dollarSmall"
                        : calTarget === "EUR"
                          ? "euroSmall"
                          : "poundsSmall"
                    }.svg`}
                    alt={calTarget}
                  />
                  <p className="ml-1 mr-2.5">{calTarget}</p>
                  <span
                    className="cursor-pointer"
                    dangerouslySetInnerHTML={{
                      __html: BlueThinArrowSVG || "",
                    }}
                  />
                </div>
                {showDropDownFiat && (
                  <ul className="absolute z-50 -ml-10 mt-3 w-[120px] rounded-lg border border-solid border-hgray-200 bg-hgray-100 py-3">
                    {dropFiatOptions.map((option) => (
                      <li
                        key={option.value}
                        className="cursor-pointer px-4 py-1 text-left text-[13px] hover:bg-hgray-300"
                        onClick={() => {
                          setCalTarget(option.value);
                          setShowDropDownFiat(false);
                        }}
                      >
                        <p>{option.value}</p>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center w-full pb-2 pt-7">
            <div
              onClick={() => {
                if (!amount || !calculatedValue) return;
                setShowCalPopup(false);
                setShowSharePopup(true);
              }}
              className="flex items-center justify-center cursor-pointer w-fit text-hbrand-700 hover:border-b hover:border-hbrand-600 hover:text-hbrand-600"
            >
              <span
                className=""
                dangerouslySetInnerHTML={{ __html: shareIcon }}
              />
              <p className="ml-2.5 text-[14px] font-semibold">
                Share payment request
              </p>
            </div>
          </div>
        </div>
        {userAccount
          ?.filter(({ currency }) => currency == calTarget)
          .slice(0, 1)
          ?.map(({ currency, accountName, accountNumber, sortCode, iban }) => (
            <div className="w-full py-5 mt-10 border-t content-info border-t-hgray-200">
              <div className="info-text">
                <h5 className="text-[13px] font-medium text-htext-main">
                  Settlement information
                </h5>
                <p className="mt-1 w-[450px] text-[13px] font-normal text-htext-subdued">
                  Stablecoin sent to your portal wallet are sent to your saved
                  local bank account
                </p>
              </div>

              <div className="flex flex-col justify-center p-6 mt-4 rounded-lg info-content gap-y-3 bg-hgray-50">
                <div className="flex items-center">
                  <span
                    className=""
                    dangerouslySetInnerHTML={{
                      __html: currencyIcons[currency.toLowerCase()],
                    }}
                  />
                  <p className="ml-2 text-[13px] font-semibold text-htext-main">
                    {currency.toUpperCase()} bank details
                  </p>
                </div>

                <div className="flex items-center justify-between w-full mt-3 ">
                  <p className="text-[12px] font-normal text-htext-main">
                    Currency
                  </p>

                  <div className="flex items-center">
                    <p className="text-[12px] font-semibold text-htext-main">
                      {currency.toUpperCase()}
                    </p>
                    <span
                      className="ml-1.5"
                      dangerouslySetInnerHTML={{
                        __html: currencyIcons[currency.toLowerCase()],
                      }}
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between w-full">
                  <p className="text-[12px] font-normal text-htext-main">
                    Account name
                  </p>

                  <p className="text-[12px] font-semibold text-htext-main">
                    {accountName}{" "}
                  </p>
                </div>

                {accountNumber && (
                  <div className="flex items-center justify-between w-full">
                    <p className="text-[12px] font-normal text-htext-main">
                      Account number
                    </p>

                    <p className="text-[12px] font-semibold text-htext-main">
                      {accountNumber}
                    </p>
                  </div>
                )}
                <div className="flex items-center justify-between w-full">
                  <p className="text-[12px] font-normal text-htext-main">
                    {iban ? "IBAN" : "Sort Code"}
                  </p>

                  <p className="text-[12px] font-semibold text-htext-main">
                    {iban ? iban : sortCode}
                  </p>
                </div>

                {/* <div className="flex items-center justify-between w-full">
                          <p className="text-[12px] font-normal text-htext-main">
                            Bank name
                          </p>

                          <p className="text-[12px] font-semibold text-htext-main">
                            Lloyds Bank
                          </p>
                        </div> */}
              </div>
            </div>
          ))}
      </Drawer>

      <Drawer
        openDrawerBox={showAccPopup || showEditAccPopup}
        onClose={closeShowPopup}
        header={showEditAccPopup ? "Edit account" : "Add new account"}
        subHeader="This is your local currency account"
        action={
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => closeShowPopup()}
              className="shadow-s-light] mr-[5px] rounded-lg border border-hgray-300 bg-white px-3.5 py-2.5 text-[15px] font-semibold text-hgray-700 hover:opacity-70"
            >
              Close
            </button>
            <button
              type="submit"
              // disabled={!isFormData}
              onClick={methods.handleSubmit(onSetUpBankAccount)}
              className="ml-[5px] rounded-lg border border-hbrand-600 bg-hbrand-600 px-12 py-2.5 text-[15px] font-semibold text-white shadow-s-light hover:opacity-80"
            >
              {isLoadingCreateDestinationBankAcct ||
              isUpdateUpBank ||
              isLoadingGettingSession ? (
                <span className="loading loading-spinner loading-sm"></span>
              ) : (
                "Save account details"
              )}
            </button>
          </div>
        }
      >
        <FormProvider {...methods}>
          <form className="mb-6">
            <fieldset>
              <div className="flex items-center ">
                <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">*</p>
                <p className="text-[13px] font-medium  text-htext-label">
                  Select currency
                </p>
              </div>
              <div className="flex items-center justify-between w-full mt-2 radio-btns">
                <div className="btn-1 flex h-[2.5rem] w-[31.3%] items-center justify-between rounded-lg border border-hgray-200 bg-hgray-25 py-7 pl-3 pr-4">
                  <p className="flex items-center text-[14.5px] font-semibold text-htext-main">
                    <span
                      className="mr-2 w-18"
                      dangerouslySetInnerHTML={{ __html: eurosSVG }}
                    />
                    Euros
                  </p>
                  <RadioButton
                    name="currency"
                    value="EUR"
                    checked={selectedValue === "EUR"}
                    onChange={handleRadioChangeValue}
                    className="w-auto accs-radio"
                  />
                </div>

                <div className="btn-2 flex h-[2.5rem] w-[31.3%] items-center justify-between rounded-lg border border-hgray-200 bg-hgray-25 py-7 pl-3 pr-4">
                  <p className="flex items-center text-[14.5px] font-semibold text-htext-main">
                    <span
                      className="mr-2 w-18"
                      dangerouslySetInnerHTML={{
                        __html: poundsSVG,
                      }}
                    />
                    Pounds
                  </p>
                  <RadioButton
                    name="currency"
                    value="GBP"
                    checked={selectedValue === "GBP"}
                    onChange={handleRadioChangeValue}
                    className="w-auto accs-radio curren"
                  />
                </div>

                <div
                  className={`btn-3 flex h-[2.5rem] cursor-none w-[31.3%] items-center justify-between rounded-lg border border-hgray-200 ${"bg-hgray-100"}  py-7 pl-3 pr-4`}
                >
                  <p className="flex items-center text-[14.5px] font-semibold text-htext-main">
                    <span
                      className="mr-2 w-18"
                      dangerouslySetInnerHTML={{
                        __html: dollarsSVG,
                      }}
                    />
                    Dollars
                  </p>
                  <RadioButton
                    name="currency"
                    value="DOLLARS"
                    disabled={true}
                    onChange={handleRadioChangeValue}
                    className="accs-radio radio-doll w-auto cursor-none"
                  />
                </div>
              </div>

              <div className="mt-10 flex w-full items-start rounded-[12px] border border-hbrand-300 bg-hbrand-50 py-3.5 pl-4 pr-16 shadow-s-light">
                <span
                  className="mr-4"
                  dangerouslySetInnerHTML={{
                    __html: warnToolTipBlueSVG,
                  }}
                />
                <p className="text-[13.5px] font-semibold text-htext-label">
                  To successfully add your bank account, your bank account name
                  must match the business name you submitted on Shiga
                </p>
              </div>

              <div className="flex flex-col items-center justify-between w-full h-full">
                {true && (
                  <div className="w-full mt-5">
                    <div className="flex flex-col w-full pt-1 pb-6 mx-auto">
                      <label
                        htmlFor="accountName"
                        className="relative mt-[1.30rem] w-full"
                      >
                        <div className="mb-1 flex items-center text-[13px] font-medium">
                          <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                            *
                          </p>

                          <p className="text-htext-label">Account name</p>
                        </div>
                        <Inputs
                          type="text"
                          placeholder="Enter here"
                          register={register("accountName")}
                          inputClass={true}
                          error={errors.accountName}
                        />
                        {errors.accountName && (
                          <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                            {errors.accountName.message}!
                          </p>
                        )}
                      </label>
                      {selectedValue !== "EUR" && (
                        <label
                          htmlFor="accountNumber"
                          className="relative mt-[1.30rem] w-full"
                        >
                          <div className="mb-1 flex items-center text-[13px] font-medium">
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>

                            <p className="text-htext-label">Account number</p>
                          </div>
                          <Inputs
                            type="text"
                            placeholder="Enter here"
                            register={register("accountNumber")}
                            inputClass={true}
                            error={errors.accountNumber}
                          />
                          {errors.accountNumber && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.accountNumber.message}!
                            </p>
                          )}
                        </label>
                      )}

                      {selectedValue === "EUR" && (
                        <label
                          htmlFor="iban"
                          className="relative mt-[1.30rem] w-full"
                        >
                          <div className="mb-1 flex items-center text-[13px] font-medium">
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>

                            <p className="text-htext-label">IBAN</p>
                          </div>
                          <Inputs
                            type="text"
                            placeholder="Enter here"
                            register={register("iban")}
                            inputClass={true}
                            error={errors.iban}
                          />
                          {errors.iban && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.iban.message}!
                            </p>
                          )}
                        </label>
                      )}
                      {selectedValue === "GBP" && (
                        <label
                          htmlFor="sortCode"
                          className="relative mt-[1.30rem] w-full"
                        >
                          <div className="mb-1 flex items-center text-[13px] font-medium">
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>

                            <p className="text-htext-label">Sort code</p>
                          </div>
                          <Inputs
                            type="text"
                            placeholder="Enter here"
                            register={register("sortCode")}
                            inputClass={true}
                            error={errors.sortCode}
                          />
                          {/* {errors.sortCode && ( */}
                          <p
                            className={`font-ibm-plex absolute mt-1.5 text-[11.5px] ${
                              errors.sortCode
                                ? "font-medium text-red-600"
                                : "font-normal text-htext-subdued"
                            }`}
                          >
                            {errors.sortCode
                              ? errors.sortCode.message + "!"
                              : " Enter the 6-digit sort code"}
                          </p>
                          {/* )} */}
                        </label>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </fieldset>
          </form>
        </FormProvider>
      </Drawer>

      <Drawer
        openDrawerBox={showSharePopup}
        onClose={() => setShowSharePopup(false)}
        header={
          <p
            className="flex cursor-pointer items-center text-[13px] font-semibold text-hbrand-600 hover:text-hbrand-700"
            onClick={() => {
              setShowCalPopup(true);
              setShowSharePopup(false);
            }}
          >
            <span
              className="mr-2.5"
              dangerouslySetInnerHTML={{ __html: BackArrowSVG }}
            />
            Go back
          </p>
        }
        action={
          <div className="flex justify-end">
            <button
              className="w-[140px] rounded-lg border  border-solid border-hgray-300 bg-white px-[10px] py-[11px] text-[15px] font-semibold text-hgray-700 shadow-s-light hover:bg-hgray-50"
              type="button"
              onClick={() => {
                setShowCalPopup(true);
                setShowSharePopup(false);
              }}
            >
              Go Back
            </button>

            <button
              className="ml-7 w-[280px] rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[11px] text-[15px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
              type="button"
              onClick={() => {
                setShowCalPopup(false);
                setShowSharePopup(false);
              }}
            >
              Close
            </button>
          </div>
        }
      >
        <div className="w-full pb-5 content-info">
          <div className="info-text">
            <h5 className="text-[18px] font-semibold text-htext-main">
              Share payment request
            </h5>
            <p className="mt-1 w-[450px] text-[13px] font-normal text-htext-subdued">
              Your wallet address and the amount can be shared to receive your
              payment faster and more conveniently
            </p>
          </div>

          <div className="flex flex-col justify-center p-6 mt-4 rounded-lg info-content gap-y-3 bg-hgray-50">
            <div className="flex items-center w-full info-top gap-x-6">
              <div className="flex items-center">
                <img
                  src={getImageSrc(
                    `${searchParams.get("amountToSnd")} to ${searchParams.get(
                      "amountToRcv"
                    )}`
                  )}
                  alt="Conversion stuff"
                />
                <div className="ml-2.5 flex flex-col items-start justify-center">
                  <p className="text-[12px] font-normal text-htext-subdued">
                    Send
                  </p>
                  <p className="text-[18px] font-semibold text-htext-main">
                    {` ${searchParams.get("calBase")} ${searchParams.get(
                      "amountToSnd"
                    )}`}{" "}
                  </p>
                </div>
              </div>

              <span
                className=""
                dangerouslySetInnerHTML={{ __html: slideRight }}
              />
              <div className="flex flex-col items-start justify-center">
                <p className="text-[12px] font-normal text-htext-subdued">
                  Receive
                </p>
                <p className="text-[18px] font-semibold text-htext-main">
                  {` ${searchParams.get("calTarget")}${searchParams.get(
                    "amountToRcv"
                  )}`}{" "}
                </p>
              </div>
            </div>

            <div className="info-bottom mt-2.5 space-y-4 border-t border-t-hgray-200 py-4">
              <h6 className="text-[11px] font-normal text-htext-main">
                WALLET DETAILS
              </h6>
              <div className="flex items-start justify-between">
                <p className="text-[13px] font-normal text-htext-main">
                  Network
                </p>
                <div className="flex items-center">
                  <p className="text-[13px] font-semibold text-htext-main">
                    {
                      unblockWallets?.find(
                        (wallet) => wallet.network == "optimism"
                      )?.network
                    }
                  </p>
                  {
                    chainOptions?.find(
                      ({ value }) => value.toLocaleLowerCase() == "optimism"
                    )?.icon
                  }
                  {/* <span
                            className="ml-2"
                            dangerouslySetInnerHTML={{ __html: PolygonIcon }}
                          /> */}
                </div>
              </div>

              <div className="flex items-start justify-between">
                <p className="text-[13px] font-normal text-htext-main">
                  Wallet
                </p>

                <p className="w-[180px] break-words text-right text-[13px] font-semibold text-htext-main">
                  {
                    unblockWallets?.find(
                      (wallet) => wallet.network == "optimism"
                    )?.address
                  }
                </p>
              </div>
            </div>

            <div className="rounded-[12px] bg-hwarning-50 p-4 shadow-s-light">
              <div className="flex items-start">
                <span
                  className="-mt-1.5"
                  dangerouslySetInnerHTML={{
                    __html: flusteredWarning,
                  }}
                />
                <p className="ml-2.5 text-[12.5px] font-medium text-[#4E1D09]">
                  Always verify your wallet address to ensure a safe
                  transaction. Sending funds to an incorrect address may result
                  in a permanent loss of your assets. We cannot recover funds
                  sent to the wrong address.
                </p>
              </div>
            </div>

            <button
              className="mt-3.5 flex w-full items-center justify-center rounded-lg border border-solid border-brand-600 bg-white px-[10px] py-[8px] text-[12.5px] font-semibold text-hbrand-700 shadow-s-light hover:bg-hgray-50"
              type="button"
              onClick={() =>
                handleCopy(
                  unblockWallets?.find((wallet) => wallet.network == "optimism")
                    ?.address || ""
                )
              }
            >
              {isCopied ? (
                <CheckIcon className="w-4 h-4 text-green-500" />
              ) : (
                <span
                  className=""
                  dangerouslySetInnerHTML={{ __html: CopyBlueSVG }}
                />
              )}
              <p className="ml-1.5">
                {isCopied ? "Copied!" : "Copy wallet address"}
              </p>
            </button>
          </div>

          <form onSubmit={handleSubmit(onShareSubmit)} className="pb-10 mt-4">
            <div className="mb-1 flex items-center text-[13px] font-medium">
              <p className="text-htext-label">Share via email</p>
            </div>

            <div className="flex items-center gap-x-3">
              <label htmlFor="email" className="relative w-full">
                <Inputs
                  type="email"
                  placeholder="Enter here"
                  icon={EmailSVG}
                  inputClass={true}
                  register={registerShareLInk("email")}
                  error={shareLinkErrors.email}
                />
              </label>
              <button
                className="w-[100px] rounded-lg border  border-solid border-hbrand-600 bg-white px-[10px] py-[9.8px] text-[13px] font-semibold text-hbrand-700 shadow-s-light hover:bg-hgray-50"
                type="submit"
              >
                {isSharingLink ? (
                  <span className="loading loading-spinner loading-sm"></span>
                ) : (
                  "Send"
                )}
              </button>
            </div>
          </form>
        </div>
      </Drawer>

      {opnDelete && (
        <DeleteAccount
          bankDetail={bankDetail}
          closeModal={() => setOpenDelete(false)}
          id={id as number}
          refetch={refetchUserAccount}
        />
      )}
    </div>
  );
};

export default PortalOutDetails;
