import { poundsSmallSVG, poundsSVG, UsdtSvg } from "../svg";
import Tooltip from "../tooltip";

interface TransactionInfoProps {
  targetCurrency?: string;
  settlementCurrency?: string;
  targetNetwork?: string;
  walletAddress?: string;
  accountNumber?: string;
  sendingAddress: string;
  sortCode?: string;
  iban?: string;
  direction: "cryptoToFiat" | "fiatToCrypto";
}

const TransactionInfo = ({
  targetCurrency,
  settlementCurrency,
  targetNetwork,
  walletAddress,
  accountNumber,
  sortCode,
  sendingAddress,
  iban,
  direction,
}: TransactionInfoProps) => {
  return (
    targetCurrency &&
    settlementCurrency && (
      <div className="w-full mt-8 content-middle-lower">
        <div className="px-4 py-5 target rounded-2xl bg-hgray-50">
          <div className="flex items-center">
            <p className="text-[13px] font-medium">Target information</p>
            <Tooltip
              tipHeader="Target information"
              tipBody="This is a Shiga generated virtual wallet for you to convert stablecoin to your fiat settlement account"
              tipStyle="reverse"
            />
          </div>

          {direction === "cryptoToFiat" ? (
            <div className="lower-content">
              <div className="flex items-center justify-between w-full mt-3">
                <div className="lower-left flex w-[48.5%] items-center">
                  <div className="p-2 border border-solid rounded-full border-hgray-200">
                    <span dangerouslySetInnerHTML={{ __html: UsdtSvg }} />
                  </div>

                  <div className="lower-left-text ml-1.5 text-htext-main">
                    <p className="text-[14px] font-semibold">
                      {targetCurrency?.toUpperCase()}
                    </p>
                    <p className="text-[10.5px] font-normal">
                      {targetNetwork?.toUpperCase()}
                    </p>
                  </div>
                </div>

                <div className="lower-right w-[58.5%] text-htext-main">
                  <p className="text-[10.5px] font-normal">WALLET ADDRESS</p>
                  <p className="mt-0.5 w-[13.4rem] break-words text-[14px] font-semibold">
                    {walletAddress}
                  </p>
                </div>
              </div>
              <div className="ml-5 mt-5 w-[58.5%] text-htext-main">
                <p className="text-[10.5px] font-normal">SENDING ADDRESS</p>
                <p className="mt-0.5  break-words text-[14px] font-semibold">
                  {sendingAddress}
                </p>
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-between w-full mt-5 lower-content">
              <div className="lower-left flex w-[48.5%] items-center">
                <span className="border-1 border-solid border-black" dangerouslySetInnerHTML={{ __html: poundsSmallSVG }} />

                <div className="ml-2 lower-left-text text-htext-main">
                  <p className="text-[10.5px] font-normal">
                    {settlementCurrency === "EUR" ? "EURO" : "POUNDS"}
                  </p>
                  <p className="text-[14px] font-semibold">{accountNumber}</p>
                </div>
              </div>

              <div className="lower-right w-[58.5%] text-htext-main">
                <p className="text-[10.5px] font-normal">
                  {sortCode ? "SORT CODE" : "IBAN"}
                </p>
                <p className="text-[14px] font-semibold">{sortCode || iban}</p>
              </div>
            </div>
          )}
        </div>

        <div className="px-4 py-5 mt-3 settlement rounded-2xl bg-hgray-50">
          <div className="flex items-center">
            <p className="text-[13px] font-medium">Settlement information</p>
            <Tooltip
              tipHeader="Settlement information"
              tipBody="This is your saved local bank account to receive the swapped stablecoin amount"
              tipClass="right-0"
              tipStyle="reverse"
            />
          </div>

          {direction === "cryptoToFiat" ? (
            <div className="flex items-center justify-between w-full mt-5 lower-content">
              <div className="lower-left flex w-[48.5%] items-center">
                <span dangerouslySetInnerHTML={{ __html: poundsSVG }} />

                <div className="ml-2 lower-left-text text-htext-main">
                  <p className="text-[10.5px] font-normal">
                    {settlementCurrency === "EUR" ? "EURO" : "POUNDS"}
                  </p>
                  <p className="text-[14px] font-semibold">{accountNumber}</p>
                </div>
              </div>

              <div className="lower-right w-[58.5%] text-htext-main">
                <p className="text-[10.5px] font-normal">
                  {sortCode ? "SORT CODE" : "IBAN"}
                </p>
                <p className="text-[14px] font-semibold">{sortCode || iban}</p>
              </div>
            </div>
          ) : (
            <div className="mt-3 lower-content">
              <div className="flex items-center justify-between w-full">
                <div className="lower-left flex w-[48.5%] items-center">
                  <div className="p-2 border border-solid rounded-full border-hgray-200">
                    <span dangerouslySetInnerHTML={{ __html: UsdtSvg }} />
                  </div>

                  <div className="lower-left-text ml-1.5 text-htext-main">
                    <p className="text-[14px] font-semibold">
                      {targetCurrency?.toUpperCase()}
                    </p>
                    <p className="text-[10.5px] font-normal">
                      {targetNetwork?.toUpperCase()}
                    </p>
                  </div>
                </div>

                <div className="lower-right w-[58.5%] text-htext-main">
                  <p className="text-[10.5px] font-normal">WALLET ADDRESS</p>
                  <p className="mt-0.5 w-[13.4rem] break-words text-[14px] font-semibold">
                    {walletAddress}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default TransactionInfo;
