import BankAccounts from "./accounts";
import Wallet from "./wallets";

export default function Settlement() {
  return (
    <div className="grid grid-cols-2 gap-6 gap-x-10 mt-6 overflow-y-scroll hide-scrollbar">
      <div className="w-full">
        <BankAccounts />
      </div>
      <div className="w-full">
        <Wallet />
      </div>
    </div>
  );
}
