import { twMerge } from "tailwind-merge";

export type IPageContainerProps = {
  children?: React.ReactNode;
  className?: string;
};

export default function PageContainer({
  children,
  className,
}: IPageContainerProps) {
  return (
    <div className="overflow-hidden w-full">
      <div className={twMerge("xlsm:px-3 px-10 py-5 w-full", className)}>{children}</div>
    </div>
  );
}
