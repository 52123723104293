import { FC } from "react";
import { smallBriefcase } from "../../components/Common/svg";
import ProfileHead from "./profileHead";

interface navContent {
  navHeader?: string;
  navSubheader?: string;
  navSubextra?: string;
  navUser?: {
    name: string;
    email: string;
    company: string;
  };
  rateCalBtn?: string;
}

const Navbar: FC<navContent> = ({ navHeader, navSubheader, navUser }) => {
  return (
    <nav className="sticky top-0 z-30 flex items-center justify-between px-10 py-4 bg-white navbar">
      <div className="flex flex-col items-start w-fit">
        <h2 className="text-[28px] font-bold text-htext-main">{navHeader}</h2>
        <p className="flex items-center text-[14.5px] font-normal text-htext-main">
          {navSubheader}{" "}
          {navUser?.company ? (
            <p className="ml-1 flex items-center rounded-2xl border border-solid border-hgray-200 bg-hgray-50 px-2.5 py-0.5 text-[13.5px] font-medium">
              <span
                className="mr-1"
                dangerouslySetInnerHTML={{ __html: smallBriefcase }}
              />{" "}
              {navUser?.company}
            </p>
          ) : (
            ""
          )}
        </p>
      </div>
      <div className="flex items-center">
        <div className="mr-2.5 text-right text-[13.5px]">
          <p className="font-semibold text-htext-main">{navUser?.name}</p>
          <p className="-mt-0.5 font-normal text-htext-subdued">
            {navUser?.email}
          </p>
          {/* <button className="flex items-center border rounded-[8px] border-hgray-300 shadow-s-light py-2 px-4 hover:bg-hgray-50">
			   <span dangerouslySetInnerHTML={{ __html: RatesCalSVG }} />
			
				<p className="text-sm font-semibold text-hgray-700 ml-1.5">Rates & Calculator</p>
			</button> */}
        </div>
        <ProfileHead />
      </div>
    </nav>
  );
};

export default Navbar;
