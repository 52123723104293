import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../../context/modalProvider";
import { trpc } from "../../../lib/trpc";
import MiniLoader from "../../Common/loader/miniLoader";
import Popup from "../../Common/popup/index";
import {
  AccactivatedSVG,
  BriefcaseSVG,
  CircleCheckSVG,
  CircleUncheckedSVG,
  closeBtnSVG,
  FlightSVG,
  KybwarningSVG,
  PersonaSVG,
} from "../../Common/svg";

const DashGetStarted: FC = () => {
  const { showModal, setKyc } = useModal();
  const navigate = useNavigate();
  const { data: user, isLoading: isUserLoading } =
    trpc.user.getAccountType.useQuery({});
  const { data: invitationResponse } = trpc.user.checkForUboInvitation.useQuery(
    {}
  );

  const isUbo = invitationResponse?.status;

  const { mutate, isLoading: isTriggeringOtp } =
    trpc.user.triggerOtp.useMutation({
      onSuccess: () => {
        setKyc(true);
        showModal();
      },
    });

  const { mutate: activateAccount, isLoading: isActivatingAccount } =
    trpc.user.activateAccount.useMutation({
      onSuccess: () => {
        handleNavigate("/dashboard/home");
      },
    });

  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  const openModal = () => {
    mutate({});
  };
  // const closeModal = () => setIsModalOpen(false);

  function closeShowPopup() {
    setShowPopup(false);
  }

  useEffect(() => {
    if (user?.shigaKycStatus === "ACTIVE") {
      setShowPopup(true);
    }
  }, [user]);

  if (user?.acountType == null) {
    navigate("/get-started/account-type");
    return;
  }

  return (
    <>
      <div className="get-started px-8 py-6">
        <div className="get-started-content m-auto flex w-[650px] flex-col items-center justify-center">
          <div className="flex items-center">
            <span dangerouslySetInnerHTML={{ __html: FlightSVG }} />
            <h3 className="ml-3 text-[32px] font-semibold">
              Welcome to Shiga!
            </h3>
          </div>

          <div
            className={`get-bag ${
              !!isUbo && "uboBag"
            } relative mt-9 flex w-full items-center justify-between rounded-[16px] ${
              user?.shigaKycStatus == "REVIEWING" ||
              user?.shigaKycStatus == "ACTIVE"
                ? "get-bag-review bg-[#044EB3]"
                : user?.shigaKycStatus == "ATTENTION_REQUIRED"
                  ? "get-bag-error bg-herror-800"
                  : "get-bag-normal bg-get-linear "
            }  px-6 py-5`}
          >
            <div className="z-10">
              <h5
                className={`text-[22px] font-semibold ${
                  user?.shigaKycStatus == "REVIEWING" ||
                  user?.shigaKycStatus == "ACTIVE" ||
                  user?.shigaKycStatus == "ATTENTION_REQUIRED"
                    ? "text-white"
                    : "text-htext-main"
                }`}
              >
                {user?.shigaKycStatus == "REVIEWING" ? (
                  user?.acountType === "INDIVIDUAL" ? (
                    "We are verifying your identity"
                  ) : (
                    "We are reviewing your business KYB"
                  )
                ) : user?.shigaKycStatus == "ACTIVE" ? (
                  "Congratulations! Your account is activated!"
                ) : user?.shigaKycStatus == "ATTENTION_REQUIRED" ? (
                  `Your attention is required on a ${
                    user.acountType == "INDIVIDUAL" ? "KYC" : "KYB"
                  } item`
                ) : (
                  <span className={`${isUbo ? "block w-[28rem]" : ""} `}>
                    You signed up as {isUbo ? "an" : "a"}
                    <span className="capitalize">
                      {" "}
                      {user?.acountType === "INDIVIDUAL"
                        ? "individual"
                        : isUbo
                          ? "Ultimate Beneficial Owner (UBO)"
                          : "business"}
                    </span>
                  </span>
                )}
              </h5>
              <p
                className={`my-2 text-[13px] font-normal ${
                  user?.shigaKycStatus == "REVIEWING" ||
                  user?.shigaKycStatus == "ACTIVE" ||
                  user?.shigaKycStatus == "ATTENTION_REQUIRED"
                    ? "text-hgray-200"
                    : isUbo
                      ? "w-[28rem] text-htext-main"
                      : "text-htext-main"
                }`}
              >
                {user?.shigaKycStatus == "REVIEWING"
                  ? "We’ll get back to you within 48 hours"
                  : user?.shigaKycStatus == "ACTIVE"
                    ? "Now, let’s setup your wallets and virtual accounts (optional)"
                    : user?.shigaKycStatus == "ATTENTION_REQUIRED"
                      ? "Please check the checklist below to resolve"
                      : isUbo
                        ? "You’ve been invited to join Shiga by [Inviter] as an Ultimate Beneficial Owner (UBO) for [Business Name]."
                        : "You can only switch how you’re using Shiga before account activation"}
              </p>
              {/* <Link
              to="/dashboard/get-started/individual"
              className="text-[13px] font-semibold text-hbrand-700"
            >
              Switch to{" "}
              {user?.acountType == "INDIVIDUAL" ? "business" : "individual"}
            </Link> */}
            </div>

            <div className="z-10">
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    user?.shigaKycStatus == "ACTIVE"
                      ? AccactivatedSVG
                      : user?.shigaKycStatus == "ATTENTION_REQUIRED"
                        ? KybwarningSVG
                        : isUbo
                          ? PersonaSVG
                          : BriefcaseSVG,
                }}
              />
            </div>
          </div>

          <div className="mt-10 flex w-full items-center justify-between rounded-t-[16px] bg-hgray-100 px-6 pb-2 pt-5">
            <div>
              <h6 className="text-[19px] font-semibold text-htext-main">
                Onboarding checklist
              </h6>
              <p className="my-2 text-[13px] font-normal text-htext-subdued">
                Complete all steps to maximise your use of Shiga. Takes 8
                minutes.
              </p>
            </div>

            <div>
              <h6 className="text-[13.5px] font-semibold text-htext-label">
                {user?.kycStatusStep || 1 > 3 ? 3: user?.kycStatusStep || 1 } of 3 completed
              </h6>
            </div>
          </div>

          {isUserLoading || isUbo ? (
            <MiniLoader />
          ) : (
            <div className="content-checks w-full rounded-b-[16px] bg-hgray-25 px-6 py-4">
              <div className="flex w-full items-center justify-between pb-[1.15rem] pt-5">
                <div className="flex w-full items-center">
                  <span dangerouslySetInnerHTML={{ __html: CircleCheckSVG }} />
                  <p className="mx-3.5 text-[15.5px] font-medium text-htext-main">
                    Verify your email
                  </p>
                  <span className="rounded-[16px] border border-hsuccess-200 bg-hsuccess-50 px-2 py-[0.9px] text-[13px] font-medium text-hsuccess-700">
                    Done
                  </span>
                </div>
              </div>

              <div className="flex w-full items-center justify-between border-t border-hgray-200 pb-[1.15rem] pt-[1.12rem]">
                <div className="flex w-full items-center">
                  {((user?.kycStatusStep || 0) as number) > 1 ? (
                    <span
                      dangerouslySetInnerHTML={{ __html: CircleCheckSVG }}
                    />
                  ) : (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: CircleUncheckedSVG,
                      }}
                    />
                  )}
                  <p className="mx-3.5 text-[15.5px] font-medium text-htext-main">
                    Complete your personal profile
                  </p>
                  <span
                    className={`${
                      user?.personalProfileStep == "ATTENTION_REQUIRED" &&
                      "rounded-[16px] border border-herror-200 bg-herror-50 px-2 py-[0.9px] text-[13px] font-medium text-herror-700"
                    } ${
                      (user?.personalProfileStep == "ACTIVE" ||
                        user?.personalProfileStep == "SUBMITTED") &&
                      "rounded-[16px] border border-hsuccess-200 bg-hsuccess-50 px-2 py-[0.9px] text-[13px] font-medium text-hsuccess-700"
                    } ${
                      user?.personalProfileStep == "REVIEWING" &&
                      "rounded-[16px] border border-hbrand-200 bg-hbrand-50 px-2 py-[0.9px] text-[13px] font-medium text-hbrand-700"
                    }`}
                  >
                    {user?.personalProfileStep == "ATTENTION_REQUIRED"
                      ? "Requiring attention"
                      : user?.personalProfileStep == "REVIEWING"
                        ? "In review"
                        : user?.personalProfileStep == "ACTIVE"
                          ? "Done"
                          : user?.personalProfileStep == "SUBMITTED"
                            ? "Submitted"
                            : ""}
                  </span>
                </div>
                <div className="check-btn">
                  <button
                    type="button"
                    onClick={() =>
                      handleNavigate(
                        `/get-started/onboarding-checklist/complete-profile`
                      )
                    }
                    className={`${
                      (((user?.kycStatusStep || 0) as number) > 1 &&
                        !(user?.personalProfileStep == "ATTENTION_REQUIRED") &&
                        user?.personalProfileStep == "SUBMITTED") ||
                      user?.personalProfileStep == "REVIEWING" ||
                      user?.personalProfileStep == "ACTIVE"
                        ? "border-0 text-[14px] font-semibold text-gray-600"
                        : "rounded-lg border border-hbrand-600 bg-hbrand-600 text-white shadow-s-light hover:bg-hbrand-700"
                    }  px-9 py-2 text-[13px] font-medium`}
                  >
                    {(((user?.kycStatusStep || 0) as number) > 1 &&
                      !(user?.personalProfileStep == "ATTENTION_REQUIRED") &&
                      user?.personalProfileStep == "SUBMITTED") ||
                    user?.personalProfileStep == "REVIEWING" ||
                    user?.personalProfileStep == "ACTIVE"
                      ? "View"
                      : user?.personalProfileStep == "ATTENTION_REQUIRED"
                        ? "Resolve"
                        : "Start"}
                  </button>
                </div>
              </div>

              {user?.acountType === "CORPORATE" && !isUbo && (
                <div className="flex w-full items-center justify-between border-t border-hgray-200 pb-[1.15rem] pt-[1.12rem]">
                  <div className="flex w-full items-center">
                    {((user?.kycStatusStep || 0) as number) > 2 ? (
                      <span
                        dangerouslySetInnerHTML={{ __html: CircleCheckSVG }}
                      />
                    ) : (
                      <span
                        dangerouslySetInnerHTML={{ __html: CircleUncheckedSVG }}
                      />
                    )}{" "}
                    <p className="mx-3.5 text-[15.5px] font-medium text-htext-main">
                      Provide your business information
                    </p>
                    <span
                      className={`${
                        user?.businessInformationStep == "ATTENTION_REQUIRED" &&
                        "rounded-[16px] border border-herror-200 bg-herror-50 px-2 py-[0.9px] text-[13px] font-medium text-herror-700"
                      } ${
                        (user?.businessInformationStep == "ACTIVE" ||
                          user?.businessInformationStep == "SUBMITTED") &&
                        "text-hsuccess-700 px-2 rounded-[16px] border border-hsuccess-200 bg-hsuccess-50 py-[0.9px] text-[13px] font-medium"
                      } ${
                        user?.businessInformationStep == "REVIEWING" &&
                        "rounded-[16px] border border-hbrand-200 bg-hbrand-50 px-2 py-[0.9px] text-[13px] font-medium text-hbrand-700"
                      }`}
                    >
                      {user?.businessInformationStep == "ATTENTION_REQUIRED"
                        ? "Requiring attention"
                        : user?.businessInformationStep == "REVIEWING"
                          ? "In review"
                          : user?.businessInformationStep == "ACTIVE"
                            ? "Done"
                            : user?.businessInformationStep == "SUBMITTED"
                              ? "Submitted"
                              : ""}
                    </span>
                  </div>
                  <div className="check-btn">
                    <button
                      disabled={((user?.kycStatusStep || 0) as number) < 2}
                      onClick={() =>
                        handleNavigate(
                          `/get-started/onboarding-checklist/business-information`
                        )
                      }
                      className={`${
                        (((user?.kycStatusStep || 0) as number) == 2 &&
                          !(
                            user?.businessInformationStep ==
                            "ATTENTION_REQUIRED"
                          ) &&
                          user?.businessInformationStep == "SUBMITTED") ||
                        user?.businessInformationStep == "REVIEWING" ||
                        user?.businessInformationStep == "ACTIVE"
                          ? "border-0 text-[14px] font-semibold text-gray-600"
                          : ((user?.kycStatusStep || 0) as number) < 2
                            ? "cursor-not-allowed border-hgray-200 bg-hgray-100 text-hgray-400"
                            : "rounded-lg border border-hbrand-600 bg-hbrand-600 text-white shadow-s-light hover:bg-hbrand-700"
                      }  px-9 py-2 text-[13px] font-medium`}
                    >
                      {(((user?.kycStatusStep || 0) as number) > 2 &&
                        !(
                          user?.businessInformationStep == "ATTENTION_REQUIRED"
                        ) &&
                        user?.businessInformationStep == "SUBMITTED") ||
                      user?.businessInformationStep == "REVIEWING" ||
                      user?.businessInformationStep == "ACTIVE"
                        ? "View"
                        : user?.businessInformationStep == "ATTENTION_REQUIRED"
                          ? "Resolve"
                          : "Start"}
                    </button>
                  </div>
                </div>
              )}

              {!!isUbo && (
                <div className="flex w-full items-center justify-between border-t border-hgray-200 pb-[1.15rem] pt-[1.12rem]">
                  <div className="flex w-full items-center">
                    {((user?.kycStatusStep || 0) as number) > 2 ? (
                      <span
                        dangerouslySetInnerHTML={{ __html: CircleCheckSVG }}
                      />
                    ) : (
                      <span
                        dangerouslySetInnerHTML={{ __html: CircleUncheckedSVG }}
                      />
                    )}{" "}
                    <p className="mx-3.5 text-[15.5px] font-medium text-htext-main">
                      Complete UBO information
                    </p>
                    <span
                      className={`${
                        user?.uboInfoStep == "ATTENTION_REQUIRED" &&
                        "text-herror-700px-2 rounded-[16px] border border-herror-200 bg-herror-50 py-[0.9px] text-[13px] font-medium"
                      } ${
                        (user?.uboInfoStep == "ACTIVE" ||
                          user?.uboInfoStep == "SUBMITTED") &&
                        "text-hsuccess-700px-2 rounded-[16px] border border-hsuccess-200 bg-hsuccess-50 py-[0.9px] text-[13px] font-medium"
                      } ${
                        user?.uboInfoStep == "REVIEWING" &&
                        "text-hbrand-700px-2 rounded-[16px] border border-hbrand-200 bg-hbrand-50 py-[0.9px] text-[13px] font-medium"
                      }`}
                    >
                      {user?.uboInfoStep == "ATTENTION_REQUIRED"
                        ? "Requiring attention"
                        : user?.uboInfoStep == "REVIEWING"
                          ? "In review"
                          : user?.uboInfoStep == "ACTIVE"
                            ? "Done"
                            : user?.uboInfoStep == "SUBMITTED"
                              ? "Submitted"
                              : ""}
                    </span>
                  </div>
                  <div className="check-btn">
                    <button
                      disabled={((user?.kycStatusStep || 0) as number) < 2}
                      onClick={() =>
                        handleNavigate(
                          `/get-started/onboarding-checklist/complete-ubo-information`
                        )
                      }
                      type="button"
                      className={`${
                        (((user?.kycStatusStep || 0) as number) == 2 &&
                          !(user?.uboInfoStep == "ATTENTION_REQUIRED") &&
                          user?.uboInfoStep == "SUBMITTED") ||
                        user?.uboInfoStep == "REVIEWING" ||
                        user?.uboInfoStep == "ACTIVE"
                          ? "border-0 text-base font-semibold text-gray-600"
                          : "rounded-lg border border-hbrand-600 bg-hbrand-600 text-white shadow-s-light hover:bg-hbrand-700"
                      }  px-9 py-2 text-[13px] font-medium`}
                    >
                      {(((user?.kycStatusStep || 0) as number) > 2 &&
                        !(user?.uboInfoStep == "ATTENTION_REQUIRED") &&
                        user?.uboInfoStep == "SUBMITTED") ||
                      user?.uboInfoStep == "REVIEWING" ||
                      user?.uboInfoStep == "ACTIVE"
                        ? "View"
                        : user?.uboInfoStep == "ATTENTION_REQUIRED"
                          ? "Resolve"
                          : "Start"}
                    </button>
                  </div>
                </div>
              )}

              {(user?.acountType === "INDIVIDUAL" || !!isUbo) && (
                <div className="flex w-full items-center justify-between border-t border-hgray-200 pb-[1.15rem] pt-[1.12rem]">
                  <div className="flex w-full items-center">
                    {(((user?.kycStatusStep || 0) as number) > 2 && !isUbo) ||
                    (((user?.kycStatusStep || 0) as number) > 3 && !!isUbo) ? (
                      <span
                        dangerouslySetInnerHTML={{ __html: CircleCheckSVG }}
                      />
                    ) : (
                      <span
                        dangerouslySetInnerHTML={{ __html: CircleUncheckedSVG }}
                      />
                    )}{" "}
                    <p className="mx-3.5 text-[15.5px] font-medium text-htext-main">
                      Verify your Identity
                    </p>
                    <span
                      className={`${
                        user?.verifyIdentityStep == "ATTENTION_REQUIRED" &&
                        "text-herror-700 px-2 rounded-[16px] border border-herror-200 bg-herror-50 py-[0.9px] text-[13px] font-medium"
                      } ${
                        (user?.verifyIdentityStep == "ACTIVE" ||
                          user?.verifyIdentityStep == "SUBMITTED") &&
                        "text-hsuccess-700 px-2 rounded-[16px] border border-hsuccess-200 bg-hsuccess-50 py-[0.9px] text-[13px] font-medium"
                      } ${
                        user?.verifyIdentityStep == "REVIEWING" &&
                        "text-hbrand-700 px-2 rounded-[16px] border border-hbrand-200 bg-hbrand-50 py-[0.9px] text-[13px] font-medium"
                      }`}
                    >
                      {user?.verifyIdentityStep == "ATTENTION_REQUIRED"
                        ? "Requiring attention"
                        : user?.verifyIdentityStep == "REVIEWING"
                          ? "In review"
                          : user?.verifyIdentityStep == "ACTIVE"
                            ? "Done"
                            : user?.verifyIdentityStep == "SUBMITTED"
                              ? "Submitted"
                              : ""}
                    </span>
                  </div>
                  <div className="check-btn">
                    <button
                      disabled={((user?.kycStatusStep || 0) as number) < 2}
                      onClick={() =>
                        ["2", "3"].includes(user?.kycStatusStep as string) &&
                        (user?.verifyIdentityStep == "ATTENTION_REQUIRED" ||
                          user?.verifyIdentityStep == "CREATED")
                          ? openModal()
                          : navigate(
                              `/get-started/onboarding-checklist/verify-identity`
                            )
                      }
                      className={`${
                        (((user?.kycStatusStep || 0) as number) == 2 &&
                          !(user?.verifyIdentityStep == "ATTENTION_REQUIRED") &&
                          user?.verifyIdentityStep == "SUBMITTED") ||
                        user?.verifyIdentityStep == "REVIEWING" ||
                        user?.verifyIdentityStep == "ACTIVE"
                          ? "border-0 text-[14px] font-semibold text-gray-600"
                          : ((user?.kycStatusStep || 0) as number) < 2
                            ? "cursor-not-allowed border-hgray-200 bg-hgray-100 text-hgray-400"
                            : "rounded-lg border border-hbrand-600 bg-hbrand-600 text-white shadow-s-light hover:bg-hbrand-700"
                      }  px-9 py-2 text-[13px] font-medium`}
                    >
                      {(((user?.kycStatusStep || 0) as number) > 2 &&
                        !(user?.verifyIdentityStep == "ATTENTION_REQUIRED") &&
                        user?.verifyIdentityStep == "SUBMITTED") ||
                      user?.verifyIdentityStep == "REVIEWING" ||
                      user?.verifyIdentityStep == "ACTIVE" ? (
                        "View"
                      ) : user?.verifyIdentityStep == "ATTENTION_REQUIRED" ? (
                        "Resolve"
                      ) : isTriggeringOtp ? (
                        <span className="loading loading-spinner loading-sm"></span>
                      ) : (
                        "Start"
                      )}
                    </button>
                  </div>
                </div>
              )}

              {/*                 
                  <div className="flex w-full items-center justify-between border-t border-hgray-200 pb-[1.15rem] pt-[1.12rem]">
                    <div className="flex w-full items-center">
                      {((user?.kycStatusStep || 0) as number) == 4 ? (
                        <span
                          dangerouslySetInnerHTML={{ __html: CircleCheckSVG }}
                        />
                      ) : (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: CircleUncheckedSVG,
                          }}
                        />
                      )}{" "}
                      <p className="mx-3.5 text-[15.5px] font-medium text-htext-main">
                        {user.setupWalletStep == "ACTIVE"
                          ? "Your wallet and bank accounts"
                          : "Setup wallet & bank account"}
                      </p>
                      <span
                        className={`${
                          user?.setupWalletStep == "ATTENTION_REQUIRED" &&
                          "rounded-[16px] border border-herror-200 bg-herror-50 px-2 py-[0.9px] text-[13px] font-medium text-herror-700"
                        } ${
                          (user?.setupWalletStep == "ACTIVE" ||
                            user?.setupWalletStep == "SUBMITTED") &&
                          "rounded-[16px] border border-hsuccess-200 bg-hsuccess-50 px-2 py-[0.9px] text-[13px] font-medium text-hsuccess-700"
                        } ${
                          user?.setupWalletStep == "REVIEWING" &&
                          "rounded-[16px] border border-hbrand-200 bg-hbrand-50 px-2 py-[0.9px] text-[13px] font-medium text-hbrand-700"
                        }`}
                      >
                        {user?.setupWalletStep == "ATTENTION_REQUIRED"
                          ? "Requiring attention"
                          : user?.setupWalletStep == "REVIEWING"
                            ? "In review"
                            : user?.setupWalletStep == "ACTIVE"
                              ? "Done"
                              : user?.setupWalletStep == "SUBMITTED"
                                ? "Submitted"
                                : ""}
                      </span>
                    </div>
                    <div className="check-btn">
                      <button
                        disabled={((user?.kycStatusStep || 0) as number) < 3}
                        onClick={() =>
                          handleNavigate(
                            user.setupWalletStep == "ACTIVE"
                              ? "/get-started/onboarding-checklist/wallets-setup/confirm-details"
                              : `/get-started/onboarding-checklist/wallets-setup/how-it-works`
                          )
                        }
                        type="button"
                        className={`${
                          (((user?.kycStatusStep || 0) as number) == 3 &&
                            !(user?.setupWalletStep == "ATTENTION_REQUIRED") &&
                            user?.setupWalletStep == "SUBMITTED") ||
                          user?.setupWalletStep == "REVIEWING" ||
                          user?.setupWalletStep == "ACTIVE"
                            ? "border-0 text-[14px] font-semibold text-gray-600"
                            : ((user?.kycStatusStep || 0) as number) < 3
                              ? "cursor-not-allowed border-hgray-200 bg-hgray-100 text-hgray-400"
                              : "rounded-lg border border-hbrand-600 bg-hbrand-600 text-white shadow-s-light hover:bg-hbrand-700"
                        }  px-9 py-2 text-[13px] font-medium`}
                      >
                        {(((user?.kycStatusStep || 0) as number) == 4 &&
                          !(user?.setupWalletStep == "ATTENTION_REQUIRED") &&
                          user?.setupWalletStep == "SUBMITTED") ||
                        user?.setupWalletStep == "REVIEWING" ||
                        user?.setupWalletStep == "ACTIVE"
                          ? "View"
                          : user?.setupWalletStep == "ATTENTION_REQUIRED"
                            ? "Resolve"
                            : "Start"}
                      </button>
                    </div>
                  </div> */}
            </div>
          )}
        </div>
        {/* <OtpModal isOpen={isModalOpen} onClose={closeModal} /> */}
      </div>
      {showPopup &&
        user?.shigaKycStatus === "ACTIVE" &&
        !user.isAccountActivated && (
          <div className="absolute z-50">
            <Popup
              className="confetti bg-hgray-900/[0.8]"
              popClass="px-[1.5rem]  pb-[1.5rem] w-[40.5rem] -top-[2rem]"
            >
              <div className="popup-content-bg flex flex-col items-center">
                <div
                  className="mt-1 cursor-pointer self-end"
                  onClick={() => closeShowPopup()}
                >
                  <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: closeBtnSVG }}
                  />
                </div>
                <img
                  className="w-[220px]"
                  src="/assets/kycActive.png"
                  alt="tree illustration"
                />
                <h5 className="my-3 text-[26px] font-semibold text-htext-main">
                  Welldone!
                </h5>
                <p className="w-[350px] text-center text-[15px] font-normal text-htext-subdued">
                  Your account is now activated to swap in and out of stablecoin
                  from fiat currencies (EUR, GBP, USD)
                </p>

                <div className="mt-9 w-full border-t border-hgray-200 pt-8">
                  <button
                    type="button"
                    onClick={() => activateAccount({})}
                    className="w-full rounded-lg border border-solid border-hbrand-600 bg-hbrand-600 p-2.5 text-[14px] font-medium text-white shadow-s-light hover:border-hbrand-700 hover:bg-hbrand-700"
                  >
                    {isActivatingAccount ? "Loading..." : "Go to dashboard"}
                  </button>
                </div>
              </div>
            </Popup>
          </div>
        )}
    </>
  );
};

export default DashGetStarted;
