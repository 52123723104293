import { zodResolver } from "@hookform/resolvers/zod";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as z from "zod";
import Drawer from "../../../components/Common/drawer";
import PageContainer from "../../../components/Common/pageContainer";
import TransactionInfo from "../../../components/Common/transactionInfo";
import { trpc } from "../../../lib/trpc";
import { formatDate, formatToTwoDecimalPlaces } from "../../../utils";
import FileUpload from "../../Common/inputs/fileUpload";
import Select from "../../Common/inputs/select";
import {
  bigBlueArrow,
  BlueThinArrowSVG,
  dotSVG,
  eurosSmallSVG,
  fasterPaymentSVG,
  poundsSmallSVG,
  RightDropArrowSVG,
  startedPlay,
  uSDCSVG,
  UsdtSvg,
} from "../../Common/svg";
import Tooltip from "../../Common/tooltip";

type UserData = {
  userId?: number | null;
  corporateId: number | null;
  accountType?: string | null;
};

const contactUsSchema = z.object({
  issue: z.string().min(1, "Issue type is required"),
  message: z.string().min(1, "Description type is required"),
  uploadImage: z.string(),
});

export type ContactUsSchema = z.infer<typeof contactUsSchema>;

const DashHome: FC = () => {
  const navigate = useNavigate();
  const stableDropdownRef = useRef<HTMLDivElement>(null);
  const fiatDropdownRef = useRef<HTMLDivElement>(null);

  const stableCoinOptions = ["USDT", "USDC"];
  const fiatOptions = ["USD", "EUR", "GBP"];

  const sof = [
    "Transaction issue",
    "Product inquiry",
    "Complaints and feedback",
  ];

  const [userAccounts, setUserAccounts] = useState({});
  const [userWallets, setUserWallets] = useState({});

  const [showDropDownFiat, setShowDropDownFiat] = useState(false);
  const [showDropDownStable, setShowDropDownStable] = useState(false);
  const [currentPage] = useState(1);
  const [selectedTransactionId, setSelectedTransactionId] = useState<
    string | null
  >(null);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [showTransactionPopup, setShowTransactionPopup] = useState(false);
  const [showContactusPopup, setShowContactusPopup] = useState(false);
  const [calRate, setCalRate] = useState("0");
  const [calBase, setCalBase] = useState(`${stableCoinOptions[0]}`);
  const [calTarget, setCalTarget] = useState(`${fiatOptions[0]}`);
  const [calculatedValue, setCalculatedValue] = useState("");
  const [amount, setAmount] = useState("");
  const { data: userProfile } = trpc.user.getAccountType.useQuery({});

  const [isSwapped, setIsSwapped] = useState(false);

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  function closeShowPopup() {
    setShowTransactionPopup(false);
    setShowContactusPopup(false);
    setSelectedTransactionId(null);
  }

  function contactUs() {
    if (showTransactionPopup) {
      setShowTransactionPopup(false);
    }
    setShowContactusPopup(true);
  }

  const isStableCoin = (str: string) => stableCoinOptions.includes(str);

  const { data: tr } = trpc.user.getCalculatedRate.useQuery({
    base: isStableCoin(calBase) ? "USD" : calBase,
    target: isStableCoin(calTarget) ? "USD" : calTarget,
  });

  useEffect(() => {
    if (amount === "" || isNaN(parseFloat(amount))) {
      setCalculatedValue("0");
    } else if (tr) {
      const rate = tr.exchange_rate; // Adjust based on the actual response structure
      const roundedUpRates = rate.toFixed(2);
      setCalRate(roundedUpRates);
      const targetValue = (parseFloat(amount) * rate).toFixed(2);
      setCalculatedValue(targetValue);
    }
  }, [tr, amount]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setAmount(value);
    }
  };

  const handleSwap = () => {
    setIsSwapped((prev) => !prev);
    setCalBase((prevCalBase) => {
      setCalTarget(prevCalBase);
      return calTarget;
    });
  };

  // Handle clicks outside of Stablecoin dropdown
  useEffect(() => {
    const handleClickOutsideStable = (event: any) => {
      if (
        stableDropdownRef.current &&
        !stableDropdownRef?.current?.contains(event.target)
      ) {
        setShowDropDownStable(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideStable);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideStable);
    };
  }, []);

  // Handle clicks outside of Fiat dropdown
  useEffect(() => {
    const handleClickOutsideFiat = (event: any) => {
      if (
        fiatDropdownRef.current &&
        !fiatDropdownRef.current.contains(event.target)
      ) {
        setShowDropDownFiat(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideFiat);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideFiat);
    };
  }, []);

  const isEmpty = (data: any) => Array.isArray(data) && data.length === 0;

  const {
    data: userTransactions,
    isLoading,
    isFetching,
  } = trpc.transaction.transaction.useQuery(
    {
      type: userProfile?.acountType === "INDIVIDUAL" ? "user" : "company",
      page: currentPage,
      limit: 5,
      id:
        userProfile?.acountType === "INDIVIDUAL"
          ? (userData?.userId as number)
          : (userData?.corporateId as number),
    },
    {
      enabled: !!userData?.corporateId || !!userData?.userId,
    }
  );

  function handleShowTransactionPopup(show: boolean, id?: string) {
    setSelectedTransactionId(id ?? null); // Store the I D
    setShowTransactionPopup(show);
  }

  // Fetch transaction detail if selectedTransactionId is not null
  const { data: userTrDetail, isLoading: popupLoading } =
    trpc.transaction.transactionDetail.useQuery(
      {
        customerId:
          userProfile?.acountType === "INDIVIDUAL"
            ? (userData?.userId as number) // userId or corporateId
            : (userData?.corporateId as number),
        id: Number(selectedTransactionId) || 0, // Use 0 if no ID is selected
        type: userProfile?.acountType === "INDIVIDUAL" ? "user" : "company",
      },
      {
        enabled:
          !!selectedTransactionId &&
          (!!userData?.corporateId || !!userData?.userId),
      }
    );

  const imageSrc = (details: any) => {
    const direction = details?.direction;
    const currencyPair = `${(direction === "cryptoToFiat"
      ? (details?.currencyCrypto as string)
      : (details?.currencyFiat as string)
    )?.toUpperCase()} to ${(direction === "cryptoToFiat"
      ? (details?.currencyFiat as string)
      : (details?.currencyCrypto as string)
    )?.toUpperCase()}`;

    switch (currencyPair) {
      case "USDT to GBP":
        return "/assets/USDT-to-GBP.svg";
      case "USDT to EUR":
        return "/assets/USDT-to-EUR.svg";
      case "USDC to EUR":
        return "/assets/USDC-to-EUR.svg";
      case "USDC to GBP":
        return "/assets/USDC-to-GBP.svg";
      case "EUR to USDT":
        return "/assets/EUR-to-USDT.svg";
      case "GBP to USDT":
        return "/assets/GBP-to-USDT.svg";
      case "EUR to USDC":
        return "/assets/euro-to-USDC.svg";
      case "GBP to USDC":
        return "/assets/GBP-to-USDC.svg";
      case "EUR to GBP":
        return "/assets/EUR-to-GBP.svg";
      case "GBP to EUR":
        return "/assets/GBP-to-EUR.svg";
      default:
        return "/assets/coin.svg";
    }
  };

  useEffect(() => {
    if (userProfile) {
      setUserData(userProfile);
    }
  }, [userProfile]);

  const { mutate: sendContactUsMsg, isLoading: isSendingMsg } =
    trpc.user.customerContactUs.useMutation({
      onSuccess: () => {
        setShowContactusPopup(false);
        toast.success("Message sent successfully");
      },

      onError: () => {
        toast.error("Error sending message");
      },
    });

  const onSave = (data: ContactUsSchema) => {
    sendContactUsMsg(data);
  };

  const methods = useForm({
    resolver: zodResolver(contactUsSchema),
    defaultValues: {
      issue: "",
      message: "",
      uploadImage: "",
    },
  });

  const {
    register: registerIssue,
    handleSubmit: handleIssueSubmit,
    formState: { errors },
  } = methods;

  // Determine options for dropdowns based on swap state
  const fromOptions = isSwapped ? fiatOptions : stableCoinOptions;
  const toOptions = isSwapped ? stableCoinOptions : fiatOptions;

  const { data: dataAccount } = trpc.user.getBankAccount.useQuery({});
  const { data: dataWallet } = trpc.user.getWallet.useQuery({});

  useEffect(() => {
    if (dataAccount) {
      setUserAccounts(dataAccount);
    }

    if (dataWallet) {
      setUserWallets(dataWallet);
    }
  }, [dataAccount, dataWallet]);

  return (
    <PageContainer>
      <div className="dashHome-content m-auto flex h-full w-full flex-col justify-center">
        <div className="content-top flex items-center gap-x-7">
          <div className="quick-action w-[65%] rounded-2xl border border-solid border-hgray-200 bg-white px-6 pb-6 pt-6 text-htext-main">
            <h5 className="text-[16.5px] font-semibold ">Quick actions</h5>

            <div className="mt-6 flex h-full w-full items-center justify-between gap-x-[20px]">
              <div className="flex w-[50%] flex-col items-center rounded-2xl bg-quick-fiat p-6">
                <p className="text-[13px] font-semibold">
                  Swap fiat to stablecoin
                </p>
                <div className="actions-currencies my-4 flex items-center">
                  <div className="flex items-center rounded-[21rem] border border-solid border-hgray-300 px-2 py-1">
                    <img
                      className="w-[22px]"
                      src="/assets/euroSmall.svg"
                      alt="euros"
                    />
                    <img
                      className="mx-1 w-[22px]"
                      src="/assets/poundsSmall.svg"
                      alt="pounds"
                    />
                    <img
                      className="w-[22px]"
                      src="/assets/dollarSmall.svg"
                      alt="dollar"
                    />
                  </div>

                  <span
                    className="mx-1.5"
                    dangerouslySetInnerHTML={{ __html: bigBlueArrow }}
                  />

                  <div className="flex items-center rounded-[21rem] border border-solid border-hgray-300 px-2 py-1">
                    <img
                      className="mr-0.5 w-[22px]"
                      src="/assets/usdcSmall.svg"
                      alt="usdc"
                    />
                    <img
                      className="ml-0.5 w-[22px]"
                      src="/assets/usdtSmall.svg"
                      alt="usdt"
                    />
                  </div>
                </div>

                <div className="actions-button mt-1.5 w-full">
                  <button
                    type="button"
                    className="flex w-full items-center justify-center rounded-lg border border-solid border-hgray-300 bg-white px-3.5 py-2 text-hgray-700 hover:bg-quick-stable"
                    onClick={() =>
                      handleNavigate(
                        `${
                          userProfile?.setupBankStep !== "ACTIVE"
                            ? "/dashboard/portal-accounts/portal-into-stablecoin"
                            : "/dashboard/portal-accounts/portal-in-details"
                        }`
                      )
                    }
                  >
                    <p className="text-[13px] font-semibold">
                      {userProfile?.setupBankStep !== "ACTIVE"
                        ? "Get started"
                        : "View details"}
                    </p>
                    {!userWallets ? (
                      <span
                        className="mx-1.5"
                        dangerouslySetInnerHTML={{ __html: startedPlay }}
                      />
                    ) : null}
                  </button>
                </div>
              </div>

              <div className="flex w-[50%] flex-col items-center rounded-2xl bg-quick-stable p-6">
                <p className="text-[13px] font-semibold">
                  Swap stablecoin to fiat
                </p>
                <div className="actions-currencies my-4 flex items-center">
                  <div className="flex items-center rounded-[21rem] border border-solid border-hgray-300 px-2 py-1">
                    <img
                      className="mr-0.5 w-[22px]"
                      src="/assets/usdcSmall.svg"
                      alt="usdc"
                    />
                    <img
                      className="ml-0.5 w-[22px]"
                      src="/assets/usdtSmall.svg"
                      alt="usdt"
                    />
                  </div>

                  <span
                    className="mx-1.5"
                    dangerouslySetInnerHTML={{ __html: bigBlueArrow }}
                  />

                  <div className="flex items-center rounded-[21rem] border border-solid border-hgray-300 px-2 py-1">
                    <img
                      className="w-[22px]"
                      src="/assets/euroSmall.svg"
                      alt="euros"
                    />
                    <img
                      className="mx-1 w-[22px]"
                      src="/assets/poundsSmall.svg"
                      alt="pounds"
                    />
                    <img
                      className="w-[22px]"
                      src="/assets/dollarSmall.svg"
                      alt="dollar"
                    />
                  </div>
                </div>

                <div className="actions-button mt-1.5 w-full">
                  <button
                    type="button"
                    className="flex w-full items-center justify-center rounded-lg border border-solid border-hgray-300 bg-white px-3.5 py-2 text-hgray-700 hover:bg-quick-fiat"
                    onClick={() =>
                      handleNavigate(
                        `${
                          userProfile?.setupWalletStep !== "ACTIVE"
                          ? "/dashboard/portal-accounts/portal-out-to-fiat"
                            : "/dashboard/portal-accounts/portal-out-details"
                        }`
                      )
                    }
                  >
                    <p className="text-[13px] font-semibold">
                      {userProfile?.setupWalletStep !== "ACTIVE"
                        ? "Get started"
                        : "View details"}
                    </p>
                    {!userAccounts ? (
                      <span
                        className="mx-1.5"
                        dangerouslySetInnerHTML={{ __html: startedPlay }}
                      />
                    ) : null}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`quick-action w-[35%] rounded-2xl border border-solid border-hgray-200 bg-white px-5 ${
              calRate !== "0" && amount !== "" ? "pb-2.5 pt-5" : "pb-6 pt-6"
            } text-htext-main`}
          >
            <h5 className="text-[16.5px] font-semibold ">Rate calculator</h5>

            <div
              className={`actions-content relative ${
                calRate !== "0" && amount !== "" ? "mt-4" : "mt-6"
              } flex h-full w-full flex-col items-center gap-y-2`}
            >
              {/* From Section */}
              <div className="flex w-full items-center justify-between rounded-[9px] bg-hgray-50 px-4 py-3.5">
                <div className="w-[160px]">
                  <p className="text-left text-[13px] font-medium text-htext-main">
                    From
                  </p>
                  <input
                    type="text"
                    className="mt-2.5 bg-transparent text-left text-[18px] font-normal text-htext-placeholder outline-none"
                    placeholder={
                      isSwapped
                        ? `${
                            calBase === "EUR"
                              ? "€"
                              : calBase === "GBP"
                                ? "£"
                                : "$"
                          }0`
                        : "0"
                    }
                    value={amount}
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <p className="text-right text-[11px] font-normal text-htext-subdued">
                    {isSwapped ? "Fiat" : "Stablecoin"}
                  </p>
                  <div
                    className="mt-2.5 cursor-pointer rounded-[500px] border-0 bg-hgray-200 py-1 pl-2 pr-2.5 text-right text-[13px] font-medium text-htext-label outline-none hover:bg-hgray-300"
                    onClick={() => setShowDropDownStable(!showDropDownStable)}
                    ref={stableDropdownRef}
                  >
                    <div className="flex items-center">
                      <img
                        className="w-[14px]"
                        src={`/assets/${
                          isSwapped
                            ? calBase === "USD"
                              ? "dollarSmall"
                              : calBase === "EUR"
                                ? "euroSmall"
                                : "poundsSmall"
                            : calBase === "USDC"
                              ? "usdcSmall"
                              : "usdtSmall"
                        }.svg`}
                        alt={calBase}
                      />
                      {/* {console.log("calBase", calBase)} */}
                      <p className="ml-1 mr-2.5">{calBase}</p>
                      <span
                        className="cursor-pointer"
                        dangerouslySetInnerHTML={{
                          __html: BlueThinArrowSVG || "",
                        }}
                      />
                    </div>
                    {showDropDownStable && (
                      <ul className="absolute z-30 -ml-10 mt-3 w-[120px] rounded-lg border border-solid border-hgray-200 bg-hgray-100 py-3">
                        {fromOptions.map((option) => (
                          <li
                            key={option}
                            className="cursor-pointer px-4 py-1 text-left text-[13px] hover:bg-hgray-300"
                            onClick={() => {
                              setCalBase(option);
                              setShowDropDownStable(false);
                            }}
                          >
                            <p>{option}</p>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>

              {/* Swap Button */}
              <div
                className="swap-div absolute mt-[4.45rem] cursor-pointer hover:rounded-full hover:border"
                onClick={() => handleSwap()}
              >
                <img src="/rates-sort.svg" alt="swap icon" />
              </div>

              {/* To Section */}
              <div className="to flex- flex w-full items-center justify-between rounded-[9px] bg-hgray-50 px-4 py-3.5">
                <div className="w-[160px]">
                  <p className="text-left text-[13px] font-medium text-htext-main">
                    To
                  </p>
                  <input
                    type="text"
                    className="mt-2.5 bg-transparent text-left text-[18px] font-normal text-htext-placeholder outline-none"
                    placeholder={`${
                      calTarget === "EUR"
                        ? "€"
                        : calTarget === "GBP"
                          ? "£"
                          : calTarget === "USD"
                            ? "$"
                            : "0"
                    }`}
                    readOnly
                    value={calculatedValue}
                  />
                </div>
                <div>
                  <p className="text-right text-[11px] font-normal text-htext-subdued">
                    {isSwapped ? "Stablecoin" : "Fiat"}
                  </p>
                  <div
                    className="mt-2.5 cursor-pointer rounded-[500px] border-0 bg-hgray-200 py-1 pl-2 pr-2.5 text-right text-[13px] font-medium text-htext-label outline-none hover:bg-hgray-300"
                    onClick={() => setShowDropDownFiat(!showDropDownFiat)}
                    ref={fiatDropdownRef}
                  >
                    <div className="flex items-center">
                      <img
                        className="w-[14px]"
                        src={`/assets/${
                          isSwapped
                            ? calTarget === "USDC"
                              ? "usdcSmall"
                              : "usdtSmall"
                            : calTarget === "USD"
                              ? "dollarSmall"
                              : calTarget === "EUR"
                                ? "euroSmall"
                                : "poundsSmall"
                        }.svg`}
                        alt={calTarget}
                      />
                      <p className="ml-1 mr-2.5">{calTarget}</p>
                      <span
                        className="cursor-pointer"
                        dangerouslySetInnerHTML={{
                          __html: BlueThinArrowSVG || "",
                        }}
                      />
                    </div>
                    {showDropDownFiat && (
                      <ul className="absolute z-50 -ml-10 mt-3 w-[120px] rounded-lg border border-solid border-hgray-200 bg-hgray-100 py-3">
                        {toOptions.map((option) => (
                          <li
                            key={option}
                            className="cursor-pointer px-4 py-1 text-left text-[13px] hover:bg-hgray-300"
                            onClick={() => {
                              setCalTarget(option);
                              setShowDropDownFiat(false);
                            }}
                          >
                            <p>{option}</p>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {calRate !== "0" && amount !== "" && (
              <p className="mt-1 text-right text-[13px] font-semibold">
                Rate: {calRate}
              </p>
            )}
          </div>
        </div>
        {/* {userProfile?.setupWalletStep === "ACTIVE" &&
        userProfile?.shigaKycStatus === "ACTIVE" ? (
          <div className="mt-2">
              <div>
                Click to download your wallet statement or bank statement
              </div>
            <div className="flex flex-col mt-2 ">
                <DownloadButton />
                <DownloadButtonB />
            </div>
          </div>
        ) : null} */}

        <div className="content-bottom mt-7 w-full rounded-2xl border border-solid border-hgray-200 bg-white px-6 pb-6 pt-6 text-htext-main">
          <div className="recents-nav flex items-start justify-between">
            <h5 className="text-[16.5px] font-semibold ">
              Recent transactions
            </h5>
            {!isEmpty(userTransactions?.data.data) && (
              <p
                className="cursor-pointer text-[13px] font-semibold text-hbrand-700 hover:text-hbrand-600"
                onClick={() => handleNavigate("/dashboard/transactions")}
              >
                View all
              </p>
            )}
          </div>
          {isLoading || isFetching ? (
            <div className="absolute inset-0 z-10 flex items-center justify-center bg-white bg-opacity-50">
              <div className="loader"></div> {/* Add your loader here */}
            </div>
          ) : null}

          {isEmpty(userTransactions?.data.data) ? (
            <div className="flex flex-col items-center py-6">
              <img src="/recent-t.svg" alt="empty recent transactions" />
              <h5 className="mt-4 text-center text-[13.5px] font-semibold">
                No recent transaction history
              </h5>
              <p className="mt-1 w-[250px] text-center text-[11px] font-normal text-htext-subdued">
                Once you start making payments, you can keep track of your
                transactions here
              </p>
            </div>
          ) : (
            <div className="hasTransact flex flex-col pt-3">
              {userTransactions?.data.data.map((transData: any, index) => (
                <div
                  key={index}
                  className="hasTransact-content flex w-full items-center justify-between border-b border-b-hgray-200 py-3 text-htext-main"
                >
                  <div className="content-left flex items-center">
                    <img
                      className="w-[34.5px]"
                      src={imageSrc(transData)}
                      alt="Conversion icons ticket"
                    />
                    <div className="left-text ml-2.5">
                      <h5 className="text-[13px] font-medium">
                        {transData?.currencyCrypto && transData?.currencyFiat
                          ? transData?.direction === "cryptoToFiat"
                            ? `${transData?.currencyCrypto?.toUpperCase()} to ${transData?.currencyFiat?.toUpperCase()}`
                            : `${transData?.currencyFiat?.toUpperCase()} to ${transData?.currencyCrypto?.toUpperCase()}`
                          : "Processing"}
                      </h5>
                      <p className="flex items-center text-[11px] font-normal text-htext-subdued">
                        {formatDate(transData?.createdAt || "")
                          .split(" ")
                          .slice(0, -2)
                          .join(" ")}
                        <span
                          className="mx-1"
                          dangerouslySetInnerHTML={{ __html: dotSVG }}
                        />{" "}
                        {formatDate(transData?.createdAt || "")
                          .split(" ")
                          .slice(-2)
                          .join(" ")}
                      </p>
                    </div>
                  </div>

                  <div className="content-right flex w-[180px] items-center justify-between">
                    <div className="right-text">
                      <h5 className="text-[13px] font-medium">
                        {" "}
                        {transData?.direction === "cryptoToFiat"
                          ? `${transData?.amountCrypto} ${(
                              transData?.currencyCrypto || ""
                            )?.toUpperCase()}`
                          : `${transData?.amountFiat} ${(
                              transData?.currencyFiat || ""
                            )?.toUpperCase()}`}
                      </h5>
                      <p className="text-[11px] font-normal text-htext-subdued">
                        {transData?.currencyCrypto && transData?.currencyFiat
                          ? transData?.direction === "cryptoToFiat"
                            ? `swapped to ${transData?.amountFiat} ${(
                                transData?.currencyFiat || ""
                              )?.toUpperCase()}`
                            : `swapped to ${transData?.amountCrypto} ${(
                                transData?.currencyCrypto || ""
                              )?.toUpperCase()}`
                          : "Processing"}
                      </p>
                    </div>

                    <span
                      className="cursor-pointer"
                      onClick={() =>
                        handleShowTransactionPopup(true, transData?.id)
                      }
                      dangerouslySetInnerHTML={{ __html: RightDropArrowSVG }}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <Drawer
        openDrawerBox={showTransactionPopup}
        onClose={closeShowPopup}
        header="Transaction Details"
        action={
          <div className="flex items-center justify-between">
            <p className="text-[13px] font-normal text-htext-subdued">
              Issues with this transaction?{" "}
              <b
                className="cursor-pointer font-semibold text-hbrand-700 hover:text-hbrand-600"
                onClick={() => contactUs()}
              >
                Contact us
              </b>
            </p>
            <button
              type="button"
              onClick={() => closeShowPopup()}
              className="rounded-lg border border-hbrand-600 bg-hbrand-600 px-24 py-2.5 text-[15px] font-semibold text-white shadow-s-light hover:border-hbrand-700 hover:bg-hbrand-700"
            >
              Close
            </button>
          </div>
        }
      >
        <div className="content-bg-wrapper w-full">
          {popupLoading ? (
            <div className="loader-wrapper">
              <div className="loader"></div> {/* Gray ring loader */}
            </div>
          ) : (
            <div className="content-content mb-8 w-full">
              <div className="content-top flex items-center justify-between">
                <div className="top-left flex items-center">
                  <img
                    className="w-[39px]"
                    src={imageSrc(userTrDetail)}
                    alt="Conversion currency ticket"
                  />
                  <div className="left-text ml-3">
                    <h4 className="text-[20px] font-semibold">
                      {userTrDetail?.direction === "cryptoToFiat"
                        ? `${userTrDetail?.amountCrypto} ${(
                            userTrDetail?.currencyCrypto || ""
                          )?.toUpperCase()}`
                        : `${userTrDetail?.amountFiat} ${(
                            userTrDetail?.currencyFiat || ""
                          )?.toUpperCase()}`}
                    </h4>
                    <p className="text-[13.5px] font-normal text-htext-subdued">
                      {userTrDetail?.currencyCrypto &&
                      userTrDetail?.currencyFiat
                        ? userTrDetail?.direction === "cryptoToFiat"
                          ? `swapped to ${userTrDetail?.amountFiat} ${(
                              userTrDetail?.currencyFiat || ""
                            )?.toUpperCase()}`
                          : `swapped to ${userTrDetail?.amountCrypto} ${(
                              userTrDetail?.currencyCrypto || ""
                            )?.toUpperCase()}`
                        : ""}
                    </p>
                  </div>
                </div>

                <div className="top-right text-right">
                  <p className="text-[13px] font-medium">
                    {formatDate(userTrDetail?.createdAt || "")
                      .split(" ")
                      .slice(0, -2)
                      .join(" ")}
                  </p>
                  <p className="text-[13px] font-normal text-htext-subdued">
                    {formatDate(userTrDetail?.createdAt || "")
                      .split(" ")
                      .slice(-2)
                      .join(" ")}
                  </p>
                </div>
              </div>

              <div className="content-middle-upper mt-10 flex w-full flex-col">
                <div className="middle-content flex items-center justify-between border-b border-b-hgray-200 py-4">
                  <p className="text-[14.5px] font-normal text-htext-subdued">
                    Status
                  </p>
                  <div className="rounded-2xl border border-hsuccess-200 bg-hsuccess-50 px-2">
                    <p className="text-[12.5px] font-medium text-hsuccess-700">
                      {userTrDetail?.status === "SUCCESS"
                        ? "SUCCESSFUL"
                        : userTrDetail?.status?.replace("_", " ")}
                    </p>
                  </div>
                </div>

                <div className="middle-content flex items-center justify-between border-b border-b-hgray-200 py-4">
                  <p className="text-[14.5px] font-normal text-htext-subdued">
                    Transaction type
                  </p>
                  <div className="flex items-center">
                    <p className="text-[13.5px] font-medium">
                      {userTrDetail?.direction === "cryptoToFiat"
                        ? "Portal out of stablecoin"
                        : "Portal in to stablecoin"}
                    </p>
                    <Tooltip
                      tipClass="portal-out-tip"
                      tipHeader={
                        userTrDetail?.direction === "cryptoToFiat"
                          ? "Portal out of stablecoin"
                          : "Portal in to stablecoin"
                      }
                      tipBody={
                        userTrDetail?.direction === "cryptoToFiat"
                          ? "Swap STABLECOIN to FIAT by using your stablecoin wallet to receive G7 currencies in your bank account"
                          : "Swap FIAT to STABLECOIN by buying stablecoin's on EVM and Solana without logging into your crypto app or swapping with someone P2P"
                      }
                    />
                  </div>
                </div>

                <div className="middle-content flex items-center justify-between border-b border-b-hgray-200 py-4">
                  <p className="text-[14.5px] font-normal text-htext-subdued">
                    Target amount
                  </p>
                  <div className="flex items-center">
                    <p className="text-[13.5px] font-medium">
                      {userTrDetail?.direction === "cryptoToFiat"
                        ? `${userTrDetail?.amountCrypto} ${(
                            userTrDetail?.currencyCrypto || ""
                          )?.toUpperCase()}`
                        : `${userTrDetail?.amountFiat} ${(
                            userTrDetail?.currencyFiat || ""
                          )?.toUpperCase()}`}
                    </p>
                    <span
                      className="ml-2"
                      dangerouslySetInnerHTML={{
                        __html:
                          userTrDetail?.direction === "cryptoToFiat" &&
                          (
                            userTrDetail?.currencyCrypto as string
                          )?.toUpperCase() === "USDT"
                            ? UsdtSvg
                            : userTrDetail?.direction === "cryptoToFiat" &&
                                (
                                  userTrDetail?.currencyCrypto as string
                                )?.toUpperCase() === "USDC"
                              ? uSDCSVG
                              : userTrDetail?.direction === "fiatToCrypto" &&
                                  (
                                    userTrDetail?.currencyFiat as string
                                  )?.toUpperCase() === "GBP"
                                ? poundsSmallSVG
                                : userTrDetail?.direction === "fiatToCrypto" &&
                                    (
                                      userTrDetail?.currencyFiat as string
                                    )?.toUpperCase() === "EUR"
                                  ? eurosSmallSVG
                                  : "",
                      }}
                    />
                  </div>
                </div>

                <div className="middle-content flex items-center justify-between border-b border-b-hgray-200 py-4">
                  <p className="text-[14.5px] font-normal text-htext-subdued">
                    Settlement amount
                  </p>
                  <div className="flex items-center">
                    <p className="text-[13.5px] font-medium">
                      {userTrDetail?.amountCrypto && userTrDetail?.amountFiat
                        ? userTrDetail?.direction === "cryptoToFiat"
                          ? `${userTrDetail?.amountFiat} ${(
                              userTrDetail?.currencyFiat || ""
                            )?.toUpperCase()}`
                          : `${userTrDetail?.amountCrypto} ${(
                              userTrDetail?.currencyCrypto || ""
                            )?.toUpperCase()}`
                        : "Processing"}
                    </p>
                    <span
                      className="ml-2"
                      dangerouslySetInnerHTML={{
                        __html:
                          userTrDetail?.direction === "cryptoToFiat" &&
                          (
                            userTrDetail?.currencyFiat as string
                          )?.toUpperCase() === "GBP"
                            ? poundsSmallSVG
                            : userTrDetail?.direction === "cryptoToFiat" &&
                                (
                                  userTrDetail?.currencyFiat as string
                                )?.toUpperCase() === "EUR"
                              ? eurosSmallSVG
                              : userTrDetail?.direction === "fiatToCrypto" &&
                                  (
                                    userTrDetail?.currencyCrypto as string
                                  )?.toUpperCase() === "USDT"
                                ? UsdtSvg
                                : userTrDetail?.direction === "fiatToCrypto" &&
                                    (
                                      userTrDetail?.currencyCrypto as string
                                    )?.toUpperCase() === "USDC"
                                  ? uSDCSVG
                                  : "",
                      }}
                    />
                  </div>
                </div>

                <div className="middle-content flex items-center justify-between border-b border-b-hgray-200 py-4">
                  <p className="text-[14.5px] font-normal text-htext-subdued">
                    Rate
                  </p>
                  <p className="text-[13.5px] font-medium">
                    {formatToTwoDecimalPlaces(
                      Number(userTrDetail?.shigaExchangeRate)
                    )}
                  </p>
                </div>

                <div className="middle-content flex items-center justify-between py-4">
                  <p className="text-[14.5px] font-normal text-htext-subdued">
                    Payment method
                  </p>
                  <div className="flex items-center">
                    <p className="text-[13.5px] font-medium">Faster payments</p>
                    <span
                      className="ml-2.5"
                      dangerouslySetInnerHTML={{
                        __html: fasterPaymentSVG,
                      }}
                    />
                  </div>
                </div>
              </div>

              <TransactionInfo
                targetCurrency={userTrDetail?.targetCurrency || ""}
                settlementCurrency={userTrDetail?.settlementCurrency || ""}
                sendingAddress={userTrDetail?.sendingAddress || ""}
                targetNetwork={userTrDetail?.targetNetwork || ""}
                walletAddress={userTrDetail?.walletAddress || ""}
                accountNumber={userTrDetail?.accountnumber || ""}
                sortCode={userTrDetail?.sortCode || ""}
                iban={userTrDetail?.iban || ""}
                direction={
                  userTrDetail?.direction as "cryptoToFiat" | "fiatToCrypto"
                }
              />
            </div>
          )}
        </div>
      </Drawer>

      <Drawer
        openDrawerBox={showContactusPopup}
        onClose={closeShowPopup}
        header="Get in touch"
        action={
          <div className="flex justify-end ">
            <button
              type="button"
              onClick={closeShowPopup}
              className="shadow-s-light] mr-[5px] rounded-lg border border-hgray-300 bg-white px-3.5 py-2.5 text-[15px] font-semibold text-hgray-700 hover:opacity-70"
            >
              Close
            </button>
            <button
              type="submit"
              onClick={handleIssueSubmit(onSave)}
              className="ml-[5px] w-[40%] rounded-lg border border-hbrand-600 bg-hbrand-600 px-12 py-2.5 text-[15px] font-semibold text-white shadow-s-light hover:opacity-80"
            >
              {isSendingMsg ? (
                <span className="loading loading-spinner loading-sm"></span>
              ) : (
                "Send"
              )}
            </button>
          </div>
        }
        subHeader="We typically respond within 24 hrs"
      >
        <FormProvider {...methods}>
          <form className="h-full w-full">
            {" "}
            <fieldset>
              <label htmlFor="idType" className="relative h-full">
                <div className="mb-1.5 flex items-center text-[13px] font-medium">
                  <p className="text-htext-label">Issue cateogry</p>
                </div>

                <Select
                  id="idType"
                  register={registerIssue("issue")}
                  inputClass={true}
                  optionsItem={sof}
                  error={errors.issue}
                />
                {errors.issue && (
                  <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                    {errors.issue.message}!
                  </p>
                )}
              </label>
              <label htmlFor="idType" className="relative  h-full w-[48.5%]">
                <div className="mb-1.5 mt-7  flex items-center text-[13px] font-medium">
                  <p className="text-htext-label">Description </p>
                </div>
                <textarea
                  className="w-full cursor-pointer rounded-md border border-gray-100 px-3 py-2 outline-none"
                  maxLength={1200}
                  rows={9}
                  placeholder="Enter answer"
                  style={{ resize: "none" }}
                  {...registerIssue("message")}
                />
                {errors.message && (
                  <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                    {errors.message.message}!
                  </p>
                )}
              </label>

              <label htmlFor="file" className="relative w-full">
                <div className="mb-1 mt-7  flex items-center text-[13px] font-medium">
                  <p className="text-htext-label">Upload attachment</p>
                </div>
                <FileUpload
                  name="uploadImage"
                  error={errors?.["uploadImage"]}
                />
                {errors?.["uploadImage"] && (
                  <p className="font-ibm-plex absolute mt-1.5 text-[13px] font-medium text-red-600">
                    {errors?.["uploadImage"]?.message as string}
                  </p>
                )}
                {errors.uploadImage && (
                  <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                    {errors.uploadImage.message}!
                  </p>
                )}
              </label>
            </fieldset>
          </form>
        </FormProvider>
      </Drawer>
    </PageContainer>
  );
};

export default DashHome;
