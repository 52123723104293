import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { trpc } from "../../../../lib/trpc";
import { usdtdcDisk } from "../../../Common/svg";
import PortalOutSetup from "./index";

const PortalOutActivateWallet: FC = () => {
  const utils = trpc.useUtils();
  const navigate = useNavigate();
  const mutation = trpc.user.confirmWalletStep.useMutation({
    onSuccess: () => {
      handleNavigate("/dashboard/portal-accounts/portal-out-wallet-acccounts");
      utils.user.getAccountType.invalidate();
    },
  });
  const handleNavigate = (route: string) => {
    navigate(route);
  };

  return (
    <PortalOutSetup currentStep={3} complete={false}>
      <div className="portal-type hide-scrollbar mt-12 h-full w-full overflow-y-scroll">
        <div className="type-content flex h-full flex-col items-center justify-between pt-3 ">
          <div className="type-top w-[750px] pb-5">
            <div className="type-top pb-10">
              <div className="relative flex items-start justify-between rounded-2xl bg-hgray-50 px-5 pb-4 pt-6 shadow-s-semi-light">
                <div className="z-10 w-[62.5%]">
                  <h3 className="w-[350px] text-[22px] font-semibold leading-[31px] text-htext-main">
                    Activate USDC & USDT portal stablecoin wallets
                  </h3>
                  <p className="mt-[5px] text-[13px] font-normal leading-[20px] text-htext-main">
                    Any stablecoin amount you send into this portal stablecoin
                    wallet is swapped into fiat currency and sent to your
                    settlement bank account.
                  </p>

                  <button
                    className="mt-7 w-[240px] rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[10px] text-[13px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
                    type="button"
                    onClick={() => mutation.mutate({})}
                  >
                    {mutation.isLoading ? (
                      <span className="loading loading-spinner loading-sm"></span>
                    ) : (
                      "Activate USDC & USDT portal wallet"
                    )}
                  </button>
                </div>

                <div className="z-10 w-[20.5%]">
                  <span
                    className="float-right -mr-[1rem] -mt-[1.5rem] block w-fit"
                    dangerouslySetInnerHTML={{ __html: usdtdcDisk }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form-btns flex h-fit w-full items-center justify-between border-t border-t-hgray-200 px-14 pb-1 pt-6">
            <button
              className="w-[150px] rounded-lg border  border-solid border-hgray-300 bg-white px-[10px] py-[13px] text-[15px] font-semibold text-hgray-700 shadow-s-light hover:bg-hgray-50"
              type="button"
              onClick={() =>
                navigate(
                  "/dashboard/portal-accounts/portal-out-settlement-information"
                )
              }
            >
              Go Back
            </button>

            <button
              className="w-[250px] rounded-lg border border-solid border-hbrand-600 bg-hbrand-600 px-[10px] py-[13px] text-[15px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
              type="button"
              onClick={() =>
                handleNavigate(
                  "/dashboard/portal-accounts/portal-out-wallet-acccounts"
                )
              }
            >
              View portal information
            </button>
          </div>
        </div>
      </div>
    </PortalOutSetup>
  );
};

export default PortalOutActivateWallet;
