import { useState } from "react";
import PageContainer from "../../../components/Common/pageContainer";
import Profile from "./profile";
import Security from "./security";
import Settlement from "./settlements";

export default function AccountSettings() {
  const [activeTab, setActiveTab] = useState("Profile");

  const tabs = ["Profile", "Settlement", "Security"];

  return (
    <PageContainer className="pt-0">
      <div className="w-full h-full border-b border-gray-200">
        <nav className="flex -mb-px space-x-8">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`px-1 py-2 font-semibold ${
                activeTab === tab
                  ? "border-b-2 border-hbrand-600 text-hbrand-600"
                  : "text-gray-500 hover:text-gray-700"
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </nav>
      </div>
      <div className="my-8 ">
        {activeTab === "Profile" && <Profile />}
        {activeTab === "Settlement" && <Settlement />}
        {activeTab === "Security" && <Security />}
      </div>
    </PageContainer>
  );
}
