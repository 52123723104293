import { zodResolver } from "@hookform/resolvers/zod";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { UserAccountStatus } from "src/route/dashboard/transactions";
import Drawer from "../../../components/Common/drawer";
import Inputs from "../../../components/Common/inputs";
import PageContainer from "../../../components/Common/pageContainer";
import { trpc } from "../../../lib/trpc";
import {
  inviteTeamSchema,
  InviteTeamSchema,
} from "../get-started/onboarding-checklist/schema";
import Roles from "./roles";
import Team from "./team";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

// Basic Button component
export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  ...props
}) => (
  <button className={`rounded px-4 py-2 font-semibold ${className}`} {...props}>
    {children}
  </button>
);

const Teams: React.FC = () => {
  const [activeTab, setActiveTab] = useState("Members");
  const [showAccPopup, setShowAccPopup] = useState(false);
  const { data: userProfile } = trpc.user.getAccountType.useQuery<UserAccountStatus>({});
 const utils =  trpc.useUtils()

 const methods = useForm<InviteTeamSchema>({
  resolver: zodResolver(inviteTeamSchema),
  defaultValues: {
    firstName: "",
    lastName: "",
    role: undefined,
    email: "",
  },
});


  const { mutate: inviteUser, isLoading: isInvitingUser } =
    trpc.user.inviteUser.useMutation({
      onSuccess: () => {
        reset();
        utils.user.getOrganizationMembers.invalidate();
        toast.success(`Team invite successfully sent to ${methods.watch('email')}`)

      },
      onError: (e) => {
        toast.error(e.message);
        // toast.error(e.message);
      },
    });
  


  const {
    register,
    // setValue,
    reset,
    formState: { errors },
  } = methods;

  const onUserInvite = (payload: InviteTeamSchema) => {
    inviteUser({...payload, corporateId: userProfile?.corporateId as number,})
  };

  return (
    <PageContainer>
      <div className="flex items-end justify-between pb-4 mb-6 border-b border-gray-200">
        <div className="flex space-x-1">
          {/* <button className="pb-2 text-sm font-semibold text-blue-600 border-b-2 border-blue-600">Members</button>
          <button className="pb-2 text-sm font-semibold text-gray-500">Roles</button> */}
          <nav className="flex space-x-1">
            {["Members", "Roles"].map((tab) => (
              <button
                key={tab}
                className={`px-4 py-2 text-[15px] font-inter  ${
                  activeTab === tab
                    ? "border-b-1 rounded-xl bg-hbrand-50 font-semibold text-hbrand-600"
                    : "font-semibold text-hgray-400"
                }`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </button>
            ))}
          </nav>
        </div>
        <Button
          onClick={() => setShowAccPopup(true)}
          className="px-3 py-3 text-[15px] font-inter font-medium text-white rounded-xl bg-hbrand-600 hover:bg-hbrand-700"
        >
          + Invite a member
        </Button>
      </div>

      {activeTab === "Members" && <Team />}
      {activeTab === "Roles" && <Roles />}

      <Drawer
        openDrawerBox={showAccPopup}
        onClose={() => setShowAccPopup(false)}
        header="Invite a team member"
        subHeader="These are the company director(s) as listed in your business documents"
        action={
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => setShowAccPopup(false)}
              className="shadow-s-light] mr-[5px] rounded-lg border border-hgray-300 bg-white px-3.5 py-2.5 text-[15px] font-semibold text-hgray-700 hover:opacity-70"
            >
              Close
            </button>
            <button
              type="submit"
              // disabled={!isFormData}
              //  onClick={() => handleNavigate("/login")}
              className="ml-[5px] rounded-lg border border-hbrand-600 bg-hbrand-600 px-12 py-2.5 text-[15px] font-semibold text-white shadow-s-light hover:opacity-80"
            >
              {isInvitingUser ? (
                <span className="loading loading-spinner loading-sm"></span>
              ) : (
                "Send Invite"
              )}
            </button>
          </div>
        }
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onUserInvite)}>
            <fieldset>
              <div className="mb-6 form-div">
                  {true && (
                    <div className="">
                      <div className="flex flex-col w-full pb-6 mx-auto">
                        <label
                          htmlFor="accountName"
                          className="relative w-full"
                        >
                          <div className="mb-1 flex items-center text-[13px] font-medium">
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>

                            <p className="text-htext-label">First Name</p>
                          </div>
                          <Inputs
                            type="text"
                            placeholder="Enter here"
                            register={register("firstName")}
                            inputClass={true}
                            error={errors.firstName}
                          />
                          {errors.firstName && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.firstName.message}!
                            </p>
                          )}
                        </label>

                        <label
                          htmlFor="accountNumber"
                          className="relative mt-[1.30rem] w-full"
                        >
                          <div className="mb-1 flex items-center text-[13px] font-medium">
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>

                            <p className="text-htext-label">Last Name</p>
                          </div>
                          <Inputs
                            type="text"
                            placeholder="Enter here"
                            register={register("lastName")}
                            inputClass={true}
                            error={errors.lastName}
                          />
                          {errors.lastName && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.lastName.message}!
                            </p>
                          )}
                        </label>

                        <label
                          htmlFor="iban"
                          className="relative mt-[1.30rem] w-full"
                        >
                          <div className="mb-1 flex items-center text-[13px] font-medium">
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>

                            <p className="text-htext-label">Email</p>
                          </div>
                          <Inputs
                            type="text"
                            placeholder="Enter here"
                            register={register("email")}
                            inputClass={true}
                            error={errors.email}
                          />
                          {errors.email && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.email.message}!
                            </p>
                          )}
                        </label>

                        <div className="flex flex-col py-5">
                          <span className="mb-1 text-[13px] font-medium text-htext-label">
                            Role <span className="text-herror-600">*</span>
                          </span>
                          <div className="space-y-2">
                            <div className="px-2 py-3 mb-4 border-2 border-gray-100 rounded-sm">
                              <label className="flex items-center space-x-2">
                                <input
                                  type="radio"
                                  value="ADMIN"
                                  {...register("role")}
                                  className="form-radio"
                                />
                                <span>Admin</span>
                              </label>
                              <p className="ml-6 text-sm text-gray-500">
                                For people who need to edit portal account, view
                                all transactions and add other team members
                                (viewers).
                              </p>
                            </div>
                            <div className="px-2 py-3 border-2 border-gray-100 rounded-md">
                              <label className="flex items-center space-x-2">
                                <input
                                  type="radio"
                                  value="VIEWER"
                                  {...register("role")}
                                  className="form-radio"
                                />
                                <span>Viewer</span>
                              </label>
                              <p className="ml-6 text-sm text-gray-500">
                                For people who can only view transactions and
                                cannot make any edit.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center p-4 mt-6 rounded-md mb-15 bg-blue-50">
                        <svg
                          className="mr-5"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.4"
                            d="M17.1667 9.00002C17.1667 13.6024 13.4357 17.3334 8.83333 17.3334C4.23096 17.3334 0.5 13.6024 0.5 9.00002C0.5 4.39765 4.23096 0.666687 8.83333 0.666687C13.4357 0.666687 17.1667 4.39765 17.1667 9.00002Z"
                            fill="#175CD3"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.83334 5.04169C9.17852 5.04169 9.45834 5.32151 9.45834 5.66669V6.50002C9.45834 6.8452 9.17852 7.12502 8.83334 7.12502C8.48817 7.12502 8.20834 6.8452 8.20834 6.50002V5.66669C8.20834 5.32151 8.48817 5.04169 8.83334 5.04169ZM8.83334 7.95835C9.17852 7.95835 9.45834 8.23818 9.45834 8.58335V12.3334C9.45834 12.6785 9.17852 12.9584 8.83334 12.9584C8.48817 12.9584 8.20834 12.6785 8.20834 12.3334V8.58335C8.20834 8.23818 8.48817 7.95835 8.83334 7.95835Z"
                            fill="#175CD3"
                          />
                        </svg>
                        <p className="text-sm text-hbrand-550">
                          We will send this team member an email to notify them
                        </p>
                      </div>
                    </div>
                  )}
              </div>
            </fieldset>
          </form>
        </FormProvider>
      </Drawer>
    </PageContainer>
  );
};

export default Teams;
