import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Drawer from "../../../../components/Common/drawer";
import Tooltip from "../../../../components/Common/tooltip";
import { useModal } from "../../../../context/modalProvider";
import { trpc } from "../../../../lib/trpc";
import RadioButton from "../../../Common/buttons/radioBtn";
import Inputs from "../../../Common/inputs";
import {
  circleSmallMinus,
  circleSmallPlus,
  dollarsSVG,
  euroDarker,
  plusSVG,
  poundsSVG,
  warnToolTipBlueSVG,
} from "../../../Common/svg";
import {
  addDestinationBankSchema,
  AddDestinationBankSchema,
} from "../../get-started/onboarding-checklist/schema";
import { CurrencyTypes } from "../../get-started/onboarding-checklist/walletsSetup/SetupAccounts";
import DeleteAccount from "../../portal-accounts/portal-out/deleteAccount";


export default function BankAccounts() {
  const [selectedValue, setSelectedValue] = useState<CurrencyTypes>();
  const [showEditAccPopup, setShowEditAccPopup] = useState(false);
  const [showAccPopup, setShowAccPopup] = useState(false);
  const [id, setId] = useState<number | null>(null);
  const [loadingBankId, setLoadingBankId] = useState<number | null>(null);
  const { showModal } = useModal();
  const [opnDelete, setOpenDelete] = useState(false);
  const [bankDetail, setBankDetail] = useState({
    currency: "",
    iban: "",
    sortCode: "",
  });
  const [expandedCard, setExpandedCard] = useState<null | number>(null);

  // TRPC CALLS
  const {
    data: userAccount,
    isLoading: isLoadingUserAcct,
    refetch: refetchUserAccount,
    isRefetching: isRefetchingUserAcct,
  } = trpc.user.getBankAccount.useQuery(
    {},
    {
      onSuccess: (data) => {
        if (data) {
          const defaultId = data?.find((obj) => obj.useAsDefault === true);
          setExpandedCard(defaultId?.id ?? null);
        }
      },
    }
  );

  const { mutateAsync: getSession, isLoading: isLoadingGettingSession } =
    trpc.user.isSessionIdExpired.useMutation({
      onError: (e) => {
        toast.error(e.message);
      },
    });

  const { mutateAsync: triggerOtp } = trpc.user.triggerOtp.useMutation({
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const { mutate: setCustomerSetBankDefault } =
    trpc.user.customerSetBankDefault.useMutation({
      onError: (e) => {
        toast.error(e.message);
      },
      onSuccess: (res) => {
        refetchUserAccount();
        toast.success(res.message);
      },
      onSettled: () => {
        setLoadingBankId(null);
      },
    });

  const { mutate: updateUpBank, isLoading: isUpdateUpBank } =
    trpc.user.updateUpBank.useMutation({
      onSuccess: (res) => {
        reset();
        refetchUserAccount();
        toast.success(res);
        closeShowPopup();
      },
      onError: () => {
        toast.error("Something went wrong");
      },
    });

  const {
    mutate: createDestinationBankAcct,
    isLoading: isLoadingCreateDestinationBankAcct,
  } = trpc.user.setUpBank.useMutation({
    onSuccess: () => {
      reset();
      refetchUserAccount();
      toast.success("Bank Account Created");
      setShowAccPopup(false);
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const handleRadioChangeValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedValue(event.target.value as CurrencyTypes);
    setValue("currency", event.target.value as CurrencyTypes);
  };

  const methods = useForm<AddDestinationBankSchema>({
    resolver: zodResolver(addDestinationBankSchema),
    defaultValues: {
      accountNumber: "",
      iban: "",
      accountName: "",
      sortCode: "",
      currency: undefined,
      mainBeneficiary: true,
    },
  });

  const {
    register,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (showEditAccPopup && userAccount) {
      const accountData = userAccount.find((item) => item.id == id);
      // Populate form fields with fetched data
      if (accountData) {
        setValue("accountNumber", accountData.accountNumber || "");
        setValue("iban", accountData.iban || "");
        setValue("accountName", accountData.accountName || "");
        setValue("sortCode", accountData.sortCode || "");
        setValue("currency", accountData.currency as "GBP" | "EUR");
        setSelectedValue(accountData?.currency as "GBP" | "EUR");
      }
    }
  }, [showEditAccPopup, userAccount, setValue]);

  const toggleCard = (card: number) => {
    setExpandedCard(expandedCard === card ? null : card);
  };

  function closeShowPopup() {
    if (showAccPopup === true) {
      setShowAccPopup(false);
    } else {
      setShowEditAccPopup(false);
    }
    reset();
  }

  const onSetUpBankAccount = async (data: AddDestinationBankSchema) => {
    try {
      const response = await getSession({});
      if (response.sessionIdHasExpired) {
        await triggerOtp({});
        showModal();
      } else {
        if (showEditAccPopup && id) {
          updateUpBank({ input: { ...data, id } });
        } else {
          createDestinationBankAcct({ input: data });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="w-full rounded-2xl border-[1.8px] border-hgray-100 mt-4 p-6 pb-8 shadow-s-light bg-hgray-50 hover:bg-hbrand-50">
      <h2 className="mb-4 text-[17.5px] font-semibold text-htext-main">
        Saved settlement bank accounts
      </h2>
      <p className="mb-4 w-[465px] text-[13.5px] text-hgray-500 leading-[18px]">
        Bank account where your portal wallet are sent to these saved local bank
        account
      </p>
      {isLoadingUserAcct || isRefetchingUserAcct ? (
        <div className="px-6 py-4 w-full mx-auto flex justify-center">
          <span className="loading loading-spinner loading-sm"></span>
        </div>
      ) : userAccount && userAccount.length > 0 ? (
        <div className="max-h-[500px] h-auto min-h-[128px] overflow-y-auto scrollbar-none">
          {userAccount?.map(
            ({
              accountName,
              accountNumber,
              currency,
              iban,
              sortCode,
              useAsDefault,
              id,
            }) => (
              <div key={id} className="mb-4 rounded-lg bg-[#F9FAFB]">
                <div
                  className="flex items-center justify-between w-full p-4 cursor-pointer"
                  onClick={() => toggleCard(id)}
                >
                  <div className="flex items-center">
                    {currency === "EUR" ? (
                      <span
                        className="mr-2 w-18"
                        dangerouslySetInnerHTML={{
                          __html: euroDarker,
                        }}
                      />
                    ) : (
                      <span
                        className="mr-2 w-18"
                        dangerouslySetInnerHTML={{
                          __html: poundsSVG,
                        }}
                      />
                    )}
                    <span className="font-semibold">
                      {currency} bank details
                    </span>
                    {useAsDefault && (
                      <span className="px-2 py-1 ml-2 text-xs font-semibold rounded-full text-htext-main">
                        (DEFAULT)
                      </span>
                    )}
                  </div>
                  {id === expandedCard ? (
                    <span
                      className=""
                      dangerouslySetInnerHTML={{
                        __html: circleSmallMinus,
                      }}
                    />
                  ) : (
                    <span
                      className=""
                      dangerouslySetInnerHTML={{
                        __html: circleSmallPlus,
                      }}
                    />
                  )}
                </div>
                {expandedCard === id && (
                  <div className="px-4 pt-2 pb-4">
                    <div className="flex flex-col justify-center w-full px-2 border-b border-gray-200">
                      <div className="flex items-center justify-between w-full">
                        <p className="text-[13.5px] font-normal text-htext-main">
                          Currency
                        </p>

                        <div className="flex items-center">
                          <p className="text-[14.5px] font-semibold text-htext-main">
                            {currency.toUpperCase()}
                          </p>
                        </div>
                      </div>

                      <div className="mt-2.5 flex w-full items-center justify-between">
                        <p className="text-[13.5px] font-normal text-htext-main">
                          Account name
                        </p>
                        <p className="text-[14.5px] font-semibold text-htext-main">
                          {accountName || `${accountName} `}
                        </p>
                      </div>

                      {currency !== "EUR" && (
                        <div className="mt-2.5 flex w-full items-center justify-between">
                          <p className="text-[13.5px] font-normal text-htext-main">
                            Account number
                          </p>
                          <p className="text-[14.5px] font-semibold text-htext-main">
                            {accountNumber}
                          </p>
                        </div>
                      )}

                      <div className="my-2.5 flex w-full items-center justify-between">
                        <p className="text-[13.5px] font-normal text-htext-main">
                          {iban ? "IBAN" : "Sort Code"}
                        </p>
                        <p className="text-[14.5px] font-semibold text-htext-main">
                          {iban ? iban : sortCode}
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center justify-between mt-4">
                      <label className="inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="peer sr-only bg-[#003399]"
                          checked={useAsDefault}
                          onChange={async () => {
                            setLoadingBankId(id);
                            const response = await getSession({});
                            if (response.sessionIdHasExpired) {
                              await triggerOtp({});
                              showModal();
                            } else {
                              setCustomerSetBankDefault({ bankId: id });
                            }
                          }}
                        />
                        <div className="peer relative h-6 w-[2.60rem] rounded-full bg-hgray-200 after:absolute after:start-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-hgray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-hbrand-600 peer-checked:after:translate-x-full peer-checked:after:border-white rtl:peer-checked:after:-translate-x-full dark:border-hgray-400 dark:bg-hgray-300"></div>
                        <span className="ml-3 text-sm font-medium text-hgray-600">
                          Set as default settlement account
                        </span>
                      </label>
                      {(loadingBankId === id || isLoadingGettingSession) && (
                        <Loader2 className="h-4 w-4 animate-spin text-[#003399]" />
                      )}
                      <div className="flex items-center gap-x-4">
                        <button
                          onClick={() => {
                            setShowEditAccPopup(true);
                            setId(id);
                          }}
                          className="text-[13.5px] font-semibold text-hbrand-700 hover:text-hbrand-600"
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          className="flex items-center text-[13px] font-semibold text-herror-700 hover:text-herror-600"
                          onClick={() => {
                            setOpenDelete(true);
                            setId(id);
                            setBankDetail({
                              currency: currency,
                              iban: iban ?? "",
                              sortCode: sortCode ?? "",
                            });
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          )}
        </div>
      ) : (
        <div className="flex h-[170px] flex-col items-center justify-center">
          <div className="flex flex-col items-center py-6">
            <img src="/recent-t.svg" alt="empty recent settlement-account" />
            <h5 className="mt-4 text-center text-[13.5px] font-semibold">
              You have no settlement accounts
            </h5>
            <p className="mt-1 w-[250px] text-center text-[11px] font-normal text-htext-subdued">
              Kindly add an account to get start with your transactions
            </p>
          </div>
        </div>
      )}

      {isLoadingUserAcct ? null : (
        <div className="flex items-center justify-between w-full mt-3.5">
          <div
            className="flex items-center w-full cursor-pointer gap-x-1"
            onClick={() => refetchUserAccount()}
          >
            <img
              src="/assets/arrowRefresh.svg"
              alt="refresh"
              className={`h-[10px] w-[10px] cursor-pointer ${
                isRefetchingUserAcct && "animate-spin"
              }`}
            />
            <span className="text-[13.5px] font-semibold text-hbrand-700">
              Refresh
            </span>
            <div className="mt-1 -ml-2">
              <Tooltip
                tipBody="This is your saved settlement accounts. Click to refresh."
                tipClass="right-0"
                tipStyle="reverse"
              />
            </div>
          </div>

          <div
            onClick={() => {
              setShowAccPopup(true);
              setSelectedValue("EUR");
            }}
            className="flex items-center justify-end w-full cursor-pointer hover:text-hbrand-600"
          >
            <span className="" dangerouslySetInnerHTML={{ __html: plusSVG }} />
            <p className="ml-2.5 text-[13.5px] font-semibold text-hbrand-700">
              Add new account
            </p>
          </div>
        </div>
      )}

      <Drawer
        openDrawerBox={showAccPopup || showEditAccPopup}
        onClose={closeShowPopup}
        header={showEditAccPopup ? "Edit account" : "Add new account"}
        subHeader="This is your local currency account"
        action={
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => closeShowPopup()}
              className="shadow-s-light] mr-[5px] rounded-lg border border-hgray-300 bg-white px-3.5 py-2.5 text-[15px] font-semibold text-hgray-700 hover:opacity-70"
            >
              Close
            </button>
            <button
              type="submit"
              // disabled={!isFormData}
              onClick={handleSubmit(onSetUpBankAccount)}
              className="ml-[5px] rounded-lg border border-hbrand-600 bg-hbrand-600 px-12 py-2.5 text-[15px] font-semibold text-white shadow-s-light hover:opacity-80"
            >
              {isLoadingCreateDestinationBankAcct ||
              isUpdateUpBank ||
              isLoadingGettingSession ? (
                <span className="loading loading-spinner loading-sm"></span>
              ) : (
                "Save account details"
              )}
            </button>
          </div>
        }
      >
        <FormProvider {...methods}>
          <form>
            <fieldset className="mb-6">
              <div className="flex items-center">
                <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">*</p>
                <p className="text-[13px] font-medium  text-htext-label">
                  Select currency
                </p>
              </div>
              <div className="flex items-center justify-between w-full mt-2 radio-btns">
                <div className="btn-1 flex h-[2.5rem] w-[31.3%] items-center justify-between rounded-lg border border-hgray-200 bg-hgray-25 py-7 pl-3 pr-4">
                  <p className="flex items-center text-[14.5px] font-semibold text-htext-main">
                    <span
                      className="mr-2 w-18"
                      dangerouslySetInnerHTML={{
                        __html: euroDarker,
                      }}
                    />
                    Euros
                  </p>
                  <RadioButton
                    name="currency"
                    value="EUR"
                    checked={selectedValue === "EUR"}
                    onChange={handleRadioChangeValue}
                    className="w-auto accs-radio"
                  />
                </div>

                <div className="btn-2 flex h-[2.5rem] w-[31.3%] items-center justify-between rounded-lg border border-hgray-200 bg-hgray-25 py-7 pl-3 pr-4">
                  <p className="flex items-center text-[14.5px] font-semibold text-htext-main">
                    <span
                      className="mr-2 w-18"
                      dangerouslySetInnerHTML={{
                        __html: poundsSVG,
                      }}
                    />
                    Pounds
                  </p>
                  <RadioButton
                    name="currency"
                    value="GBP"
                    checked={selectedValue === "GBP"}
                    onChange={handleRadioChangeValue}
                    className="w-auto accs-radio curren"
                  />
                </div>

                <div
                  className={`btn-3 flex h-[2.5rem] w-[31.3%] items-center justify-between rounded-lg border border-hgray-200 ${"bg-hgray-100"}  py-7 pl-3 pr-4`}
                >
                  <p className="flex items-center text-[14.5px] font-semibold text-htext-main">
                    <span
                      className="mr-2 w-18"
                      dangerouslySetInnerHTML={{
                        __html: dollarsSVG,
                      }}
                    />
                    Dollars
                  </p>
                  <RadioButton
                    name="currency"
                    value="DOLLARS"
                    disabled={true}
                    onChange={handleRadioChangeValue}
                    className={`accs-radio w-auto ${
                      true && "cursor-not-allowed"
                    }`}
                  />
                </div>
              </div>

              <div className="mt-10 flex w-full items-start rounded-[12px] border border-hbrand-300 bg-hbrand-50 py-3.5 pl-4 pr-16 shadow-s-light">
                <span
                  className="mr-4"
                  dangerouslySetInnerHTML={{
                    __html: warnToolTipBlueSVG,
                  }}
                />
                <p className="text-[13.5px] font-semibold text-htext-label">
                  To successfully add your bank account, your bank account name
                  must match the business name you submitted on Shiga
                </p>
              </div>

              <div className="flex flex-col items-center justify-between w-full h-full">
                {true && (
                  <div className="w-full mt-3">
                    <div className="flex flex-col w-full pt-1 pb-6 mx-auto">
                      <label
                        htmlFor="accountName"
                        className="relative mt-[1.30rem] w-full"
                      >
                        <div className="mb-1 flex items-center text-[13px] font-medium">
                          <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                            *
                          </p>

                          <p className="text-htext-label">Account name</p>
                        </div>
                        <Inputs
                          type="text"
                          placeholder="Enter here"
                          register={register("accountName")}
                          inputClass={true}
                          error={errors.accountName}
                        />
                        {errors.accountName && (
                          <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                            {errors.accountName.message}!
                          </p>
                        )}
                      </label>

                      <label
                        htmlFor="accountNumber"
                        className="relative mt-[1.30rem] w-full"
                      >
                        <div className="mb-1 flex items-center text-[13px] font-medium">
                          <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                            *
                          </p>

                          <p className="text-htext-label">Account number</p>
                        </div>
                        <Inputs
                          type="text"
                          placeholder="Enter here"
                          register={register("accountNumber")}
                          inputClass={true}
                          error={errors.accountNumber}
                        />
                        {errors.accountNumber && (
                          <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                            {errors.accountNumber.message}!
                          </p>
                        )}
                      </label>

                      {selectedValue === "EUR" && (
                        <label
                          htmlFor="iban"
                          className="relative mt-[1.30rem] w-full"
                        >
                          <div className="mb-1 flex items-center text-[13px] font-medium">
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>

                            <p className="text-htext-label">IBAN</p>
                          </div>
                          <Inputs
                            type="text"
                            placeholder="Enter here"
                            register={register("iban")}
                            inputClass={true}
                            error={errors.iban}
                          />
                          {errors.iban && (
                            <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                              {errors.iban.message}!
                            </p>
                          )}
                        </label>
                      )}
                      {selectedValue === "GBP" && (
                        <label
                          htmlFor="sortCode"
                          className="relative mt-[1.30rem] w-full"
                        >
                          <div className="mb-1 flex items-center text-[13px] font-medium">
                            <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                              *
                            </p>

                            <p className="text-htext-label">Sort code</p>
                          </div>
                          <Inputs
                            type="text"
                            placeholder="Enter here"
                            register={register("sortCode")}
                            inputClass={true}
                            error={errors.sortCode}
                          />
                          {/* {errors.sortCode && ( */}
                          <p
                            className={`font-ibm-plex absolute mt-1.5 text-[11.5px] ${
                              errors.sortCode
                                ? "font-medium text-red-600"
                                : "font-normal text-htext-subdued"
                            }`}
                          >
                            {errors.sortCode
                              ? errors.sortCode.message + "!"
                              : " Enter the 6-digit sort code"}
                          </p>
                          {/* )} */}
                        </label>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </fieldset>
          </form>
        </FormProvider>
      </Drawer>

      {opnDelete && (
        <DeleteAccount
          bankDetail={bankDetail}
          closeModal={() => setOpenDelete(false)}
          id={id as number}
          refetch={refetchUserAccount}
        />
      )}
    </div>
  );
}
