import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { portalSwapStable, portalYellowCheck } from "../../../Common/svg";
import PortalOutSetup from "./index";

const PortalInStable: FC = () => {
  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  return (
    <PortalOutSetup currentStep={1} complete={false}>
      <div className="portal-type hide-scrollbar mt-12 h-full w-full overflow-y-scroll">
      <div className="type-content flex flex-col h-full items-center justify-between pt-3 ">
         <div className="overflow-y-scroll hide-scrollbar pb-[4rem] mx-auto">
          <div className="type-top w-[720px] pb-5">
            <div className="type-top pb-10">

            <div className="type-top-banner relative bg-new-linear shadow-s-semi-light flex items-center justify-between rounded-2xl px-5 py-6">
              <div className="w-[62.5%] z-10">
                <h6 className="text-htext-subdued text-[11.5px] font-normal">PORTAL OUT OF STABLECOIN</h6>
                <h3 className="mt-4 text-[22px] font-semibold leading-[31px] text-htext-main">
                Swap stablecoin to fiat
                </h3>
                <p className="mt-[5px] text-[14px] font-normal text-htext-main leading-[20px]">
                Use your stablecoin wallet to receive G7 currencies in your bank account without going through complex processes
                </p>
              </div>

              <div className="w-[20.5%] pr-5 z-10">
                <span 
                 className="block w-fit float-right"
                 dangerouslySetInnerHTML={{ __html: portalSwapStable }}
                />
              </div>
            </div>

            <div className="top-steps mt-11 flex w-full items-center justify-between">
              <div className="relative w-[47.5%] rounded-lg bg-hgray-50 p-6">
               <h5 className="text-htext-main text-[14.5px] font-semibold">What we need from you</h5>

               <div className="steps-bullet flex items-start gap-x-4 mt-5">
               <span 
                 className="mt-0.5"
                 dangerouslySetInnerHTML={{ __html: portalYellowCheck }}
                />

                <p className="text-htext-main text-[12.5px] font-normal">Fiat account details in local currency which will be your settlement bank account. This is where we will send your money to after swapping from stablecoin.</p>
               </div>

               <div className="steps-bullet flex items-start gap-x-4 mt-4">
               <span 
                 className="mt-0.5"
                 dangerouslySetInnerHTML={{ __html: portalYellowCheck }}
                />

                <p className="text-htext-main text-[12.5px] font-normal">Euro, Pound are currencies currently supported. Dollars coming up soon.</p>
               </div>
              </div>

              <div className="relative w-[47.5%] rounded-lg bg-hgray-50 p-6">
               <h5 className="text-htext-main text-[14.5px] font-semibold">What you get from Shiga</h5>

               <div className="steps-bullet flex items-start gap-x-4 mt-5">
               <span 
                 className="mt-0.5"
                 dangerouslySetInnerHTML={{ __html: portalYellowCheck }}
                />

                <p className="text-htext-main text-[12.5px] font-normal">A virtual stablecoin wallet address is created for you to send  amount to. We call this your portal stablecoin wallet.</p>
               </div>

               <div className="steps-bullet flex items-start gap-x-4 mt-4">
               <span 
                 className="mt-0.5"
                 dangerouslySetInnerHTML={{ __html: portalYellowCheck }}
                />

                <p className="text-htext-main text-[12.5px] font-normal">Any amount you send into this portal stablecoin wallet is swapped into fiat currency and sent to your settlement bank account.</p>
               </div>
              </div>
            </div>

            <div 
            onClick={() => handleNavigate("/dashboard/portal-accounts/portal-into-stablecoin")}
            className="rando-link mt-8 text-center text-hbrand-700 hover:text-hbrand-600 text-[12.5px] font-semibold cursor-pointer  hover:underline hover:underline-offset-4">
              <p>I want to swap fiat to stablecoin instead</p>
            </div>

            </div>
          </div>
        </div>

          <div className="form-btns flex h-auto fixed bottom-0 bg-white w-full items-center justify-between border-t border-t-hgray-200 px-14 pb-6 pt-6">
            <button
              className="w-[150px] rounded-lg border  border-solid border-hgray-300 bg-white px-[10px] py-[11px] text-[15px] font-semibold text-hgray-700 shadow-s-light hover:bg-hgray-50"
              type="button"
              onClick={() => navigate('/dashboard/portal-accounts')}
            >
              Go Back
            </button>

            <button
              className="w-[250px] rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[11px] text-[15px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
              type="button"
              onClick={() => handleNavigate("/dashboard/portal-accounts/portal-out-settlement-information")}
            >
              Add settlement information
            </button>
        </div>
        </div>
      </div>
    </PortalOutSetup>
  );
};

export default PortalInStable;
