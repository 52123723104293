import React from "react";
import { warnQuestion } from "../svg";

type TransactionDetailProps = {
  type: string;
  fee?: string;
  amount: string;
  rate: string;
};

export const TransactionDetail: React.FC<TransactionDetailProps> = ({
  type,
  amount,
  rate,
}) => {
  
  const tipBody =
    type === "Portal out of stablecoin"
      ? "Swap STABLECOIN to FIAT by using your stablecoin wallet to receive G7 currencies in your bank account"
      : "Swap FIAT to STABLECOIN by buying stablecoin's on EVM and Solana without logging into your crypto app or swapping with someone P2P";

  return (
    <div className="w-full pt-3">
      <div className="flex-1">
        <div className="relative flex items-center justify-between mb-3">
          <div className="flex items-center">
            <div className="flex flex-col items-center justify-center">
              <div className="w-1 h-6 -mt-6 border-l-2 border-dashed border-hgray-300"></div>
              <span className="-ml-[1px] h-[7px] w-[7px] rounded-full bg-hgray-300"></span>
            </div>
            <span className="ml-3 text-[12.5px] font-normal text-htext-subdued">
              Type
            </span>
          </div>
          <span className="flex items-center text-[12.5px] font-normal text-htext-subdued">
            {type}
            <div className={`tipppy ml-1`}>
              <span
                className="cursor-pointer tippy-warn"
                dangerouslySetInnerHTML={{ __html: warnQuestion }}
              />
              <div className="tippy-deck">
                <p className="font-semibold text-htext-main">{type}</p>
                <p className="mt-1">{tipBody}</p>
              </div>
            </div>
          </span>
        </div>

        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center">
            <div className="flex flex-col items-center justify-center">
              <div className="-mt-[26px] h-6 w-1 border-l-2 border-dashed border-hgray-300"></div>
              <span className="-ml-[1px] mt-0.5 h-[7px] w-[7px] rounded-full bg-hgray-300"></span>
            </div>
            <span className="ml-3 text-[12.5px] font-normal text-htext-subdued">
              Amount to portal in
            </span>
          </div>
          <span className="text-[12.5px] font-normal text-htext-subdued">
            {amount}
          </span>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="flex flex-col items-center justify-center">
              <div className="w-1 h-6 border-l-2 border-dashed -mt-7 border-hgray-300"></div>
              <span className="-ml-[1px] mt-0.5 h-[7px] w-[7px] rounded-full bg-hgray-300"></span>
            </div>

            <span className="ml-3 text-[12.5px] font-normal text-htext-subdued">
              Rate
            </span>
          </div>
          <span className="text-[12.5px] font-normal text-htext-subdued">
            {rate}
          </span>
        </div>

        <div className="flex items-start ">
          <div className="flex flex-col items-center justify-center">
            <div className="-mt-1 ml-[0.8px] h-4 w-1 border-l-2 border-dashed border-hgray-300"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
