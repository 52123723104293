import { zodResolver } from "@hookform/resolvers/zod";
import { CheckIcon } from "lucide-react";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Drawer from "../../../../components/Common/drawer";
import Inputs from "../../../../components/Common/inputs";
import MainLoader from "../../../../components/Common/loader/mainLoader";
import { chainOptions, fiatSwapIcons, tokenOptions } from "../../../../constant/options";
import { useModal } from "../../../../context/modalProvider";
import { trpc } from "../../../../lib/trpc";
import { TransactionDetail } from "../../../Common/calDetails";
import DropDownWithIcon from "../../../Common/dropdown/DropDownWithIcon";
import SidePopup from "../../../Common/popup/sidepopup";
import {
  BlueThinArrowSVG,
  calculator,
  circleSmallMinus,
  circleSmallPlus,
  closeBtnSVG,
  CopyWhiteSVG,
  flusteredWarning,
  uSDCSVG,
  UsdtSvg,
} from "../../../Common/svg";
import {
  setupWalletWithoutCurrencySchema,
  SetupWalletWithoutCurrencySchema,
} from "../../get-started/onboarding-checklist/schema";
import { Chain } from "../../get-started/onboarding-checklist/walletsSetup/types";
import PortalInSetup from "./index";
import {
  CurencyIconKeys,
  currencyIcons,
  MapAccountItem,
} from "./portal-in-details";


type AccordionItem = {
  currency: string;
  account_number: string;
  bank_country: string;
  sort_code: string;
  status: string;
  uuid: string;
};

const items: AccordionItem[] = [
  {
    currency: "usd",
    account_number: "00042476",
    bank_country: "GB",
    sort_code: "040509",
    status: "ACTIVE",
    uuid: "18a408ed-7f45-4c2b-8fbf-26305627e5e",
  },
];

const dropFiatOptions = [
  {
    value: "USD",
    type: "fiat",
  },
  {
    value: "EUR",
    type: "fiat",
  },
  {
    value: "GBP",
    type: "fiat",
  },
];

const dropStableOptions = [
  {
    value: "USDT",
    type: "stablecoin",
  },
  {
    value: "USDC",
    type: "stablecoin",
  },
];

const PortalInBankAccount: FC = () => {
  const navigate = useNavigate();
  const stableDropdownRef = useRef<HTMLDivElement>(null);
  const fiatDropdownRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setSearchParams] = useSearchParams();

  const [showPopup, setShowPopup] = useState(false);
  const [calRate, setCalRate] = useState("0");
  const [calBase, setCalBase] = useState(`${dropFiatOptions[0]?.value}`);
  const [calTarget, setCalTarget] = useState(`${dropStableOptions[0]?.value}`);
  const [openItemId, setOpenItemId] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currency, setCurrency] = useState("");
  const [calculatedValue, setCalculatedValue] = useState("");
  const [amount, setAmount] = useState("");
  const [showDropDownFiat, setShowDropDownFiat] = useState(false);
  const [showDropDownStable, setShowDropDownStable] = useState(false);
  const [showCalPopup, setShowCalPopup] = useState(false);
  const [showEditWalletPopup, setShowEditWalletPopup] = useState(false);
  const [copiedId, setCopiedId] = useState<string | null>(null);
  const { showModal } = useModal();

  const { data: getTokenPreferences } = trpc.user.getTokenPreferences.useQuery(
    {}
  );

  const copyToClipboard = (details: {
    currency: string;
    account_number: string;
    iban?: string;
    sort_code?: string;
    bic?: string;
    account_name?: string;
    uuid: string;
  }) => {
    const formattedDetails = [
      `Currency ${details.currency?.toUpperCase()}`,
      `Account name ${
        details.account_name || `${user.data?.firstName} ${user.data?.lastName}`
      }`,
      details.account_number && `Account number ${details.account_number}`,
      `${details.iban ? "IBAN" : "Sort Code"} ${
        details.iban || details.sort_code
      }`,
      details.bic && `Bank Identification Code (BIC) ${details.bic}`,
    ]
      .filter(Boolean)
      .join("\n");

    navigator.clipboard.writeText(formattedDetails).then(() => {
      setCopiedId(details.account_number);
      setTimeout(() => setCopiedId(null), 2000);
    });
  };

  const copyAddressToClipboard = (address: string) => {
    navigator.clipboard.writeText(address.toString()).then(() => {
      setCopiedId(address);
      setTimeout(() => setCopiedId(null), 2000);
    });
  };

  useEffect(() => {
    setSearchParams((prev) => {
      prev.set("amountToSnd", calBase);
      prev.set("amountToRcv", calTarget);
      prev.set("calBase", amount);
      prev.set("calTarget", calculatedValue);
      return prev;
    });
  }, [calculatedValue, amount, calBase, calTarget, setSearchParams]);

  function closeShowPopup() {
    if (showCalPopup === true) {
      setShowCalPopup(false);
    } else {
      setShowEditWalletPopup(false);
    }
    reset();
  }

  const toggleItem = (id: string) => {
    setOpenItemId(openItemId === id ? null : id);
  };

  // Handle clicks outside of Stablecoin dropdown
  useEffect(() => {
    const handleClickOutsideStable = (event: any) => {
      if (
        stableDropdownRef.current &&
        !stableDropdownRef?.current?.contains(event.target)
      ) {
        setShowDropDownStable(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideStable);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideStable);
    };
  }, []);

  // Handle clicks outside of Fiat dropdown
  useEffect(() => {
    const handleClickOutsideFiat = (event: any) => {
      if (
        fiatDropdownRef.current &&
        !fiatDropdownRef.current.contains(event.target)
      ) {
        setShowDropDownFiat(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideFiat);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideFiat);
    };
  }, []);

  const { data: tr } = trpc.user.getCalculatedRate.useQuery({
    base: calBase,
    target: calTarget,
  });

  const user = trpc.user.getUserById.useQuery({});

  const { data: unblockBankAccts, isLoading: isLoadingUnblockBankAccts } =
    trpc.user.listUnblockBankAccounts.useQuery({});

  const { data: userWallet, isLoading: isLoadingUserWallet } =
    trpc.user.getWallet.useQuery({});

  const { mutateAsync: getSession, isLoading: isLoadingGettingSession } =
    trpc.user.isSessionIdExpired.useMutation({
      onError: (e) => {
        toast.error(e.message);
      },
    });

    
  const { mutateAsync: triggerOtp } = trpc.user.triggerOtp.useMutation({
    onError: (e) => {
      toast.error(e.message);
    },
  });

  //  edit wallet details
  const utils = trpc.useUtils();
  const { mutate: UpdateWallet, isLoading: isUpdatingWallet } =
    trpc.user.UpdateWallet.useMutation({
      onSuccess: () => {
        reset();
        utils.user.getWallet.refetch();
        utils.user.getTokenPreferences.invalidate();
        chainOptions;
        closeShowPopup();
      },
      onError: (e) => {
        console.log(e);
        toast.error(e.message);
      },
    });

  useEffect(() => {
    if (amount === "" || isNaN(parseFloat(amount))) {
      setCalculatedValue("0");
    } else if (tr) {
      const rate = tr.exchange_rate; // Adjust based on the actual response structure
      const roundedUpRates = rate.toFixed(2);
      setCalRate(roundedUpRates);
      const targetValue = (parseFloat(amount) * rate).toFixed(2);
      setCalculatedValue(targetValue);
    }
  }, [tr, amount]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setAmount(value);
    }
  };

  const onSetUpWallet = async (data: SetupWalletWithoutCurrencySchema) => {
    try {
      const response = await getSession({});
      if (response.sessionIdHasExpired) {
        await triggerOtp({});
        showModal();
      } else {
        if (showEditWalletPopup) {
          UpdateWallet({ ...data,currency });
        } 
      }
    } catch (error) {
      console.error(error);
    }
  };

  const methods = useForm<SetupWalletWithoutCurrencySchema>({
    resolver: zodResolver(setupWalletWithoutCurrencySchema),
    defaultValues: {
      chain: undefined,
      token: "",
      walletAddress: "",
      confirmWalletAddress: "",
    },
  });

  const {
    control,
    register,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (showEditWalletPopup && userWallet) {
      const walletData = userWallet[0];
      // Populate form fields with fetched data
      if (walletData) {
        setValue("chain", walletData?.chain as Chain);
        setValue("token", walletData.token || "");
        setValue("walletAddress", walletData?.walletAddress || "");
        setValue("confirmWalletAddress", walletData?.walletAddress || "");
      }
    }
  }, [showEditWalletPopup, userWallet, setValue]);

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  useEffect(() => {
    const handleClickOutsideStable = (event: any) => {
      if (
        stableDropdownRef.current &&
        !stableDropdownRef?.current?.contains(event.target)
      ) {
        setShowDropDownStable(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideStable);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideStable);
    };
  }, []);

  // Handle clicks outside of Fiat dropdown
  useEffect(() => {
    const handleClickOutsideFiat = (event: any) => {
      if (
        fiatDropdownRef.current &&
        !fiatDropdownRef.current.contains(event.target)
      ) {
        setShowDropDownFiat(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideFiat);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideFiat);
    };
  }, []);

  const [filteredChainOptions, setFilteredChainOptions] =
    useState(chainOptions);
  const selectedToken = useWatch({ control, name: "token" });

  // Filter/sort chain options based on selected token
  useEffect(() => {
    if (selectedToken === "usdc") {
      setFilteredChainOptions(
        chainOptions.filter(
          (option) => !["tron", "celo"].includes(option.value)
        )
      ); // Example
    } else if (selectedToken === "usdt") {
      setFilteredChainOptions(
        chainOptions.filter(
          (option) => !["base", "celo"].includes(option.value)
        )
      ); // Example
    }
  }, [selectedToken]);

  useEffect(() => {
    if (amount === "" || isNaN(parseFloat(amount))) {
      setCalculatedValue("0");
    } else if (tr) {
      const rate = tr.exchange_rate; // Adjust based on the actual response structure
      const roundedUpRates = rate.toFixed(2);
      setCalRate(roundedUpRates);
      const targetValue = (parseFloat(amount) * rate).toFixed(2);
      setCalculatedValue(targetValue);
    }
  }, [tr, amount]);

  if (isLoadingUserWallet) {
    return <MainLoader />;
  }
  return (
    <PortalInSetup currentStep={3} complete={false}>
      <div className="portal-type hide-scrollbar mt-12 h-full w-full overflow-y-scroll">
        <div className="type-content flex h-full flex-col items-center justify-between pt-3 ">
          <div className="type-top w-[60%] pb-5">
            <div className="type-text flex w-full items-center justify-between">
              <div className="text-content">
                <h3 className="text-[22px] font-semibold leading-[31px] text-htext-main">
                  Your portal bank account
                </h3>
                <p className="mt-[5px] w-[400px] text-[13px] font-normal leading-[18px] text-htext-main">
                  Send fiat money to this Shiga generated virtual account below
                  and it converts into stablecoin in your stablecoin settlement
                  account.
                </p>
              </div>
              <div className="text-btns">
                <button
                  className="flex w-[210px] items-center justify-center gap-x-2 rounded-lg border border-solid border-hbrand-600 bg-white px-[10px] py-[9px] text-[13px] font-semibold text-hbrand-700 shadow-s-light hover:border-hbrand-700 hover:bg-hgray-50 hover:text-hbrand-600"
                  type="button"
                  onClick={() => setShowPopup(true)}
                >
                  <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: calculator }}
                  />
                  <p>Calculate rate conversion</p>
                </button>
              </div>
            </div>

            <div className="content-table  mt-6 flex w-full py-3">
              <div className="portal-account w-full">
                <div className="type-top pb-10 pt-9">
                  <div className="space-y-4">
                    {isLoadingUnblockBankAccts && (
                      <span className="loading loading-spinner loading-sm"></span>
                    )}
                    {!isLoadingUnblockBankAccts &&
                      unblockBankAccts &&
                      [...unblockBankAccts, ...items]?.map(
                        ({
                          bic,
                          currency,
                          iban,
                          uuid,
                          account_number,
                          account_name,
                          sort_code,
                        }: MapAccountItem) => (
                          <div
                            key={uuid}
                            className={`${
                              openItemId === uuid &&
                              "border-[#F9FAFB ] rounded-lg border-[1px] p-[24px]"
                            }`}
                          >
                            <div
                              onClick={() => {
                                if (currency === "usd") return;
                                toggleItem(uuid);
                              }}
                              className={`flex w-full items-center justify-between rounded-lg py-5 ${
                                openItemId === uuid
                                  ? "shadow-none rounded-none border-none bg-white px-2"
                                  : "bg-hgray-50 px-6 focus:outline-none"
                              }`}
                            >
                              <div className="flex items-center">
                                {
                                  fiatSwapIcons?.find(
                                    ({ value }) =>
                                      value.toLocaleLowerCase() ==
                                      currency?.toLocaleLowerCase()
                                  )?.icon
                                }
                                <p className="ml-3 text-[14px] font-semibold text-htext-main">
                                  {currency?.toUpperCase() == "EUR"
                                    ? "Euros"
                                    : currency?.toUpperCase() == "GBP"
                                      ? "Pounds"
                                      : "Dollars"}{" "}
                                  to Stablecoin
                                </p>
                              </div>
                              {currency === "usd" ? (
                                <p className="rounded-2xl border border-solid border-hbrand-200 bg-hbrand-50 px-[5px] py-[0.5px] text-[11px] font-medium text-hbrand-700">
                                  Coming soon
                                </p>
                              ) : openItemId === uuid ? (
                                <div className="flex items-center">
                                  <span
                                    className="mr-3"
                                    dangerouslySetInnerHTML={{
                                      __html: circleSmallMinus,
                                    }}
                                  />
                                  <p className="font-ibm-plex text-[14px] font-semibold text-hgray-500">
                                    Collapse
                                  </p>
                                </div>
                              ) : (
                                <div className="flex items-center">
                                  <span
                                    className="mr-3"
                                    dangerouslySetInnerHTML={{
                                      __html: circleSmallPlus,
                                    }}
                                  />
                                  <p className=" font-family-inherit text-[14px] font-semibold text-hgray-500">
                                    Expand
                                  </p>
                                </div>
                              )}
                            </div>
                            {openItemId === uuid && (
                              <div className="grid grid-cols-2 gap-6">
                                <div className="rounded-lg bg-hgray-50 p-6">
                                  <div className="account-nav">
                                    <h3 className="text-[16px] font-semibold leading-[31px] text-htext-label">
                                      1. Send here
                                    </h3>
                                    <p className="mt-[5px] w-[400px] text-[14px] font-normal leading-[19px] text-htext-subdued">
                                      Send {currency?.toUpperCase()} to this
                                      Shiga generated virtual account to convert
                                      to stablecoin.
                                    </p>
                                  </div>
                                  <div className="rounded-[12px] p-4 shadow-s-light">
                                    <div className="flex items-center">
                                      <span
                                        className=""
                                        dangerouslySetInnerHTML={{
                                          __html: flusteredWarning,
                                        }}
                                      />
                                      <p className="ml-2.5 text-[12.5px] font-medium text-htext-subdued">
                                        Send only {currency?.toUpperCase()} from
                                        accounts in your name to avoid losing
                                        funds. This account doesn't hold{" "}
                                        {currency?.toUpperCase()}; funds are
                                        auto-converted and sent to your
                                        stablecoin wallet.{" "}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="accountDetails flex w-full flex-col justify-center px-2 py-5">
                                    <div className="flex w-full items-center justify-between py-2.5">
                                      <p className="text-[13.5px] font-normal text-htext-main">
                                        Currency
                                      </p>

                                      <div className="flex items-center">
                                        <p className="text-[14.5px] font-semibold text-htext-main">
                                          {currency?.toUpperCase()}
                                        </p>
                                        <span
                                          className="ml-2"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              currencyIcons[
                                                currency?.toLowerCase() as CurencyIconKeys
                                              ],
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="flex w-full items-center justify-between py-2.5">
                                      <p className="text-[13.5px] font-normal text-htext-main">
                                        Account name
                                      </p>
                                      <p className="text-[14.5px] font-semibold text-htext-main">
                                        {account_name ||
                                          `${user.data?.firstName} ${user.data?.lastName}`}
                                      </p>
                                    </div>

                                    {currency !== "EUR" && (
                                      <div className="flex w-full items-center justify-between py-2.5">
                                        <p className="text-[13.5px] font-normal text-htext-main">
                                          Account number
                                        </p>
                                        <p className="text-[14.5px] font-semibold text-htext-main">
                                          {account_number}
                                        </p>
                                      </div>
                                    )}

                                    <div className="flex w-full items-center justify-between py-2.5">
                                      <p className="text-[13.5px] font-normal text-htext-main">
                                        {iban ? "IBAN" : "Sort Code"}
                                      </p>
                                      <p className="text-[14.5px] font-semibold text-htext-main">
                                        {iban ? iban : sort_code}
                                      </p>
                                    </div>
                                    {currency == "EUR" && (
                                      <div className="flex w-full items-center justify-between py-2.5">
                                        <p className="text-[13.5px] font-normal text-htext-main">
                                          Bank Identification Code (BIC)
                                        </p>
                                        <p className="text-[14.5px] font-semibold text-htext-main">
                                          {bic}
                                        </p>
                                      </div>
                                    )}
                                  </div>

                                  <div className="w-full px-2">
                                    <button
                                      onClick={() =>
                                        copyToClipboard({
                                          bic,
                                          currency,
                                          iban,
                                          uuid,
                                          account_number,
                                          account_name,
                                          sort_code,
                                        })
                                      }
                                      className="flex w-full items-center justify-center rounded-lg border border-hbrand-600 bg-hbrand-600 px-4 py-2 hover:border-hbrand-700 hover:bg-hbrand-700"
                                    >
                                      {copiedId === account_number ? (
                                        <CheckIcon className="h-5 w-5 text-white" />
                                      ) : (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: CopyWhiteSVG,
                                          }}
                                        />
                                      )}
                                      <p className="ml-2 text-[14.5px] font-semibold text-white">
                                        {copiedId === account_number
                                          ? "Copied!"
                                          : "Copy details"}
                                      </p>
                                    </button>
                                  </div>
                                </div>

                                <div className="portal-settlement h-[320px] rounded-lg bg-hgray-50 p-6">
                                  <div className="account-nav">
                                    <h3 className="text-[16px] font-semibold leading-[31px] text-htext-label">
                                      2. Recieve here
                                    </h3>
                                    <p className="mt-[5px] w-[400px] text-[14px] font-normal leading-[19px] text-htext-subdued">
                                      Receive your swapped stablecoin into the
                                      wallet below.{" "}
                                    </p>
                                  </div>
                                  {isLoadingUserWallet && (
                                    <span className="loading loading-spinner loading-sm"></span>
                                  )}
                                  {!isLoadingUserWallet &&
                                    getTokenPreferences
                                      ?.filter(
                                        (item: { currency: string }) =>
                                          item.currency == currency
                                      )
                                      .map(
                                        ({
                                          token,
                                          chain,
                                        }: {
                                          token: string;
                                          chain: string;
                                        }) => (
                                          <div
                                            key={token}
                                            className="info-content mt-5 rounded-lg bg-hgray-50 px-6 pb-5 pt-4"
                                          >
                                            <div className="flex w-full items-start justify-between pb-4">
                                              <div className="lower-left flex w-[48.5%] items-center">
                                                <div className="rounded-full border border-solid border-hgray-200 p-2">
                                                  <span
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        token === "usdc"
                                                          ? uSDCSVG
                                                          : UsdtSvg,
                                                    }}
                                                  />
                                                </div>

                                                <div className="lower-left-text ml-2 text-htext-main">
                                                  <p className="text-[14px] font-semibold">
                                                    {token.toLocaleUpperCase()}
                                                  </p>
                                                  <p className="text-[10.5px] font-normal">
                                                    ({chain})
                                                  </p>
                                                </div>
                                              </div>

                                              <div className="lower-right w-[58.5%] text-htext-main">
                                                <p className="text-[10.5px] font-normal">
                                                  WALLET ADDRESS
                                                </p>
                                                <p className="mt-0.5 w-[13.4rem] break-words text-[14px] font-semibold">
                                                  {
                                                    userWallet?.[0]
                                                      ?.walletAddress
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                            <div className="mb-4 mt-2 flex w-full items-center justify-end border-b border-t-hgray-200 pb-2">
                                              <button
                                                type="button"
                                                className="flex items-center text-[12.5px] font-semibold text-hbrand-700 hover:text-hbrand-600"
                                                onClick={() => {
                                                  setShowEditWalletPopup(true);
                                                  setCurrency(currency);
                                                }}
                                              >
                                                Edit
                                              </button>
                                            </div>
                                            <div className="w-full px-2">
                                              <button
                                                onClick={() =>
                                                  copyAddressToClipboard(
                                                    userWallet?.[0]
                                                      ?.walletAddress as string
                                                  )
                                                }
                                                className="flex w-full items-center justify-center rounded-lg border border-hbrand-600 bg-hbrand-600 px-4 py-2 hover:border-hbrand-700 hover:bg-hbrand-700"
                                              >
                                                {copiedId ===
                                                userWallet?.[0]
                                                  ?.walletAddress ? (
                                                  <CheckIcon className="h-5 w-5 text-white" />
                                                ) : (
                                                  <span
                                                    dangerouslySetInnerHTML={{
                                                      __html: CopyWhiteSVG,
                                                    }}
                                                  />
                                                )}
                                                <p className="ml-2 text-[14.5px] font-semibold text-white">
                                                  {copiedId ===
                                                  userWallet?.[0]?.walletAddress
                                                    ? "Copied!"
                                                    : "Copy address"}
                                                </p>
                                              </button>
                                            </div>
                                          </div>
                                        )
                                      )}
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-btns flex h-fit w-full items-center justify-between border-t border-t-hgray-200 px-14 pb-1 pt-6">
            <button
              className="w-[150px] rounded-lg border  border-solid border-hgray-300 bg-white px-[10px] py-[13px] text-[15px] font-semibold text-hgray-700 shadow-s-light hover:bg-hgray-50"
              type="button"
              onClick={() =>
                navigate(
                  "/dashboard/portal-accounts/portal-in-activate-accounts"
                )
              }
            >
              Go Back
            </button>

            <button
              className="w-[250px] rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[13px] text-[15px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
              type="button"
              onClick={() => handleNavigate("/dashboard/portal-accounts")}
            >
              Go to accounts
            </button>
          </div>
        </div>
      </div>
      {showPopup && (
        <SidePopup
          className="signup-popup"
          popClass="pt-[1.3rem] pb-[1.5rem] w-[33rem]"
        >
          <div className="popup-content-bg flex h-full flex-col items-center justify-between px-7">
            <div className=" flex w-full flex-col items-center pb-7">
              <div className="content-top w-full">
                <div className="content-nav -px-[1.5rem] flex w-full items-start justify-between">
                  <div>
                    <h3 className="text-[18px] font-semibold text-htext-main">
                      Rate calculator
                    </h3>
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => closeShowPopup()}
                  >
                    <span
                      className=""
                      dangerouslySetInnerHTML={{ __html: closeBtnSVG }}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4 w-full">
                <div className="content-cal w-full rounded-lg bg-hgray-50 px-5 py-6">
                  <div className="from-card flex w-full items-center justify-between rounded-lg border border-hgray-300 bg-white px-5 py-4 shadow-s-light">
                    <div className="w-[160px]">
                      <p className="text-left text-[14px] font-medium text-htext-main">
                        From
                      </p>
                      <input
                        type="text"
                        className="mt-2.5 bg-transparent text-left text-[18px] font-normal text-htext-main outline-none"
                        placeholder={`${
                          calBase === "EUR"
                            ? "€"
                            : calBase === "GBP"
                              ? "£"
                              : calBase === "USD"
                                ? "$"
                                : "0"
                        }`}
                        value={amount}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="from-drop">
                      <p className="text-right text-[12px] font-normal capitalize text-htext-subdued">
                        Fiat
                      </p>
                      <div
                        className="mt-2.5 cursor-pointer rounded-[500px] border-0 bg-hgray-200 py-1 pl-2 pr-2.5 text-right text-[13px] font-medium text-htext-label outline-none hover:bg-hgray-300"
                        onClick={() => setShowDropDownFiat(!showDropDownFiat)}
                        ref={fiatDropdownRef}
                      >
                        <div className="flex items-center">
                          <img
                            className="w-[14px]"
                            src={`/assets/${
                              calBase === "USD"
                                ? "dollarSmall"
                                : calBase === "EUR"
                                  ? "euroSmall"
                                  : "poundsSmall"
                            }.svg`}
                            alt={calBase}
                          />
                          <p className="ml-1 mr-2.5">{calBase}</p>
                          <span
                            className="cursor-pointer"
                            dangerouslySetInnerHTML={{
                              __html: BlueThinArrowSVG || "",
                            }}
                          />
                        </div>
                        {showDropDownFiat && (
                          <ul className="absolute z-50 -ml-10 mt-3 w-[120px] rounded-lg border border-solid border-hgray-200 bg-hgray-100 py-3">
                            {dropFiatOptions.map((option) => (
                              <li
                                key={option.value}
                                className="cursor-pointer px-4 py-1 text-left text-[13px] hover:bg-hgray-300"
                                onClick={() => {
                                  setCalBase(option.value);
                                  setShowDropDownFiat(false);
                                }}
                              >
                                <p>{option.value}</p>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="middleCont flex w-full flex-col justify-center pl-16 pr-1 pt-1.5">
                    <TransactionDetail
                      type="Portal in to stablecoin"
                      fee="- €4"
                      amount={amount || "0"}
                      rate={`x ${calRate}`}
                    />
                  </div>

                  <div className="to-card flex w-full items-center justify-between rounded-lg border border-hgray-300 bg-white px-5 py-4 shadow-s-light">
                    <div className="w-[160px]">
                      <p className="text-left text-[14px] font-medium text-htext-main">
                        To
                      </p>
                      <input
                        type="text"
                        className="mt-2.5 bg-transparent text-left text-[18px] font-normal text-htext-main outline-none"
                        placeholder={`${
                          calTarget === "EUR"
                            ? "€"
                            : calTarget === "GBP"
                              ? "£"
                              : calTarget === "USD"
                                ? "$"
                                : "0"
                        }`}
                        readOnly
                        value={calculatedValue}
                      />
                    </div>

                    <div className="to-drop">
                      <p className="text-right text-[12px] font-normal capitalize text-htext-subdued">
                        Stablecoin
                      </p>
                      <div
                        className="mt-2.5 cursor-pointer rounded-[500px] border-0 bg-hgray-200 py-1 pl-2 pr-2.5 text-right text-[13px] font-medium text-htext-label outline-none hover:bg-hgray-300"
                        onClick={() =>
                          setShowDropDownStable(!showDropDownStable)
                        }
                        ref={stableDropdownRef}
                      >
                        <div className="flex items-center">
                          <img
                            className="w-[14px]"
                            src={`/assets/${
                              calTarget === "USDC" ? "usdcSmall" : "usdtSmall"
                            }.svg`}
                            alt={calTarget}
                          />
                          <p className="ml-1 mr-2.5">{calTarget}</p>
                          <span
                            className="cursor-pointer"
                            dangerouslySetInnerHTML={{
                              __html: BlueThinArrowSVG || "",
                            }}
                          />
                        </div>
                        {showDropDownStable && (
                          <ul className="absolute z-50 -ml-10 mt-3 w-[120px] rounded-lg border border-solid border-hgray-200 bg-hgray-100 py-3">
                            {dropStableOptions.map((option) => (
                              <li
                                key={option.value}
                                className="cursor-pointer px-4 py-1 text-left text-[13px] hover:bg-hgray-300"
                                onClick={() => {
                                  setCalTarget(option.value);
                                  setShowDropDownStable(false);
                                }}
                              >
                                <p>{option.value}</p>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Edit wallet popup */}
                <Drawer
                  openDrawerBox={true}
                  onClose={closeShowPopup}
                  header={
                    showEditWalletPopup
                      ? "Edit your stablecoin wallet"
                      : "Add your stablecoin wallet"
                  }
                  subHeader="This is your stablecoin wallet"
                  action={
                    <div className="flex justify-end">
                      <button
                        type="button"
                        onClick={closeShowPopup}
                        className="shadow-s-light] mr-[5px] rounded-lg border border-hgray-300 bg-white px-3.5 py-2.5 text-[15px] font-semibold text-hgray-700 hover:opacity-70"
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        onClick={methods.handleSubmit(onSetUpWallet)}
                        className="ml-[5px] rounded-lg border border-hbrand-600 bg-hbrand-600 px-12 py-2.5 text-[15px] font-semibold text-white shadow-s-light hover:opacity-80"
                      >
                        {isUpdatingWallet || isLoadingGettingSession ? (
                          <span className="loading loading-spinner loading-sm"></span>
                        ) : (
                          "Save wallet details"
                        )}
                      </button>
                    </div>
                  }
                >
                  <FormProvider {...methods}>
                    <form>
                      <fieldset className="h-full">
                        <div className="w-full pb-2">
                          <div
                            className={`flex w-full items-start justify-between ${
                              errors.token || errors?.chain
                                ? "mt-[.80rem] h-24"
                                : "mt-[1.30rem]"
                            }`}
                          >
                            <label
                              htmlFor="stablecoin"
                              className="relative h-full w-[48.5%]"
                            >
                              <div className="mb-1.5 flex items-center text-[13px] font-medium">
                                <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                  *
                                </p>
                                <p className="text-htext-label">Stablecoin</p>
                              </div>
                              <DropDownWithIcon
                                register={register}
                                name="token"
                                options={tokenOptions}
                                error={errors.token}
                              />
                              {errors.token && (
                                <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                  {errors.token.message}!
                                </p>
                              )}
                            </label>

                            <label
                              htmlFor="smartchain"
                              className="relative h-full w-[48.5%]"
                            >
                              <div className="mb-1.5 flex items-center text-[13px] font-medium">
                                <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                  *
                                </p>

                                <p className="text-htext-label">Smartchain</p>
                              </div>

                              <DropDownWithIcon
                                register={register}
                                name="chain"
                                options={filteredChainOptions}
                                error={errors?.chain}
                              />
                              {errors?.chain && (
                                <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                  {errors?.chain.message}!
                                </p>
                              )}
                            </label>
                          </div>

                          <div
                            className={`mx-auto flex w-full flex-col pb-6  pt-1 ${
                              errors?.walletAddress ||
                              errors.confirmWalletAddress
                                ? "h-48 justify-start"
                                : ""
                            }`}
                          >
                            <label
                              htmlFor="walletAddress"
                              className={`${
                                errors?.walletAddress
                                  ? "mt-[.50rem]"
                                  : "mt-[1.30rem]"
                              } w-full"`}
                            >
                              <div className="mb-1 flex items-center text-[13px] font-medium">
                                <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                  *
                                </p>

                                <p className="text-htext-label">
                                  Wallet address
                                </p>
                              </div>
                              <Inputs
                                type="text"
                                placeholder="Enter here"
                                register={register("walletAddress")}
                                error={errors.walletAddress}
                                inputClass={true}
                              />
                              {errors.walletAddress && (
                                <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                  {errors.walletAddress.message}!
                                </p>
                              )}
                            </label>

                            <label
                              htmlFor="confirmWalletAddress"
                              className={`${
                                errors.confirmWalletAddress
                                  ? "mt-[2.3rem]"
                                  : "mt-[1.30rem]"
                              } w-full"`}
                            >
                              <div className="mb-1 flex items-center text-[13px] font-medium">
                                <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                                  *
                                </p>

                                <p className="text-htext-label">
                                  Confirm wallet address
                                </p>
                              </div>
                              <Inputs
                                type="text"
                                placeholder="Enter here"
                                register={register("confirmWalletAddress")}
                                inputClass={true}
                                error={errors.confirmWalletAddress}
                              />
                              {errors.confirmWalletAddress && (
                                <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                                  {errors.confirmWalletAddress.message}!
                                </p>
                              )}
                            </label>
                          </div>
                        </div>
                      </fieldset>
                    </form>
                  </FormProvider>{" "}
                </Drawer>

                <div className="content-info mt-10 w-full border-t border-t-hgray-200 py-5">
                  <div className="info-text">
                    <h5 className="text-[13px] font-medium text-htext-main">
                      Settlement information
                    </h5>
                    <p className="mt-1 w-[450px] text-[13px] font-normal text-htext-subdued">
                      Local currency sent to your portal account are sent to
                      this stablecoin wallet you selected as default.
                    </p>
                  </div>

                  <div className="info-content mt-4 rounded-lg bg-hgray-50 p-6">
                    <div className="flex w-full items-center justify-between">
                      <div className="lower-left flex w-[48.5%] items-center">
                        <div className="rounded-full border border-solid border-hgray-200 p-2">
                          <span dangerouslySetInnerHTML={{ __html: UsdtSvg }} />
                        </div>

                        <div className="lower-left-text ml-2 text-htext-main">
                          <p className="text-[14px] font-semibold">USDT</p>
                          <p className="text-[10.5px] font-normal">
                            (Ethereum)
                          </p>
                        </div>
                      </div>

                      <div className="lower-right w-[58.5%] text-htext-main">
                        <p className="text-[10.5px] font-normal">
                          WALLET ADDRESS
                        </p>
                        <p className="mt-0.5 w-[13.4rem] break-words text-[14px] font-semibold">
                          0xb794f5ea0ba39494ce839613fffba74279579268
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="close-btn w-full border-t border-hgray-200 py-5">
              <button
                className="w-full rounded-lg border border-solid border-brand-600 bg-brand-600 px-[10px] py-[11px] text-[15px] font-semibold text-white shadow-s-light hover:bg-hbrand-700"
                type="button"
                onClick={() => closeShowPopup()}
              >
                Close
              </button>
            </div>
          </div>
        </SidePopup>
      )}
    </PortalInSetup>
  );
};

export default PortalInBankAccount;
