import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import Drawer from "../../../../components/Common/drawer";
import DropDownWithIcon from "../../../../components/Common/dropdown/DropDownWithIcon";
import Inputs from "../../../../components/Common/inputs";
import Tooltip from "../../../../components/Common/tooltip";
import { chainOptions, tokenOptions } from "../../../../constant/options";
import { useModal } from "../../../../context/modalProvider";
import { trpc } from "../../../../lib/trpc";
import { usdcSVG, UsdtSvg } from "../../../Common/svg";
import {
  SetupWalletWithoutCurrencySchema,
  setupWalletWithoutCurrencySchema,
} from "../../get-started/onboarding-checklist/schema";
import { Chain } from "../../get-started/onboarding-checklist/walletsSetup/types";

export default function Wallet() {
  const [showPopup, setShowPopup] = useState(false);
  const [showEditWalletPopup, setShowEditWalletPopup] = useState(false);
  const { showModal } = useModal();
  const [filteredChainOptions, setFilteredChainOptions] =
    useState(chainOptions);
  const utils = trpc.useUtils();

  // TRPC CALLS
  const methods = useForm<SetupWalletWithoutCurrencySchema>({
    resolver: zodResolver(setupWalletWithoutCurrencySchema),
    defaultValues: {
      chain: undefined,
      token: "",
      walletAddress: "",
      confirmWalletAddress: "",
    },
  });

  const {
    control,
    register,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const { mutate: setUpWallet, isLoading: isSettingUpWallet } =
    trpc.user.setUpWallet.useMutation({
      onSuccess: () => {
        reset();
        utils.user.getWallet.refetch();
        setShowPopup(false);
      },
      onError: (e) => {
        console.log(e);
        toast.error(e.message);
      },
    });

  const { mutate: UpdateWallet, isLoading: isUpdatingWallet } =
    trpc.user.UpdateWallet.useMutation({
      onSuccess: () => {
        reset();
        utils.user.getWallet.refetch();
        closeShowPopup();
      },
      onError: (e) => {
        console.log(e);
        toast.error(e.message);
      },
    });

  const { data: userWallet, isLoading: isLoadingUserWallet, refetch: refetchUserWallet, isRefetching: isRefetchingUserWallet } =
    trpc.user.getWallet.useQuery({});
  const { mutateAsync: getSession, isLoading: isLoadingGettingSession } =
    trpc.user.isSessionIdExpired.useMutation({
      onError: (e) => {
        toast.error(e.message);
      },
    });

  const { mutateAsync: triggerOtp } = trpc.user.triggerOtp.useMutation({
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const { mutate: setCustomerSetWalletDefault } =
    trpc.user.customerSetWalletDefault.useMutation({
      onError: (e) => {
        toast.error(e.message);
      },
      onSuccess: (res) => {
        utils.user.getWallet.refetch();
        toast.success(res.message);
        closeShowPopup();
      },
    });
  const selectedToken = useWatch({ control, name: "token" });

  // Filter/sort chain options based on selected token
  useEffect(() => {
    if (selectedToken === "usdc") {
      setFilteredChainOptions(
        chainOptions.filter(
          (option) => !["tron", "celo"].includes(option.value)
        )
      ); // Example
    } else if (selectedToken === "usdt") {
      setFilteredChainOptions(
        chainOptions.filter(
          (option) => !["base", "celo"].includes(option.value)
        )
      ); // Example
    }
  }, [selectedToken]);

  useEffect(() => {
    if (showEditWalletPopup && userWallet) {
      const walletData = userWallet[0];
      // Populate form fields with fetched data
      if (walletData) {
        setValue("chain", walletData?.chain as Chain);
        setValue("token", walletData.token || "");
        setValue("walletAddress", walletData.walletAddress || "");
        setValue("confirmWalletAddress", walletData.walletAddress || "");
      }
    }
  }, [showEditWalletPopup, userWallet, setValue]);

  function closeShowPopup() {
    if (showPopup === true) {
      setShowPopup(false);
    } else {
      setShowEditWalletPopup(false);
    }
    reset();
  }

  const onSetUpWallet = async (data: SetupWalletWithoutCurrencySchema) => {
    try {
      const response = await getSession({});
      if (response.sessionIdHasExpired) {
        await triggerOtp({});
        showModal();
      } else {
        if (userWallet)
          if (showEditWalletPopup) {
            UpdateWallet({ ...data, currency: userWallet[0].currency });
          } else {
            setUpWallet({ ...data, currency: userWallet[0].currency });
          }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="w-full p-6 pb-8 border-[1.8px] border-hgray-100 mt-4 rounded-2xl shadow-s-light bg-hgray-50 hover:bg-get-linear">
      <h2 className="mb-4 text-[17.5px] font-semibold text-htext-main">
        Saved settlement stablecoin wallets
      </h2>
      <p className="mb-4 w-[465px] text-[13.5px] text-hgray-500 leading-[18px]">
        Local currency from your portal account are sent to these saved
        stablecoin wallets
      </p>
      {isLoadingUserWallet ? (
       <div className="px-6 py-4 w-full mx-auto flex justify-center">
       <span className="loading loading-spinner loading-sm"></span>
     </div>
      ) : userWallet && userWallet?.length > 0 ? (
        <div className="max-h-[310px] h-auto min-h-[128px] overflow-y-auto scrollbar-none">
          {userWallet?.map(
            ({ token, walletAddress, useAsDefault, id, chain }) => (
              <div key={walletAddress} className="mb-4 ">
                <div className="flex items-center justify-between mb-2">
                  <div className="flex justify-between p-5">
                    <div className="flex items-center">
                      <span
                        className={`mr-2 flex h-6  w-6 items-center justify-center rounded-full text-xs font-bold text-white`}
                      >
                        <span
                          className="mr-2 w-18"
                          dangerouslySetInnerHTML={{
                            __html:
                              token.toLowerCase() === "usdt"
                                ? UsdtSvg
                                : usdcSVG,
                          }}
                        />
                      </span>
                      <div className="flex flex-col">
                        <span className="font-semibold">
                          {token.toUpperCase()}
                        </span>
                        <span className="text-sm font-normal capitalize">
                          ({chain.toLowerCase()})
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="p-3">
                    <p className="mb-2 text-xs text-gray-500">WALLET ADDRESS</p>
                    <p className="mb-2 w-[200px] break-words text-sm">
                      {walletAddress}
                    </p>
                  </div>
                </div>
                <hr className="h-px my-4 bg-gray-200 border-0" />
                <div className="flex items-center justify-between">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={useAsDefault}
                      onChange={() =>
                        setCustomerSetWalletDefault({ walletId: id })
                      }
                    />
                    <div className="peer relative h-6 w-11 rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700"></div>
                    <span className="ml-3 text-sm font-medium text-hgray-600">
                      Set as default settlement wallet
                    </span>
                  </label>
                  <div className="flex items-center gap-x-4">
                    <div
                      className="flex items-center w-full cursor-pointer gap-x-1"
                      onClick={() => refetchUserWallet()}
                    >
                      <img
                        src="/assets/arrowRefresh.svg"
                        alt="refresh"
                        className={`h-[10px] w-[10px] cursor-pointer ${
                          isRefetchingUserWallet && "animate-spin"
                        }`}
                      />
                      <span className="text-[13.5px] font-semibold text-hbrand-700 hover:text-hbrand-600">
                        Refresh
                      </span>
                      <div className="mt-1 -ml-2">
                        <Tooltip
                          tipBody="This is your saved wallet address. Click to refresh."
                          tipClass="right-0 top-0 z-40"
                        />
                      </div>
                    </div>

                    <button
                      onClick={() => setShowEditWalletPopup(true)}
                      className="text-[13.5px] font-semibold text-hbrand-700 hover:text-hbrand-600"
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      ) : (
        <div className="flex h-[170px] flex-col items-center justify-center">
          <div className="flex flex-col items-center py-6">
            <img src="/recent-t.svg" alt="empty recent wallet" />
            <h5 className="mt-4 text-center text-[13.5px] font-semibold">
              You have no saved wallet
            </h5>
            <p className="mt-1 w-[250px] text-center text-[11px] font-normal text-htext-subdued">
              Kindly add a wallet to get start with your transactions
            </p>
          </div>
        </div>
      )}

      {/* Edit wallet popup */}
      <Drawer
        openDrawerBox={showEditWalletPopup}
        onClose={closeShowPopup}
        header={
          showEditWalletPopup
            ? "Edit your stablecoin wallet"
            : "Add your stablecoin wallet"
        }
        subHeader="This is your stablecoin wallet"
        action={
          <div className="flex justify-end">
            <button
              type="button"
              onClick={closeShowPopup}
              className="shadow-s-light] mr-[5px] rounded-lg border border-hgray-300 bg-white px-3.5 py-2.5 text-[15px] font-semibold text-hgray-700 hover:opacity-70"
            >
              Close
            </button>
            <button
              type="submit"
              onClick={methods.handleSubmit(onSetUpWallet)}
              className="ml-[5px] rounded-lg border border-hbrand-600 bg-hbrand-600 px-12 py-2.5 text-[15px] font-semibold text-white shadow-s-light hover:opacity-80"
            >
              {isSettingUpWallet ||
              isUpdatingWallet ||
              isLoadingGettingSession ? (
                <span className="loading loading-spinner loading-sm"></span>
              ) : (
                "Save wallet details"
              )}
            </button>
          </div>
        }
      >
        <FormProvider {...methods}>
          <form>
            <fieldset className="h-full">
              <div className="w-full pb-2">
                <div
                  className={`flex w-full items-start justify-between ${
                    errors.token || errors.chain
                      ? "mt-[.80rem] h-24"
                      : "mt-[1.30rem]"
                  }`}
                >
                  <label
                    htmlFor="stablecoin"
                    className="relative h-full w-[48.5%]"
                  >
                    <div className="mb-1.5 flex items-center text-[13px] font-medium">
                      <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                        *
                      </p>
                      <p className="text-htext-label">Stablecoin</p>
                    </div>
                    <DropDownWithIcon
                      register={register}
                      name="token"
                      options={tokenOptions}
                      error={errors.token}
                    />
                    {errors.token && (
                      <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                        {errors.token.message}!
                      </p>
                    )}
                  </label>

                  <label
                    htmlFor="smartchain"
                    className="relative h-full w-[48.5%]"
                  >
                    <div className="mb-1.5 flex items-center text-[13px] font-medium">
                      <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                        *
                      </p>

                      <p className="text-htext-label">Smartchain</p>
                    </div>

                    <DropDownWithIcon
                      register={register}
                      name="chain"
                      options={filteredChainOptions}
                      error={errors.chain}
                    />
                    {errors.chain && (
                      <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                        {errors.chain.message}!
                      </p>
                    )}
                  </label>
                </div>

                <div
                  className={`mx-auto flex w-full flex-col pb-6  pt-1 ${
                    errors.walletAddress || errors.confirmWalletAddress
                      ? "h-48 justify-start"
                      : ""
                  }`}
                >
                  <label
                    htmlFor="walletAddress"
                    className={`${
                      errors.walletAddress ? "mt-[.50rem]" : "mt-[1.30rem]"
                    } w-full"`}
                  >
                    <div className="mb-1 flex items-center text-[13px] font-medium">
                      <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                        *
                      </p>

                      <p className="text-htext-label">Wallet address</p>
                    </div>
                    <Inputs
                      type="text"
                      placeholder="Enter here"
                      register={register("walletAddress")}
                      error={errors.walletAddress}
                      inputClass={true}
                    />
                    {errors.walletAddress && (
                      <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                        {errors.walletAddress.message}!
                      </p>
                    )}
                  </label>

                  <label
                    htmlFor="confirmWalletAddress"
                    className={`${
                      errors.confirmWalletAddress
                        ? "mt-[2.3rem]"
                        : "mt-[1.30rem]"
                    } w-full"`}
                  >
                    <div className="mb-1 flex items-center text-[13px] font-medium">
                      <p className="mr-1 mt-[-2px] h-[10px] text-herror-600">
                        *
                      </p>

                      <p className="text-htext-label">Confirm wallet address</p>
                    </div>
                    <Inputs
                      type="text"
                      placeholder="Enter here"
                      register={register("confirmWalletAddress")}
                      inputClass={true}
                      error={errors.confirmWalletAddress}
                    />
                    {errors.confirmWalletAddress && (
                      <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                        {errors.confirmWalletAddress.message}!
                      </p>
                    )}
                  </label>
                </div>
              </div>
            </fieldset>
          </form>
        </FormProvider>{" "}
      </Drawer>
    </div>
  );
}
