import { Outlet, useLocation } from "react-router-dom";
import { trpc } from "../../lib/trpc";
import Navbar from "../../section/navbar";
import Sidebar from "../../section/sidebar";
import MainLoader from "../Common/loader/mainLoader";

function Dashboard() {
  const location = useLocation();
  const { data, isLoading } = trpc.user.getAccountType.useQuery({});
  const { data: user } = trpc.user.getUserById.useQuery({});

  const currentRoute = location.pathname;
  let navbarTitle = "";
  let navbarSubTitle = "";
  let userData = {
    name: "",
    email: "",
    company: "",
  };

  if (currentRoute.includes("/dashboard/home")) {
    navbarTitle = user?.firstName ? `Welcome ${user?.firstName}` : "";

    if (data?.acountType) {
      navbarSubTitle =
        data?.acountType === "INDIVIDUAL"
          ? ""
          : "You're signed in as an admin in";
    }

    userData = {
      name: "",
      email: data?.email || "",
      company: "",
    };
  } else if (currentRoute.includes("/portal-accounts")) {
    navbarTitle = "Portal accounts";
    userData = {
      name: "",
      email: data?.email || "",
      company: "",
    };
  } else if (currentRoute.includes("/transactions")) {
    navbarTitle = "Transactions";
    userData = {
      name: "",
      email: data?.email || "",
      company: "",
    };
  } else if (currentRoute.includes("/dashboard/help-and-faq")) {
    navbarTitle = "Help and FAQ";
  } else if (currentRoute.includes("/dashboard/account-settings")) {
    navbarTitle = "Account Settings";
  } else if (currentRoute.includes("/dashboard/teams")) {
    navbarTitle = "Teams";
  }

  if (isLoading) {
    return <MainLoader />;
  }
//   console.log({navbarTitle, navbarSubTitle, userData})
  return (
    <>
      <section className="mdxl:block bg-white grid h-screen grid-cols-[260px_1fr] overflow-auto">
        <aside className="sticky top-0 h-screen mdxl:hidden">
          <Sidebar />
        </aside>
        <main className="w-full h-full bg-white mdxl:w-full">
          <div className="">
            <Navbar
              navHeader={navbarTitle}
              navSubheader={navbarSubTitle}
              navUser={userData}
            />
            <Outlet />
          </div>
        </main>
      </section>
    </>
  );
}

export default Dashboard;
