import { useEffect, useState } from "react";
import { Section } from ".";
import { closeBtnSVG } from "../../Common/svg";
import FAQItem from "./faqItem";

type shigaFaq =
  | {
      id?: number | undefined;
      createdAt?: string | undefined;
      updatedAt?: string | undefined;
      section?: string | null | undefined;
      question?: string | null | undefined;
      answer?: string | null | undefined;
      show?: boolean | undefined;
    }[]
  | undefined;

const FAQSection = ({
  section,
  data,
}: {
  section: Section;
  data: shigaFaq;
}) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [searchStr, setSearchStr] = useState("");
  const [dataArray, setDataArray] = useState<shigaFaq>([]);

  const sortedFaq =
    section == "all" ? data : data?.filter((e) => e.section == section);

  useEffect(() => {
    let slicedData = sortedFaq;
    if (searchStr !== "") {
      slicedData = sortedFaq?.filter(
        (item) =>
          item?.question?.toLowerCase()?.includes(searchStr?.toLowerCase())
      ) as shigaFaq;
    }

    setDataArray(slicedData);
  }, [searchStr, sortedFaq]);

  const handleChangeValue = (title: string) => {
    setSearchStr(title);
  };

  const clearSearch = () => {
    setSearchStr("");
  };

  return (
    <div className="col-span-2 mb-5">
      <h1 className="mb-5 text-2xl font-bold font-ibm-plex text-hgray-900">
        Frequently asked questions
      </h1>
      <div className="focus:ring-2 flex h-[45px] w-full items-center gap-[20px] rounded-[8px] border-[1px] border-gray-300 px-3 py-1 ring-offset-gray-300 sm:gap-[0px]">
        <input
          type="text"
          value={searchStr}
          onChange={(e) => handleChangeValue(e.target.value)}
          placeholder="Search FAQ with any keyword..."
          className="h-full w-full border-none bg-transparent text-[18px] outline-none focus:outline-none sm:text-[14px]"
        />
        {searchStr && (
          <span
            className="-mr-3 flex h-[44px] w-[44px] cursor-pointer items-center justify-center"
            dangerouslySetInnerHTML={{ __html: closeBtnSVG }}
            onClick={clearSearch}
          />
        )}
      </div>
      {dataArray &&
        dataArray?.map(({ question, answer }, index) => {
          return (
            <FAQItem
              key={index}
              question={question}
              answer={answer}
              isOpen={openIndex === index}
              onClick={() => setOpenIndex(openIndex === index ? null : index)}
            />
          );
        })}
      {dataArray?.length === 0 && (
        <p className="flex h-[400px] flex-col justify-center text-center text-[18px] font-[500] leading-[27px] text-[#505660]">
          No Result Found for <b className="">{searchStr}</b>
        </p>
      )}
    </div>
  );
};

export default FAQSection;
