import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import {
	DashboardSVG,
	GetStartedSVG,
	HelpFaqSVG,
	PortalAccSVG,
	TeamsSVG,
	TransactionsSVG,
} from "../../components/Common/svg";
import { trpc } from "../../lib/trpc";

interface Item {
  name: string;
  icon: string;
  route: string;
  subRoute: string;
}

interface SideItemProps {
  item: Item;
}

const SideItem: FC<SideItemProps> = ({ item }) => {
  const location = useLocation();
  const isActive =
    item?.route === location.pathname ||
    location.pathname.includes(item?.subRoute);

  return (
    <Link to={item?.route} className="">
      <div
        className={`mb-3 flex w-[220px] flex-[1_0_0%] items-center ${
          isActive ? "bg-hbrand-50 text-hbrand-600" : "text-htext-label"
        } rounded-[6px] py-2 pl-3.5 pr-2 hover:bg-hbrand-50`}
      >
        <div
          className={`mr-4 transition-transform ${
            isActive ? "scale-90" : "scale-100"
          }`}
        >
          <span dangerouslySetInnerHTML={{ __html: item?.icon }} />
        </div>
        <h3
          className={`icon-name w-full text-sm ${
            isActive ? "font-semibold" : "font-normal"
          }`}
        >
          {item?.name}
        </h3>
      </div>
    </Link>
  );
};

const Sidebar: FC = () => {
  const location = useLocation();
  const { data: user } = trpc.user.getAccountType.useQuery({});

  const itemsData: Item[] = [
    {
      name: "Get Started",
      icon: GetStartedSVG,
      route: "get-started",
      subRoute: "get-started",
    },
    {
      name: "Dashboard",
      icon: DashboardSVG,
      route: "home",
      subRoute: "home",
    },
    {
      name: "Portal accounts",
      icon: PortalAccSVG,
      route: "portal-accounts",
      subRoute: "portal-accounts",
    },
    {
      name: "Transactions",
      icon: TransactionsSVG,
      route: "transactions",
      subRoute: "transactions",
    },
    {
      name: "Teams",
      icon: TeamsSVG,
      route: "teams",
      subRoute: "teams",
    },
    {
      name: "Help and FAQ",
      icon: HelpFaqSVG,
      route: "help-and-faq",
      subRoute: "help-and-faq",
    },
  ];

  // Filter out the Teams item if the account type is Individual
  const filteredItemsData =
    ((user?.acountType || "") as string) === "INDIVIDUAL"
      ? itemsData.filter((item) => item.name !== "Teams")
      : itemsData;

  const finalItemsData =
    ((user?.shigaKycStatus || "") as string) === "ACTIVE" &&
    location.pathname !== "/dashboard/get-started"
      ? filteredItemsData.filter((item) => item.name !== "Get Started")
      : filteredItemsData;

  return (
    // <div className="fixed z-40 flex h-screen w-[16.5rem] flex-col bg-hgray-50 py-6">
    <div className="flex flex-col justify-between h-screen bg-hgray-50">
      <div className='mt-6'>
        <div className="pl-8 pr-8">
          <div className="w-[100px] gap-9 pb-4 pt-2">
            <img className="w-full" src="/shiga-cus.svg" alt="Shiga.io logo" />
            <p className="text-xs font-normal text-center text-htext-subdued">
              FOR{" "}
              {(user?.acountType == "CORPORATE"
                ? "BUSINESSES"
                : user?.acountType?.split("_")[0] || ""
              ).toUpperCase()}
            </p>
          </div>
        </div>

        <div className="pl-5 pr-9">
          <div className="mt-10">
            {finalItemsData.map((item, index) => (
              <SideItem item={item} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
