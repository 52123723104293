import { FC } from "react";
import { warnToolTipSVG } from "../svg";

interface ToolTipProps {
  icon?: string;
  tipClass?: string;
  tipHeader?: string;
  tipBody?: string;
  tipStyle?: "normal" | "reverse";
}

const Tooltip: FC<ToolTipProps> = ({
  icon,
  tipClass,
  tipHeader,
  tipBody,
  tipStyle = "normal",
}) => {

  return (
    <div className={`tipppy ml-1 ${tipClass}`}>
      <span
        className="cursor-pointer tippy-warn"
        dangerouslySetInnerHTML={{ __html: icon || warnToolTipSVG }}
      />
      {/* <div className="tippy-deck">
       */}
      <div
        className={`tippy-deck ${
          tipStyle === "reverse" ? "tippy-deck-reverse" : ""
        }`}
      >
        <p className="font-semibold text-htext-main">{tipHeader}</p>
        <p className="mt-1">{tipBody}</p>
      </div>
    </div>
  );
};

export default Tooltip;
