import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as z from "zod";
import Drawer from "../../../components/Common/drawer";
import PageContainer from "../../../components/Common/pageContainer";
import { trpc } from "../../../lib/trpc";
import { UserAccountStatus } from "../../../route/dashboard/transactions";
import { formatDate2 } from "../../../utils";
import CommonDropdown from "../../Common/dropdown/CommonDrop";
import EasyDateDrop from "../../Common/dropdown/EasyDateDrop";
import FileUpload from "../../Common/inputs/fileUpload";
import Select from "../../Common/inputs/select";
import Pagination from "../../Common/pagination";
import {
  closeBtnSmallSVG,
  SearchSVG,
  DropArrowThinGraySVG,
} from "../../Common/svg";
import { Table } from "../../Common/table";
import ViewTransaction from "./viewTransaction";

const columns = [
  {
    label: "Date",
    key: "createdAt",
    format: (date: string) => formatDate2(date),
  },
  { label: "Status", key: "status" },
  {
    label: "Type",
    key: "direction",
    format: (direction: string) =>
      direction === "cryptoToFiat" ? "Portal Out" : "Portal In",
  },
  {
    label: "Conversion",
    key: { currencyCrypto: "currencyCrypto", currencyFiat: "currencyFiat" },
    format: (
      value: { currencyCrypto: string; currencyFiat: string },
      row: any
    ) => {
      if (!value.currencyCrypto || !value.currencyFiat) {
        return "Processing";
      }

      const cryptoCurrency = value.currencyCrypto.toUpperCase();
      const fiatCurrency = value.currencyFiat.toUpperCase();

      return row.direction === "cryptoToFiat"
        ? `${cryptoCurrency} to ${fiatCurrency}`
        : `${fiatCurrency} to ${cryptoCurrency}`;
    },
  },
  {
    label: "Input amount",
    key: "amountCrypto", // Depending on the conversion type, this could be either crypto or fiat
    format: (_: string, row: any) =>
      row.direction === "cryptoToFiat" ? row.amountCrypto : row.amountFiat,
  },
  {
    label: "Output amount",
    key: "amountFiat",
    format: (_: string, row: any) =>
      row.direction === "cryptoToFiat" ? row.amountFiat : row.amountCrypto,
  }, // Added amountSent
];

export type queryType = "user" | "company";

type queryObjectTypes = {
  page: number;
  limit: number;
  id: number;
  search: string;
  currency: string;
  direction: string;
  dateFrom: string;
  dateTo: string;
  ype: queryType;
};

const currency = ["GBP", "USD", "EUR"];
const portalType = ["cryptoToFiat", "fiatToCrypto"];

const sof = ["Transaction issue", "Product inquiry", "Complaints and feedback"];

const contactUsSchema = z.object({
  issue: z.string().min(1, "Issue type is required"),
  message: z.string().min(1, "Description type is required"),
  uploadImage: z.string(),
});

export type ContactUsSchema = z.infer<typeof contactUsSchema>;

const DashTransaction = ({
  userData,
}: {
  userData: Pick<UserAccountStatus, "acountType" | "corporateId" | "userId">;
}) => {
  const id =
    userData.acountType === "INDIVIDUAL"
      ? userData.userId
      : userData?.corporateId;
  const userType = userData.acountType === "INDIVIDUAL" ? "user" : "company";

  const [searchValue, setSearchValue] = useState("");

  const [showTransactionPopup, setShowTransactionPopup] = useState(false);
  const [showContactusPopup, setShowContactusPopup] = useState(false);

  const [selectedTransactionId, setSelectedTransactionId] = useState<
    string | null
  >(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [queryObject, setQueryObject] = useState({
    page: currentPage,
    limit: 10,
    id: id as number, //userId if the person is not a ubo and corporateid if ubo
    search: "",
    currency: "",
    direction: "",
    dateFrom: "",
    dateTo: "",
    type: userType as queryType,
  });

  // direction: 'cryptoToFiat' || 'fiatToCrypto',

  const isEmpty = (data: any) => Array.isArray(data) && data.length === 0;

  const {
    data: userTransactions,
    isLoading,
    isFetching,
  } = trpc.transaction.transaction.useQuery(queryObject);

  const { reset, watch, setValue } = useForm({
    defaultValues: {
      search: "",
      currency: "",
      portalType: "",
      dateFrom: "",
      dateTo: "",
    },
  });

  const updateQueryParams = (params: Partial<queryObjectTypes>) => {
    setQueryObject((prev) => ({ ...prev, ...params }));
  };

  function closeShowPopup() {
    setShowTransactionPopup(false);
    setShowContactusPopup(false);
    setSelectedTransactionId(null);
  }

  function contactUs() {
    if (showTransactionPopup) {
      setShowTransactionPopup(false);
    }
    setShowContactusPopup(true);
  }

  function handleShowTransactionPopup(show: boolean, id?: string) {
    setSelectedTransactionId(id ?? null); // Store the ID
    setShowTransactionPopup(show);
  }

  function handlePageChange(page: number) {
    setCurrentPage(page);
    updateQueryParams({ page: page });
  }

  const handleSearch = (value: string) => {
    setSearchValue(value);
    updateQueryParams({ search: value });
  };

  const handleClearFilter = () => {
    reset(); // This resets the form to its default values
    setSearchValue("");
    updateQueryParams({
      search: "",
      currency: "",
      direction: "",
      dateFrom: "",
      dateTo: "",
      page: 1,
      limit: 10,
    });
  };

  const { mutate: sendContactUsMsg, isLoading: isSendingMsg } =
    trpc.user.customerContactUs.useMutation({
      onSuccess: () => {
        setShowContactusPopup(false);
        toast.success("Message sent successfully");
      },
      onError: () => {
        toast.error("Error sending message");
      },
    });

  const onSave = (data: ContactUsSchema) => {
    sendContactUsMsg(data);
  };

  const methods = useForm({
    resolver: zodResolver(contactUsSchema),
    defaultValues: {
      issue: "",
      message: "",
      uploadImage: "",
    },
  });

  const {
    register: registerIssue,
    handleSubmit: handleIssueSubmit,
    formState: { errors },
  } = methods;

  return (
    <PageContainer>
      <section className="dashTransaction_transactions">
        <form className="transactions-filter relative mb-[20px] flex w-full items-center justify-between rounded-[12px] bg-[#F9FAFB] px-4 py-3">
          <div className="flex items-center space-x-[15px]">
            <div className="search flex w-[280px] items-center rounded-lg border border-solid border-hgray-300 bg-white px-2 shadow-s-light">
              <span
                className="mr-1.5"
                dangerouslySetInnerHTML={{ __html: SearchSVG }}
              />
              <input
                type="text"
                placeholder="Amount, Address"
                value={searchValue}
                onChange={(e) => handleSearch(e.target.value)}
                className="text-ellipsis rounded-lg border-0 py-[10.5px] text-[13px] font-normal placeholder:text-htext-placeholder focus:outline-none"
              />
            </div>
            <div className="currency w-[125px] rounded-lg border border-solid border-hgray-300 bg-white px-[14px] py-[7.5px] shadow-s-light">
              <CommonDropdown
                dropClass="currency"
                dropdownItems={currency}
                disabledClass="Currency"
                defaultSelectedItem={watch("currency")} // Watch the currency value
                onSelect={(item) => {
                  setValue("currency", item);
                  updateQueryParams({ currency: item });
                }}
                svgIcon={DropArrowThinGraySVG}
              />
            </div>

            <div className="portalType w-[145px] rounded-lg border border-solid border-hgray-300 bg-white py-[7.5px] pl-[14px] pr-[12px] shadow-s-light">
              <CommonDropdown
                dropClass="portalType"
                dropdownItems={portalType}
                disabledClass="Portal Type"
                defaultSelectedItem={watch("portalType")} // Watch the portalType value
                onSelect={(item) => {
                  setValue("portalType", item);
                  updateQueryParams({ direction: item });
                }}
                svgIcon={DropArrowThinGraySVG}
              />
            </div>

            <div className="date w-[200px] rounded-lg border border-solid border-hgray-300 bg-white py-[7.5px] shadow-s-light">
              <EasyDateDrop
                startDate={
                  watch("dateFrom") ? new Date(watch("dateFrom")) : null
                }
                endDate={watch("dateTo") ? new Date(watch("dateTo")) : null}
                onSelect={(item) => {
                  const startDate = item[0]
                    ? format(item[0], "yyyy-MM-dd")
                    : "";
                  const endDate = item[1] ? format(item[1], "yyyy-MM-dd") : "";

                  // Only update query params if both startDate and endDate are selected
                  if (startDate && endDate) {
                    setValue("dateFrom", startDate);
                    setValue("dateTo", endDate);
                    updateQueryParams({
                      dateFrom: startDate,
                      dateTo: endDate,
                    });
                  }
                }}
              />
            </div>
          </div>

          <button type="button" onClick={() => handleClearFilter()}>
            <div className="flex items-center px-3 py-1 rounded cursor-pointer clear-filter hover:bg-hgray-100">
              <span
                className="mr-2.5"
                dangerouslySetInnerHTML={{ __html: closeBtnSmallSVG }}
              />
              <p className="text-[13px] font-medium text-hgray-400">
                Clear filter
              </p>
            </div>
          </button>
        </form>
      </section>

      <section>
        <div className="relative mb-5">
          {isLoading || isFetching ? (
            <div className="absolute inset-0 z-10 flex items-center justify-center bg-white bg-opacity-50">
              <div className="loader"></div> {/* Add your loader here */}
            </div>
          ) : null}

          <div
            className={`relative ${
              isLoading || isFetching
                ? "opacity-50"
                : "rounded-[12px] border border-solid border-hgray-200 shadow-s-semi-light"
            }`}
          >
            {isEmpty(userTransactions?.data.data) ? (
              <div className="flex flex-col items-center py-32">
                <img src="/recent-t.svg" alt="empty recent transactions" />
                <h5 className="mt-4 text-center text-[15px] font-semibold">
                  No transaction history
                </h5>
                <p className="mt-1 w-[250px] text-center text-[12px] font-normal text-htext-subdued">
                  Once you start making payments, you can keep track of your
                  transactions here
                </p>
              </div>
            ) : (
              <>
                <Table
                  columns={columns}
                  data={userTransactions?.data.data || []}
                  tableClass={true}
                  setShowTransactionPopup={handleShowTransactionPopup}
                />
                <Pagination
                  currentPage={currentPage}
                  totalPages={userTransactions?.data.meta.totalPages || 1} // Update this based on your data structure
                  onPageChange={handlePageChange}
                />
              </>
            )}
          </div>
        </div>
      </section>
      <Drawer
        openDrawerBox={showTransactionPopup}
        onClose={closeShowPopup}
        header="Transaction Details"
        action={
          <div className="flex items-center justify-between">
            <p className="text-[13px] font-normal text-htext-subdued">
              Issues with this transaction?{" "}
              <b
                className="font-semibold cursor-pointer text-hbrand-700 hover:text-hbrand-600"
                onClick={() => contactUs()}
              >
                Contact us
              </b>
            </p>
            <button
              type="button"
              onClick={() => closeShowPopup()}
              className="rounded-lg border border-hbrand-600 bg-hbrand-600 px-24 py-2.5 text-[15px] font-semibold text-white shadow-s-light hover:border-hbrand-700 hover:bg-hbrand-700"
            >
              Close
            </button>
          </div>
        }
      >
        <ViewTransaction
          selectedTransactionId={selectedTransactionId}
          customerId={id}
          userType={userType}
        />
      </Drawer>

      <Drawer
        openDrawerBox={showContactusPopup}
        onClose={closeShowPopup}
        header="Get in touch"
        action={
          <div className="flex justify-end ">
            <button
              type="button"
              onClick={closeShowPopup}
              className="shadow-s-light] mr-[5px] rounded-lg border border-hgray-300 bg-white px-3.5 py-2.5 text-[15px] font-semibold text-hgray-700 hover:opacity-70"
            >
              Close
            </button>
            <button
              type="submit"
              onClick={handleIssueSubmit(onSave)}
              className="ml-[5px] w-[40%] rounded-lg border border-hbrand-600 bg-hbrand-600 px-12 py-2.5 text-[15px] font-semibold text-white shadow-s-light hover:opacity-80"
            >
              {isSendingMsg ? (
                <span className="loading loading-spinner loading-sm"></span>
              ) : (
                "Send"
              )}
            </button>
          </div>
        }
        subHeader="We typically respond within 24 hrs"
      >
        <FormProvider {...methods}>
          <form className="w-full h-full">
            {" "}
            <fieldset>
              <label htmlFor="idType" className="relative h-full">
                <div className="mb-1.5 flex items-center text-[13px] font-medium">
                  <p className="text-htext-label">Issue cateogry</p>
                </div>

                <Select
                  id="idType"
                  register={registerIssue("issue")}
                  inputClass={true}
                  optionsItem={sof}
                  error={errors.issue}
                />
                {errors.issue && (
                  <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                    {errors.issue.message}!
                  </p>
                )}
              </label>
              <label htmlFor="idType" className="relative  h-full w-[48.5%]">
                <div className="mb-1.5 mt-7  flex items-center text-[13px] font-medium">
                  <p className="text-htext-label">Description </p>
                </div>
                <textarea
                  className="w-full px-3 py-2 border border-gray-100 rounded-md outline-none cursor-pointer"
                  maxLength={1200}
                  rows={9}
                  placeholder="Enter answer"
                  style={{ resize: "none" }}
                  {...registerIssue("message")}
                />
                {errors.message && (
                  <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                    {errors.message.message}!
                  </p>
                )}
              </label>

              <label htmlFor="file" className="relative w-full">
                <div className="mb-1 mt-7  flex items-center text-[13px] font-medium">
                  <p className="text-htext-label">Upload attachment</p>
                </div>
                <FileUpload
                  name="uploadImage"
                  error={errors?.["uploadImage"]}
                />
                {errors?.["uploadImage"] && (
                  <p className="font-ibm-plex absolute mt-1.5 text-[13px] font-medium text-red-600">
                    {errors?.["uploadImage"]?.message as string}
                  </p>
                )}
                {errors.uploadImage && (
                  <p className="font-ibm-plex absolute mt-1.5 text-[11.5px] font-medium text-red-600">
                    {errors.uploadImage.message}!
                  </p>
                )}
              </label>
            </fieldset>
          </form>
        </FormProvider>
      </Drawer>
    </PageContainer>
  );
};

export default DashTransaction;
