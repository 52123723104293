import FAQPage from "../../components/Dashboard/faq";

function HelpandFaq() {
  return (
    <div className="get-started">
      <div className="get-started-content">
         < FAQPage/>
      </div>
    </div>
  );
}

export default HelpandFaq;
